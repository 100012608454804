import {BottomStickyBar, Button} from "../../../components";
import {Announcement} from "../../../assets/svg";
import {useSelector} from "react-redux";
import {useLocalization} from "../../../hooks/useLocalization";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {url} from "../../../routes/utility";
import {getTimeZone, ISOStringToDate} from "../../../utils";

const CampaignBar = ({ campaignStickyBar }) => {

    switch (campaignStickyBar?.type){
        case 0:
            return <StickyBarWithText campaignStickyBar={campaignStickyBar} />
        case 1:
            return <StickyBarWithCounter campaignStickyBar={campaignStickyBar} />
        default:
            return null;
    }
}

const StickyBarWithText = ({ campaignStickyBar }) => {

    const navigate = useNavigate()
    const language = useSelector(state => state.localizationStore.language);

    return (
        <BottomStickyBar>
            <div className="flex justify-between items-center min-h-[64px]">
                <div className="flex justify-center items-center gap-2.5">
                    <img src={Announcement} alt="announcement" height={50} width={50} />
                    <div>
                        <p className="font-bold text-[16px] md:text-[18px]">{campaignStickyBar?.title?.[language]}</p>
                        <p className="md:text-[16px] text-[14px]">{campaignStickyBar?.content?.[language]}</p>
                    </div>
                </div>
                <Button
                    classnames="button primary-button-outline !border-placeholder text-base-text bg-box px-2 h-[40px] w-[80px] text-[16px] mt-0 leading-[16px]"
                    text={campaignStickyBar?.buttonText?.[language]}
                    action={() => navigate(url("market.packagecatalog"))}
                />
            </div>
        </BottomStickyBar>
    );
}


const StickyBarWithCounter = ({ campaignStickyBar }) => {

    const strings = useLocalization();
    const language = useSelector(state => state.localizationStore.language);
    const navigate = useNavigate();
    const timeZone = getTimeZone();

    const calculateTimeLeft = () => {
        const removeZFromDate = campaignStickyBar?.endDate.replace("Z", "");
        const difference = new Date(ISOStringToDate(removeZFromDate, timeZone)) - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 60000);

        return () => clearInterval(timer);
    }, []);

    return (
        <BottomStickyBar>
            <div className="flex justify-between items-center min-h-[64px]">
                <div className="flex justify-center items-center gap-2.5">
                    <div className="flex gap-1 justify-center">
                        <div
                            className="flex flex-col items-center justify-center bg-white text-base-text w-10 h-10 rounded-lg text-[18px]">
                            <p className="font-bold">{timeLeft.days}</p>
                            <p className="text-[11px] -mt-2">{strings.dashboard.student.day}</p>
                        </div>
                        <div
                            className="flex flex-col items-center justify-center bg-white text-base-text w-10 h-10 rounded-lg text-[18px]">
                            <p className="font-bold">{timeLeft.hours}</p>
                            <p className="text-[11px] -mt-2">{strings.dashboard.student.hour}</p>
                        </div>
                        <div
                            className="flex flex-col items-center justify-center bg-white text-base-text w-10 h-10 rounded-lg text-[18px]">
                            <p className="font-bold">{timeLeft.minutes}</p>
                            <p className="text-[11px] -mt-2">{strings.dashboard.student.minute}</p>
                        </div>
                    </div>
                    <div>
                        <p className="font-bold text-[16px] md:text-[18px]">{campaignStickyBar?.title?.[language]}</p>
                    </div>
                </div>
                <Button
                    classnames="button primary-button-outline !border-placeholder bg-box text-base-text px-2 h-[40px] w-[80px] text-[16px] mt-0 leading-[16px]"
                    text={campaignStickyBar?.buttonText?.[language]}
                    action={() => navigate(url("market.packagecatalog"))}
                />
            </div>
        </BottomStickyBar>
    )
}

export default CampaignBar;