import React from 'react'

const IdiomsOfTheDay = ({ idioms }) => {
  return (
    <>
        {idioms.map((idiom, index) => {
            return (
                <div key={index} className="mt-[27px]">
                    <p><span className="font-bold">{idiom[0]}<span
                        className="sm:hidden"><br /></span><span className="hidden sm:inline"> - </span></span>{idiom[1]}</p>
                    {idiom !== idioms.slice(-1)[0] && <hr key={index} />}
                </div>
            )
        })}
    </>
)
}

export default IdiomsOfTheDay