import React, {useEffect, useState} from "react";
import {
    Leaderboard,
    ProfileInfo,
    ProfileOperations,
    Recommendations,
    SentenceLearningList,
    Statistics
} from "./components";
import {useNavigate, useParams} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {GetMemberProfile} from "../../services/Profile/ProfileService";
import {EventLogs, MemberProfileStatus} from "../../components/Constants";
import {Loading} from "../../components";
import {url} from "../../routes/utility";
import useAnalytics from "../../hooks/useAnalytics";

const Profile = () => {

    const { username } = useParams();
    const { member } = useAuth();
    const navigate = useNavigate();
    const analytics = useAnalytics();

    const [profileData, setProfileData] = useState({});
    const [loading, setLoading] = useState(false)
    const [sendRequestToRecommendations, setSendRequestToRecommendations] = useState(false);

    const getMemberProfile = () => {
        setLoading(true);

        GetMemberProfile(member?.MemberId, username)
            .then(result => {
                if(result.status === 200)
                    setProfileData(JSON.parse(result.content));
                else
                    navigate(url("dashboard"));
            })
            .catch()

        setLoading(false);
    }

    const updateProfileStatus = () => {
        setProfileData(profileData => ({
            ...profileData,
            profileStatus: MemberProfileStatus.PUBLIC
        }));
    }

    const updateIsReportedStatus = () => {
        setProfileData(profileData => ({
            ...profileData,
            isReported: true
        }));
    }

    useEffect(() => {
        (member?.MemberId && username) &&
        getMemberProfile();
    }, [username])

    useEffect(() => {
        analytics.useAnalytics(EventLogs.PROFILE_VIEWED);
    }, [])

    return (
        <div className="main-content">
            <div className="mt-5 relative">
                {loading && <Loading/>}

                {!loading &&
                    <>
                        <ProfileInfo
                            data={profileData}
                            updateProfileStatus={updateProfileStatus}
                            getMemberProfile={getMemberProfile}
                            setSendRequestToRecommendations={setSendRequestToRecommendations}
                        />

                        <Statistics
                            memberId={profileData?.memberId}
                            username={username}
                        />

                        <Recommendations
                            getMemberProfile={getMemberProfile}
                            sendRequestToRecommendations={sendRequestToRecommendations}
                            setSendRequestToRecommendations={setSendRequestToRecommendations}
                        />

                        <Leaderboard
                            memberUsername={username}
                            memberId={member?.MemberId}
                        />

                        {profileData.isSelfProfile &&
                            <SentenceLearningList />
                        }

                        <ProfileOperations
                            data={profileData}
                            updateIsReportedStatus={updateIsReportedStatus}
                            getMemberProfile={getMemberProfile}
                        />
                    </>
                }

                <div className="left-margin-bottom"></div>
            </div>
        </div>
    )
}

export default Profile;