import React, { useEffect, useState } from 'react'
import useAuth from '../hooks/useAuth';
import ProgressReport from './ProgressReport';
import { useSelector } from "react-redux";
import { GetProgressReportService } from '../services/SpeakingLesson/SpeakingLessonService';

export const SideBar = (props) => {

    const { member, token } = useAuth();

    const termId = useSelector(state => state.termStore.termId) || false;
    const [report, setReport] = useState(false);
    const [reportLoading, setReportLoading] = useState(false);

    const getProgressReport = async (termId) => {
        setReportLoading(reportLoading => true);
        const result = await GetProgressReportService(termId, token);

        if(result.statusCode === 200) {
            setReport(report => JSON.parse(result.message))
        }

        setReportLoading(reportLoading => false);

    }

    useEffect(() => {
        let abortController = new AbortController();
        let rp = { startLevel: "", currentLevel: "", attendanceRate: "0,0" };
        setReport(report => rp);

        if(termId) {
            getProgressReport(termId);
        }

        return () => {
            abortController.abort();
        };
    }, [termId])

    return (
        <>

            <div className="side-bar">
                <ProgressReport report={report} reportLoading={reportLoading} />
            </div>

        </>
    )
}