import React, { useEffect } from 'react'
import '../../assets/css/logo.css'
import { Logo, Onboarding_Rocket, Button_Right_Arrow } from '../../assets/svg'
import { Button } from '../../components';
import { useLocalization } from '../../hooks/useLocalization';
import { Link, useNavigate } from 'react-router-dom';
import { url } from '../../routes/utility';
import useAnalytics from '../../hooks/useAnalytics';
import useAuth from '../../hooks/useAuth';
import useToken from '../../hooks/useToken';
import { useDispatch } from 'react-redux';
import { setAuth } from '../../store/AuthSlice';

const OnboardingSuccess = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const { member } = useAuth();
    const tokenHook = useToken();
    const dispatch = useDispatch();

    const STORAGE_KEY = 'onboardingIndex';

    //this function provides to go to dashboard
    const goToDashboard = () => {
        //firebase data
        analytics.useAnalytics('Onboarding_Completed');
        // localStorage'dan kayıtlı index'i al ve geçerli mi kontrol et
        localStorage.removeItem(STORAGE_KEY); 
        //navigate to dashboard
        navigate(url("dashboard"));
    }

    //onboarding success'e gelindiğinde englishLevel'ın güncellenmesi için token tekrar generate ediliyor.
    useEffect(() => {
        tokenHook.generateToken(member?.MemberId);
        dispatch(setAuth(false));
    }, [])

    return (
        <section className="h-full flex overflow-hidden flex-col relative">
            {/*<!-- top -->*/}
            <div className="w-full h-1/2 flex justify-center items-center">
                <div className=" mb-12 flex flex-col items-center z-20">
                    <img className="w-[90px] md:w-[120px]" src={Logo} />
                    <p className="font-bold text-2xl md:text-4xl mt-2 outline-title">{strings.brand_name}</p>
                </div>
            </div>

            <div className="absolute -mt-[16px] top-[calc(50%-80px)] left-[calc(50%-80px)]">
                <img src={Onboarding_Rocket} width="160" height="160" alt="" />
            </div>

            {/*<!-- bottom -->*/}
            <div className="w-full h-1/2 bg-box border-t-[5.4px] border-base-text pt-20">
                <div className="text-center mb-5 md:mb-16 mx-2">
                    <p className="font-bold text-xl mb-2 md:mb-7">{strings.onboarding.success.congratulations}</p>
                    <p className="mb-2">{strings.onboarding.success.profile_created}</p>
                    <p>{strings.onboarding.success.subtitle}</p>
                </div>
                <div className="btn-outer-div mb-3">
                    <Button type="button" classnames="button primary-button" text={strings.onboarding.continue_button}
                        action={goToDashboard}
                    />
                </div>
            </div>
        </section>
    )
}

export default OnboardingSuccess