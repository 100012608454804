import { Outlet } from "react-router-dom"
import { Menu, SideBar } from "../../components"
import { useLocalization } from "../../hooks/useLocalization"
import {useSelector} from "react-redux";
import { ReferenceBar } from "./components/DailyLesson/components";

const SpeakingLessonLayout = () => {

    const strings = useLocalization()
    const attendedRenderStatus = useSelector(state => state.componentRenderStore.DailyLesson.Attended);

    return(
        <>
            <Menu title={strings.speaking_lesson.title} />

            <section className="container">

                <Outlet/>

                <SideBar />

            </section>

            {attendedRenderStatus && <ReferenceBar />}
        </>
    )
}

export default SpeakingLessonLayout