import React, {useEffect, useState} from 'react'
import useAnalytics from '../../../../hooks/useAnalytics';
import {useLocalization} from '../../../../hooks/useLocalization'
import { AttendedInformation, LessonAudio, LessonContentAttended as LessonContent, LessonInfoAttended as LessonInfo, LessonStudy, PopularSentences, SpeakingLabContent } from './components';
import {LessonResultAccordionType, TermLessonAccordionType} from "../../../../components/Constants";

const Attended = ({data, selectedDate}) => {

    const strings = useLocalization();
    const analytics = useAnalytics();

    const isViewLessonBookClicked = localStorage.getItem("isLessonBookClicked") ?? false;

    const [convertedData, setConvertedData] = useState();

    useEffect(() => {
        let newData = {
            1: [], //StudySubjects-question
            2: [], //StudySubjects-sentence
            3: [], //DailyTextbook-book
            4: [], //DailyTextbook-presentation
            5: [], //DailyTextbook-practice
            6: [], //video
            7: [], //audio
            8: [], //Lesson Audio
        };

        data.book.contents.map(value => {
            // 7 contentType var
            for (let i = 1; i < 9; i++) {
                value.contentType === i && newData[i].push(value.content);
            }
        });

        data.lessonRecords?.map(value => {
            newData[8].push(
                `https://records.clickivo.com/${value.slice(4, 12)}/${value}`,
            );
        });

        setConvertedData(newData);
    }, [data, selectedDate]);

    useEffect(() => {
        analytics.useAnalytics('Completed_Lesson_Detail_View');
        analytics.useAnalytics('screen_view', {screen_name: 'Gunun_Dersi_Islenmis_Derse_Katildi'});
        window.customDataLayer.push({event: 'screen_view', screen_name: 'Gunun_Dersi_Islenmis_Derse_Katildi'});
    }, []);

    return (
        <>
            <AttendedInformation data={data}/>

            {/* Speaking Lab */}
            {data?.isSpeakingLabAvailable &&
                <SpeakingLabContent
                    bookUnitId={data?.book?.bookUnitId}
                    selectedDate={selectedDate}
                    termLessonAccordionActivities={
                        data?.termLessonAccordionActivities?.filter(l => TermLessonAccordionType.SPEAKING_LAB === l.termLessonAccordionType) || []
                    }
                />
            }

            {convertedData && convertedData[8][0] && (
                <LessonAudio
                    src={convertedData[8]}
                    title={strings.speaking_lesson.components.audio.lesson_records_title}
                    event='Completed_Lesson_Record_Listen'
                    lessonResultId={data?.lessonResultId}
                    lessonResultAccordionActivities={
                        data?.lessonResultAccordionActivities?.filter(l => LessonResultAccordionType.LESSON_RECORDS === l.lessonResultAccordionType) || []
                    }
                />
            )}

            <LessonStudy
                isBookHaveQuiz={data?.book?.isBookHaveQuiz}
                bookUnitId={data?.book?.bookUnitId}
                selectedDate={selectedDate}
                lessonResultId={data?.lessonResultId}
                lessonResultAccordionActivities={
                    data?.lessonResultAccordionActivities?.filter(l => LessonResultAccordionType.AFTER_LESSON_ACTIVITIES === l.lessonResultAccordionType) || []
                }
            />

            <LessonInfo
                teacherInfo={data?.teacher}
                lessonCallLogs={data?.lessonCallLogs}
                lessonResultId={data?.lessonResultId}
                lessonResultAccordionActivities={
                    data?.lessonResultAccordionActivities?.filter(l => LessonResultAccordionType.LESSON_INFO === l.lessonResultAccordionType) || []
                }
            />

            <LessonContent
                book={data?.book}
                convertedData={convertedData}
                selectedDate={selectedDate}
                isViewLessonBookClicked={isViewLessonBookClicked}
                termLessonAccordionActivities={
                    data?.termLessonAccordionActivities?.filter(l => TermLessonAccordionType.LESSON_MATERIALS === l.termLessonAccordionType) || []
                }
            />

            {data?.book?.bookUnitId !== 0 &&
                <PopularSentences
                    title={strings.speaking_lesson.daily_lesson.sentence_bank.title}
                    bookUnitId={data?.book?.bookUnitId}
                    selectedDate={selectedDate}
                />
            }
        </>
    )
}

export default Attended