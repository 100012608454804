import classNames from "classnames";
import Lottie from "lottie-react";
import React, { useEffect, useRef, useState } from "react";
import record from '../../../../assets/animation/record.json';
import { MicrophonePronunciation, SpeakerOutline } from "../../../../assets/svg";
import { Loading, ModalFullScreenWhite, ModalOneButton, ModalOverlay, ModalTwoButtons } from "../../../../components";
import { EventLogs, PronunciationErrors, PronunciationResultType, ScoreType } from "../../../../components/Constants";
import useAnalytics from "../../../../hooks/useAnalytics";
import useAuth from "../../../../hooks/useAuth";
import { useLocalization } from "../../../../hooks/useLocalization";
import useTts from "../../../../hooks/useTts";
import { AddMemberPronunciationResult, GetSpeechReport } from "../../../../services/VideoPractice/VideoPracticeService";
import {
    closeModal,
    getBrowserName,
    getDeviceOSName,
    getPermissionTutorialVideoUrl,
    openModal,
    sendEventByDefaultParameters
} from "../../../../utils";
import { ScoreCard } from "../index";

const PronunciationModal = (props) => {

    const {
        setOpenPronunciationModal,
        pronunciationInfo,
        cancelScroll = false
    } = props;

    const strings = useLocalization();
    const { member } = useAuth();
    const {speakSentence, SpeakerComponent} = useTts();
    const analytics = useAnalytics();
    const videoInfo = JSON.parse(localStorage.getItem("videoInfo")) || false;

    const modalOverlayRef = useRef();
    const modalRef = useRef();
    const errorModalRef = useRef();
    const recorderRef = useRef();

    const browser = getBrowserName();
    const operatingSystem = getDeviceOSName();

    // Result yüklenmesini kontrol eden state
    const [loading, setLoading] = useState(false);
    // Ses kaydının başlayıp/başlamadığını tutan state
    const [isRecording, setIsRecording] = useState(false);
    // Animasyonun gösterimini kontrol eden state
    const [showAnimation, setShowAnimation] = useState(false);
    // Result verilerini tutan state
    const [result, setResult] = useState({});

    const getPronunciationResultTypeBySource = (source) => {
        switch (source) {
            case "word-card":
                return PronunciationResultType.WORD;
            case "dialog":
                return PronunciationResultType.VIDEO_DIALOG;
            default:
                return "";
        }
    }

    // base event fonksiyonu
    const sendEvent = (event, parameters) => {
        sendEventByDefaultParameters(
            event,
            analytics,
            videoInfo?.videoTags,
            {
                ...pronunciationInfo?.logParameters,
                ...parameters
            },
            { seviye: '', ilgi_alanlari: '' }
        );
    }

    const startRecording = async () => {
        let chunks = [];

        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true}); // To get access to the microphone
            recorderRef.current = new MediaRecorder(stream); // To create a media recorder object

            recorderRef.current.addEventListener('dataavailable', (event) => {
                chunks.push(event.data); // To store the audio data in chunks
            });

            recorderRef.current.addEventListener('stop', async () => {
                const blob = new Blob(chunks, {type: 'audio/wav'}); // To create a blob object from the chunks
                const formData = new FormData(); // To create a form data object
                formData.append('audio', blob); // To append the blob object to the form data
                formData.append('text', pronunciationInfo?.content);

                setLoading(true);
                const result = await GetSpeechReport(formData)

                try {
                    setResult(JSON.parse(result));
                } catch (error) {
                    openModal(modalOverlayRef, errorModalRef);
                }

                setLoading(false);
                // Reset the chunks array
                chunks = [];
            });

            setIsRecording(true);
            recorderRef.current.start(); // To start recording audio
            setTimeout(() => { setShowAnimation(true)}, 800);

        } catch (error) {
            openModal(modalOverlayRef, modalRef);
            console.error('Error accessing microphone:', error); // To log the error message
        }
    }

    const stopRecording = () => {
        setIsRecording(false);
        setShowAnimation(false);
        recorderRef.current.stop();
    }

    // Telaffuz sayfasını kapatan buton işlemlerini yapan fonksiyon
    const handleClosePronunciationModal = () => {
        if(cancelScroll)
            document.body.style.overflow = 'auto';
        setOpenPronunciationModal(false);
        sendEvent(EventLogs.VIDEO_EXERCISE_WORD_PRONUNCIATION_BACK);
    }

    // Seslendirme butonunun kontrollerini yapan fonksiyon
    const handleRecordButton = () => {
        !isRecording ? startRecording() : stopRecording();

        if(pronunciationInfo?.source === "word-card")
            sendEvent(EventLogs.VIDEO_EXERCISE_WORD_PRONUNCIATION_SPEAK); //LOGGED

        if(pronunciationInfo?.source === "dialog")
            sendEvent(EventLogs.VIDEO_EXERCISE_DIALOG_DETAIL_PRACTICE_CLICK); //LOGGED
    }

    // Kelime veya cümleyi seslendiren fonksiyon
    const handleSpeakPronunciationContent = () => {
        speakSentence(pronunciationInfo?.content, process.env.REACT_APP_LANG_EN_CODE, pronunciationInfo?.pronunciationId);

        if(pronunciationInfo?.source === "word-card")
            sendEvent(EventLogs.VIDEO_EXERCISE_WORD_PRONUNCIATION_LISTEN); //LOGGED

        if(pronunciationInfo?.source === "dialog")
            sendEvent(EventLogs.VIDEO_EXERCISE_DIALOG_DETAIL_LISTEN_CLICK); //LOGGED
    }

    useEffect(() => {
        if(Object.keys(result).length > 0) {

            const model = {
                pronunciationId: pronunciationInfo?.pronunciationId,
                memberId: member?.MemberId,
                pronunciationResultType: getPronunciationResultTypeBySource(pronunciationInfo?.source),
                pronunciationScore: result?.pron_score,
                memberPronunciationResultScores: [
                    {
                        scoreType: ScoreType.PRONUNCIATION,
                        score: result?.pron_score
                    },
                    {
                        scoreType: ScoreType.ACCURACY,
                        score: result?.accuracy_score
                    },
                    {
                        scoreType: ScoreType.COMPLETENESS,
                        score: result?.completeness_score
                    },
                    {
                        scoreType: ScoreType.FLUENCY,
                        score: result?.fluency_score
                    },
                    {
                        scoreType: ScoreType.PROSODY,
                        score: result?.prosody_score
                    }
                ]
            }

            AddMemberPronunciationResult(model)
                .then()
                .catch()

            if(pronunciationInfo?.source === "word-card")
                sendEvent(
                  EventLogs.VIDEO_EXERCISE_WORD_PRON_SPEAK_RESULT,
                    {
                        total_score: result?.pron_score,
                        accuracy_score: result?.accuracy_score,
                        fluency_score: result?.fluency_score,
                        prosody_score: result?.prosody_score
                    }
                ); //LOGGED

            if(pronunciationInfo?.source === "dialog")
                sendEvent(
                    EventLogs.VIDEO_EXERCISE_DIALOG_DETAIL_RESULT,
                    {
                        total_score: result?.pron_score,
                        accuracy_score: result?.accuracy_score,
                        fluency_score: result?.fluency_score,
                        prosody_score: result?.prosody_score
                    }
                ); //LOGGED
        }
    }, [result])

    return (
        <>
            <SpeakerComponent />
            <ModalFullScreenWhite
                handleCloseClick={handleClosePronunciationModal}
                children={
                    <>
                        <div className="flex items-center justify-center mt-4">
                            <div className="flex flex-col items-center w-full gap-6 px-4 md:px-0 overflow-y-auto">
                                <p className="text-secondary text-center font-bold">
                                    {strings.video_practice.word_card.word_pronunciation}
                                </p>

                                <button
                                    type="button"
                                    className="relative"
                                    onClick={handleRecordButton}
                                >
                                    <div
                                        className="flex items-center w-[120px] h-[120px] justify-center rounded-full border">
                                        <img src={MicrophonePronunciation} width={80} height={80} alt="mic"/>
                                    </div>

                                    {showAnimation ? (
                                        <div className="absolute inset-0 rounded-full overflow-hidden  animate-overlay">
                                            <Lottie
                                                animationData={record}
                                                loop
                                                autoplay
                                                style={{ height: '120px', width: '120px' }}
                                            />
                                        </div>
                                    ) : null}
                                </button>

                                <div className="flex flex-col items-center justify-center">
                                    <button
                                        type="button"
                                        className={classNames("flex gap-1 hover:text-base-text/70", {
                                            "flex-col items-center" : pronunciationInfo?.source === 'dialog'
                                        })}
                                        onClick={handleSpeakPronunciationContent}
                                    >
                                        <p
                                            className={classNames("text-center max-w-[500px]", {
                                                "font-bold" :  pronunciationInfo?.source === 'word-card'
                                            })}
                                        >
                                            {pronunciationInfo?.content}
                                        </p>
                                        <img src={SpeakerOutline} width={24} height={24} alt="speaker"/>
                                    </button>
                                    {pronunciationInfo?.phonetic?.length > 0 &&
                                        <p className="mt-1 text-center">{pronunciationInfo?.phonetic}</p>
                                    }
                                </div>

                                {loading && <Loading />}

                                {(Object.keys(result).length > 0 && !loading) &&
                                    <>
                                        {pronunciationInfo?.source === "dialog" &&
                                            <>
                                                <div className="flex items-center justify-center">
                                                    <hr className="w-[165px]"/>
                                                </div>

                                                <p className="text-center max-w-[500px]">
                                                    {result.words.length > 0
                                                        && result.words.map((item, index) => (
                                                            <span
                                                                key={index}
                                                                className={classNames("font-bold", {
                                                                    "text-[#9A1E13]" : PronunciationErrors.OMISSION === item.error_type,
                                                                    "text-[#F16C00]" : PronunciationErrors.MISPRONUNCIATION === item.error_type,
                                                                    "text-[#458B57]" : PronunciationErrors.NONE === item.error_type,
                                                                })}
                                                            >
                                                                {item.word} {" "}
                                                            </span>
                                                        ))
                                                    }
                                                </p>

                                                <div className="flex gap-4 ml-6">
                                                    <div className="flex items-center gap-1">
                                                        <div className="h-[10px] w-[10px] bg-[#9A1E13]"></div>
                                                        <p className="text-[12px]">
                                                            {strings.video_practice.pronunciation.omission}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center gap-1">
                                                        <div className="h-[10px] w-[10px] bg-[#F16C00]"></div>
                                                        <p className="text-[12px]">
                                                            {strings.video_practice.pronunciation.mispronunciation}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center gap-1">
                                                        <div className="h-[10px] w-[10px] bg-[#458B57]"></div>
                                                        <p className="text-[12px]">
                                                            {strings.video_practice.pronunciation.none}
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <ScoreCard result={result} classnames="mt-5 max-w-[500px]"/>

                                        <p className="text-center text-[14px] mb-8">{strings.video_practice.pronunciation.click_microphone_for_try_again}</p>
                                    </>
                                }

                            </div>
                        </div>
                    </>
                }
            />

            <ModalOverlay ref={modalOverlayRef}/>
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.video_practice.word_card.give_microphone_permission}
                buttonText1={strings.auth.form.okay_button}
                buttonClick1={() => closeModal(modalOverlayRef, modalRef)}
                buttonText2={strings.auth.form.watch_video}
                buttonClick2={() => window.open(getPermissionTutorialVideoUrl(operatingSystem, browser))}
            />
            <ModalOneButton
                ref={errorModalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.general_information_messages.an_error_occured}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => closeModal(modalOverlayRef, errorModalRef)}
            />
        </>
    )
}

export default PronunciationModal;