import React, {useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import { Holiday_2, Icon_Confused, Postponed_Class } from '../../assets/svg';
import { Loading, TermDropDown } from '../../components';
import {EventLogs, ServiceErrorMessages} from '../../components/Constants';
import useAuth from '../../hooks/useAuth';
import { useLocalization } from '../../hooks/useLocalization';
import { GetDailyPattern } from '../../services/SpeakingLesson/SpeakingLessonService';
import { formatNoneZeroDateToZeroDateForDash, getTodayForLessonDate } from '../../utils';
import DailyLessonError from './components/DailySentence/DailyLessonError';
import DailySentenceDetail from './components/DailySentence/DailySentenceDetail';
import { ChooseDate } from './components/common';
import useAnalytics from "../../hooks/useAnalytics";

const DailySentenceHome = () => {

  const strings = useLocalization();

  const { member } = useAuth();
  const analytics = useAnalytics();

  const terms = typeof (member.Terms) !== "undefined" && JSON.parse(member.Terms);
  const selectedTermId = useSelector(state => state.termStore.termId);

  const firstVisit = useRef(true);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  //those are for calendar
  const today = getTodayForLessonDate();
  //Kullanıcı eğer termScheduleDate ile gelmişse o tarihi seçili tarih olarak atıyoruz. Aksi taktirde bugünün tarihini seçili tarih olarak atıyoruz.
  const [selectedDate, setSelectedDate] = useState(localStorage.getItem("selectedDate") ? localStorage.getItem("selectedDate") : today);

  const dailyLessonTypeArray = [
    { dailyLessonType: 5, icon: Postponed_Class, message1: strings.speaking_lesson.daily_sentence.postponed.content_1, message2: strings.speaking_lesson.daily_sentence.postponed.content_2 },
    { dailyLessonType: 6, icon: Icon_Confused, message1: strings.speaking_lesson.daily_sentence.frozen.content_1, message2: strings.speaking_lesson.daily_sentence.frozen.content_2 },
    { dailyLessonType: 7, icon: Holiday_2, message1: strings.speaking_lesson.daily_sentence.holiday.content_1, message2: strings.speaking_lesson.daily_sentence.holiday.content_2 },
    { dailyLessonType: 8, icon: Holiday_2, message1: strings.speaking_lesson.daily_sentence.holiday.content_1, message2: strings.speaking_lesson.daily_sentence.holiday.content_2 },
    { dailyLessonType: 9, icon: Holiday_2, message1: strings.speaking_lesson.daily_sentence.inactive.content_1, message2: strings.speaking_lesson.daily_sentence.inactive.content_2 },
  ]

  const getDailyPattern = async (selectedDate, selectedTermId) => {

    let model = {
      brandId: +process.env.REACT_APP_BRAND_ID,
      termId: selectedTermId,
      lessonDate: selectedDate
    }
    const result = await GetDailyPattern(model);
    
    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setErrorMessage(errorMessage => "");
      setData(data => resultContent);
    } else {
      if (result.content === ServiceErrorMessages.BOOK_HINT_NOT_FOUND) {
        setErrorMessage(errorMessage => strings.speaking_lesson.daily_sentence.book_hint_not_found);
      } else {
        setErrorMessage(errorMessage => strings.general_information_messages.an_error_occured);
      }
    }
    setLoading(loading => false);
  }

  const dailyPatternStatus = () => {
    let allowedData = [1, 2, 4]
    if (data) {
      if (allowedData.includes(data.dailyLessonType)) {
        return true
      } else {
        return false
      }
    }
  }

  useEffect(() => {
    if (selectedDate && selectedTermId) {
      getDailyPattern(selectedDate, selectedTermId);
    }
  }, [selectedDate, selectedTermId])

  useEffect(() => {
    //Seçili tarih değiştikçe log atılıyor ve sayfa ilk yüklendiğinde log atılması engelleniyor.
    if(!firstVisit.current)
      analytics.useAnalytics(EventLogs.DAILY_PATTERN_DATE_CHANGE);
  }, [selectedDate]);

  useEffect(() => {
    if(!firstVisit.current)
        //Seçili paket değiştikçe log atılıyor ve sayfa ilk yüklendiğinde log atılması engelleniyor.
      analytics.useAnalytics(EventLogs.DAILY_PATTERN_PACKAGE_CHANGE);
  }, [selectedTermId]);

  useEffect(() => {
    //Sayfa yüklendikten sonra logları doğru bir şekilde atabilmek için ilk yüklenmede kontrol sağlıyoruz.
    firstVisit.current = false;
    analytics.useAnalytics(EventLogs.DAILY_PATTERN_SCREEN_VIEW, undefined, true, false);
  }, [])

  return (
    <div className="main-content">
      <div className="left-page">
        <ChooseDate
          title={strings.speaking_lesson.daily_lesson.choose_date}
          // 2023-11-8 formatında gelen tarihler 2023-11-08 olarak formatlanır
          sDate={formatNoneZeroDateToZeroDateForDash(selectedDate)}
          setDate={setSelectedDate}
        />
        {loading && <Loading />}

        {!loading && (
          <>
            <div className="mt-5 gray-section px-[10px] xs:px-[10px] text-start">
              <h5 className="text-primary">
                {strings.speaking_lesson.speaking_lesson_user.daily_sentence}
              </h5>
              <div className='my-2'>
                <TermDropDown terms={terms} />
                <div className='text-center mt-3 max-w-lg mx-auto'>
                  {data.isFriday && (
                    <>
                      {errorMessage?.length === 0 && strings.speaking_lesson.daily_sentence.friday_error_message}
                    </>
                  )}
                  {(dailyPatternStatus() && !data.isFriday) && (
                    <>
                      {strings.speaking_lesson.daily_sentence.message}
                    </>
                  )}
                  {(!dailyPatternStatus() && !data.isFriday) && (
                    <>
                      {errorMessage?.length > 0 && errorMessage}
                      {errorMessage?.length === 0 && <DailyLessonError data={dailyLessonTypeArray.find(d => d.dailyLessonType === data.dailyLessonType)} />}
                    </>
                  )}
                </div>
              </div>
            </div>

            {(!data.isFriday && dailyPatternStatus() && data.dailyPattern) && (
              <DailySentenceDetail data={data.dailyPattern}/>
            )}

          </>
        )}

      </div>

      <div className="left-margin-bottom"></div>
      
    </div>
  )
}

export default DailySentenceHome