import { initializeApp } from "firebase/app";
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";
import { createContext, useEffect, useState } from "react";

const FirebaseContext = createContext(null)
export { FirebaseContext }

const FirebaseProvider = ({ children }) => {

    const [exerciseType, setExerciseType] = useState(null);
    const [campaignStickyBar, setCampaignStickyBar] = useState(null);

    const firebaseConfig = {
        apiKey: "AIzaSyDeAKKGi05gQss6gq9XFzoqVFdw8lEPwU4",
        authDomain: "azeringilisce-57c6b.firebaseapp.com",
        projectId: "azeringilisce-57c6b",
        storageBucket: "azeringilisce-57c6b.appspot.com",
        messagingSenderId: "36835501733",
        appId: "1:36835501733:web:0a13fbb824c489a5f40f4c",
        measurementId: "G-VEG75S703C"
    };

    const firebase = initializeApp(firebaseConfig);
    const remoteConfig = getRemoteConfig(firebase);

    // Firebase'den gelen verinin cache süresini 20 saniye yapmak için kullanılır
    // Yani 20 saniyede bir remoteConfig'den gelen verinin cache'i temizleniyor
    remoteConfig.settings.minimumFetchIntervalMillis = 20000;

    useEffect(() => {
        fetchAndActivate(remoteConfig)
            .then(() => {
                let tmpExerciseType = parseInt(getValue(remoteConfig, 'exercise_type')?._value)

                let tmpCampaignStickyBar;
                try {
                    tmpCampaignStickyBar = JSON.parse(getValue(remoteConfig, 'campaign_sticky_bar')?._value);
                } catch (e) {
                    tmpCampaignStickyBar = null;
                }
                setExerciseType(tmpExerciseType);
                setCampaignStickyBar(tmpCampaignStickyBar);
            })
            .catch((error) => {
                console.error('Error fetching and activating Remote Config', error);
            });
    }, [])

    const contextValue = {
        firebase: firebase,
        exerciseType: exerciseType,
        campaignStickyBar: campaignStickyBar
    };

    return (
        <FirebaseContext.Provider value={contextValue}>
            {children}
        </FirebaseContext.Provider>
    )
}

export default FirebaseProvider