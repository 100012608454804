import classNames from "classnames";
import React, { useEffect, useState } from "react";

const BottomStickyBar = (props) => {

    const { isCloseable = true, children, theme = "green", isOverlay = false } = props;
    const [isOpen, setIsOpen] = useState(false);
    
    const storedData = sessionStorage.getItem('bottomStickyBar');

    const handleClose = () => {
        setIsOpen(false);
        updateSessionStorage(theme, true);
    }

    const updateSessionStorage = (theme) => {
        const storedData = sessionStorage.getItem('bottomStickyBar');

        let themeArray = [];
        if (storedData) {
            themeArray = JSON.parse(storedData);
        }

        if (!themeArray.includes(theme)) {
            themeArray.push(theme);
        }

        sessionStorage.setItem('bottomStickyBar', JSON.stringify(themeArray));
    };

    // Modal açıldığında arka plandaki overflow'u kapatan fonksiyon
    useEffect(() => {
        if (isOpen && isOverlay) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen, isOverlay]);

    useEffect(() => {

        if (storedData) {
            const existingItem = JSON.parse(storedData);

            if (!existingItem.includes(theme)) {
                setIsOpen(true)
            }
        } else {
            setIsOpen(true)
        }
    }, [theme, storedData]);

    return (
        <>
            {isOpen && isOverlay && <div className="fixed z-40 w-screen h-screen inset-0 bg-[#333333] bg-opacity-70" />}
            <div
                className={classNames("min-h-[64px] fixed left-0 right-0 w-full  transition-transform duration-700 z-[41]", {
                    "translate-y-0": isOpen,
                    "translate-y-[160px]": !isOpen,
                    "bg-[#008502] text-white bottom-[70px] md:bottom-0": theme === "green",
                    "bg-white  rounded-t-[10px] shadow-md bottom-0": theme === "white",
                    "bg-[#666] text-white bottom-[70px] md:bottom-0 !min-h-[48px]": theme === "dark"
                })}
            >
                <div className="w-full h-full relative">
                    {isCloseable &&
                        <span
                            onClick={handleClose}
                            className={classNames("material-symbols-outlined cursor-pointer top-1 right-1 absolute", {
                                "text-white": theme === "green" || theme === "dark"
                            })}
                        >
                            close
                        </span>
                    }

                    <div className="pl-3 pr-7 sm:px-8 md:px-10 lg:px-20 mx-auto">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BottomStickyBar;