export const English = {

    brand_name: 'Azər Ingiliscə',

    errors: {
        no_records_found: 'No records found',
    },

    menu: {
        nav: {
            menu1: 'Home',
            menu2: 'Speaking Lesson',
            menu3: 'Teachers',
            menu4: 'Market',
            menu5: 'Support',
        },
        collapse: {
            profile: "Profile",
            support: "Support",
            reference: "Invite a Friend",
            demo: "Take a Demo Lesson",
            logout: "Exit",
            contact_us: "Contact Us",
            modal: {
                no_demo_description: "You do not have the demo lesson rights.<br/><br/>You need to contact your mentor for demo lesson rights.",
                already_have_active_demo: "You have an active demo lesson.",
                contact_mentor: "Contact Mentor"
            }
        },
        callbar: {
            need_permission: "You need to give notification and microphone permission to attend classes from this screen.",
            allow_now: "Allow",
            permission_error: "Your internet browser is preventing the notification permission request from appearing. You can get help on how to make these settings by clicking the link below.",
            join_your_lesson_button: "Join Your Lesson",
            join_your_lesson_message: "By clicking the <b>Join Your Lesson</b> button, you can start waiting for your teacher for your lesson.",
            calling_you: "#teacher# is calling you now.", //Teacher Jasmine is calling you now.
            talking_to_now: "#teacher# is calling you now.", //Teacher is talking to you now.
            wait_while_connecting: "Please wait while connecting.", //Please wait while connecting.
            an_error_occured: 'An error occurred during operation.', //An error occurred during operation.
            no_lesson_for_today: 'You do not have any lesson today.',//You do not have any lesson today.
            not_in_available_time_range: 'You are not in available time range! You can join your lesson 5 minutes before.', //You are not in available time range! You can join your lesson 5 minutes before.
            poor_connection: '(Your internet connection is weak. Please check your connection.)',
            connection_lost: 'Your internet connection is broken. Your teacher will call you back.',
            general_error_message: 'An unexpected error occurred. Please submit a ticket.',
            callbar_refresh_alert_message: 'You may have given your permission before, please refresh your page.\n If you still see the Allow button after refreshing your page, please grant your permissions.',
            refresh_page: 'Refresh Page',
            download_application: "Go to App",
            not_avaliable_text: "You need to use our mobile application to receive calls."
        }
    },

    modal: {
        okey: "Okay",
        approve: "Confirm",
        dont_approve: "Cancel",
        information_message: "Information Message",
        transaction_information: "Transaction Confirmation",
        required_field_error: "Required (*) fields cannot be left blank. Please fill in all required fields."
    },

    //auth screens
    auth: {
        layout: {
            right_subtitle_1: "We are the Largest Online English School in Azerbaijan with 50,000+ Students!",
            right_subtitle_2: "\"An excellent English course. It changed my life.\" Ali Yılmaz.",
            logo_subtitle: "Welcome to Azerbaijan\'s most effective English learning system.",
        },
        form: {
            your_name: 'Full Name',
            create_your_name: 'Enter your full name',
            phone_number: 'Phone number',
            current_password: 'Current Password',
            enter_current_password: 'Enter your current password',
            your_password: 'Password',
            your_password_again: 'Password (Again)',
            create_your_password: 'Create your password',
            enter_your_password: 'Enter your password',
            enter_your_password_again: 'Enter your password again',
            enter_your_phone_number: 'Enter your phone number',
            enter_code: 'Enter the code',
            enter_sms_code: 'Enter the code received via SMS.',
            sign_up_button: 'Sign Up',
            sign_in_button: 'Log In',
            forgot_password_button: 'Forgot My Password',
            okay_button: 'Okay',
            send_button: 'Send',
            change_button: 'Change',
            watch_video: 'Watch Video',
            verification_button: 'Verify',
            privacy_and_terms_1: 'By becoming a member, you are deemed to have accepted the Azər Ingiliscə ',
            privacy_and_terms_2: 'privacy agreement ',
            privacy_and_terms_3: 'and ',
            privacy_and_terms_4: 'terms of use',
            privacy_and_terms_5: '.',
        },
        information_messages: {
            forgot_password_sentence_1: 'Please enter your registered phone number and click the Send button. ',
            forgot_password_sentence_2: 'Your password will be sent to your phone via SMS.',
            forgot_password_back: 'I want to log in',
            forgot_password_verification_code_sentence_1: 'Enter the code received via SMS and click the Verify button.',
            forgot_password_verification_code_sentence_2: 'If the verification process is successful, you can change your password.',
            change_password_success_message: 'You have successfully changed your password.\n You can log in with your phone number and new password on the login screen.',
            code_not_expired: "Your last code hasn't expired yet!",
            modal_title: 'Information Message',
        },
        validation_messages: {
            invalid_name: 'Please enter your full name.',
            invalid_phone: 'Please enter a valid mobile phone number.',
            invalid_password: 'Your password must be a minimum of 4 characters.',
            invalid_verification_code: 'The verification code must be 4 characters.',
            invalid_change_password: 'The entered passwords must be a minimum of 4 characters and must be the same.',
            already_created_user: 'There is already a membership created with this phone number.\nIf you know your password, you can log in. If you have forgotten your password, you can request it in the "Forgot My Password" section.',
            invalid_username_or_password: 'No records matching the entered information were found.\nPlease check your information and try again.',
            you_entered_an_incorrect_verification_code: 'You have entered an incorrect verification code. Please check your information and try again.',
        },

    },

    //onboarding screens
    onboarding: {
        welcome_1: 'Welcome,',
        welcome_2: 'we have a few questions.',
        welcome_2_single: 'we have a question.',
        next_button: 'Next',
        continue_button: 'Continue',
        first: {
            title: 'What is your English proficiency level?',
            button1: 'My English is zero',
            button2: 'I understand a little bit',
            button3: 'I have some grammar, but weak in speaking',
            button4: 'I am very comfortable giving presentations',
            button5: 'I am as good as Shakespeare',
            level1: "Starter",
            level2: "Beginner",
            level3: "Intermediate",
            level4: "Advanced"
        },
        second: {
            title: 'Why do you want to learn English?',
            button1: 'For my job/career',
            button2: 'For traveling abroad',
            button3: 'For my education',
            button4: 'Other',
            use_case1: "To use in the workplace",
            use_case2: "To advance in my career",
            use_case3: "To use in business meetings",
            use_case6: "Foreign vacation",
            use_case4: "To participate in job interviews",
            use_case5: "Business trips abroad",
            use_case7: "To work abroad",
            use_case8: "Communication with close relatives or friends",
            use_case9: "Students who will use it at school",
        },
        third: {
            title: 'What is your profession?',
            button1: 'Academician',
            button2: 'Lawyer',
            button3: 'Doctor',
            button4: 'Public Servant',
            button5: 'Engineer',
            button6: 'Student',
            button7: 'Teacher',
            button8: 'Private Sector',
            button9: 'Manager',
            button10: 'Finance',
            button11: 'Tourism',
            button12: 'Other',
        },
        success: {
            congratulations: 'Congratulations!',
            profile_created: 'You have successfully created your profile.',
            subtitle: 'Now it\'s time to get acquainted with Azerbaijan\'s most effective English learning system.'
        },
        information_messages: {
            an_error_occured: 'An error occurred during the process. Please try again.',
            make_a_choice: 'To continue, you need to make a selection. Please mark one of the options and click the Next button.',
            max_choice_message: 'You can make a maximum of #count# selections.'
        },
    },

    onboarding_exercises: {
        title: 'Daily Practice',
        dashboard_message_1: 'Your free practice program consisting of dialogue, vocabulary, sentence structure and grammar has been defined.',
        dashboard_message_2: 'You can continue studying by click the button below.',
        start_study_error_message: 'Sorry, your 3-day practice program has expired.',
        day_text: '#dayIndex#. Day',
        start_study: 'Start Practice',
        translate: 'Show Translation',
        voice: 'Voice Dialog',
        record_dialog: 'Record Dialog',
        recording: 'Recording',
        correct_answer: 'You answered correctly.<br>Congratulations.',
        wrong_answer: 'You answered wrong.<br>You have to work harder.',
        congratulate: 'Congratulations',
        final_message: 'Today you learned a dialogue, three words, a sentence pattern and a grammar rule.',
        final_message_2: 'See you tomorrow for new practice.',
        your_answer: "Your answer",
        right_answer: "Correct answer",
        read_dialog: "Read the following dialog",
        complete_the_blank: "Complete the blank with the appropriate word.",
        sentence_pattern: "Sentence Pattern",
        grammar_rule: "Grammar Rule",
        dialog_info: "Do not read the headings (A,B) while reading the dialogue"
    },

    mentor: {
        title: "Your Personal Mentor",
        dashboard_message_1: "Move forward on your personal journey with your mentor!",
        dashboard_message_2: "Approach your goals step by step with your mentor and strengthen your personal development with guidance and valuable feedback!",
        about_mentor: "About Mentor",
        mentor_informations: "Mentor Information",
        name: "Name",
        phone: "Phone Number",
        mail: "Email Address",
        working_hour: "Working Hours",
        what_is_mentor: "What is Mentor?",
        mentor_content1: "Follows your progress with your teacher.",
        mentor_content2: "Provides suggestions and guidance.",
        mentor_content3: "Makes interim assessments.",
        write_whatsapp: "Write on Whatsapp",
        default_mentor: "Default Mentor",
        weekday: "Weekdays",
        hello: "Hello",
    },

    demo: {
        title: "Demo Lesson",
        page_title: "Choose Your Lesson Day and Time",
        description: "You can take your 10-minute trial lesson by choosing from the teachers listed below.",
        approve_message: "Your trial lesson will take place at #date# time #hour#. \nDo you confirm the transaction?",
        success_message: "Your trial lesson will take place at #date# time #hour#. <br/><br/>Keep the Azər Ingiliscə page open in your browser and answer the incoming call.",
        term_not_found: "Term not found!",
        missed_call_not_found: "Active lesson not found!",
        unit_selection: {
            title: "Select Lesson Content",
            level_test: "Level test lesson",
            free_talk: "Free talk lesson",
            trial: "Lesson for your learning objective",
            select_unit: "Please select lesson content!"
        },
        level_selection: {
            title: "Choose Your English Level"
        },
        auto_teacher_selection: {
            available_hour: "Choosing the Best Time for You",
            available_teacher: "Choosing the Most Suitable Teacher for You",
            approve_demo_lesson: "Confirm Your Demo Lesson",
            ai_choosing_teacher: "Our AI analyzes your level and goals and selects the most suitable teacher for you.",
            ai_choosing_time: "At the same time, we will determine the ideal lesson time according to your availability",
            selected_auto_teacher_description: "Confirm your lesson if it is available for the lesson day and time, you can change it if you wish",
            approve_lesson_button: "Approve Lesson",
            choose_different_time: "Choose Different Day or Time",
            your_lesson_date: "Lesson Date"
        },
        demo_progress: {
            demo_lesson: "Demo Lesson",
            demo_lesson_description: "Make a free demo lesson at a time that suits you best.",
            demo_lesson_plan: "Plan the Demo Lesson",
            demo_lesson_prepare: "Prepare for Demo Lesson",
            demo_lesson_attend: "Take a Demo Lesson",
            demo_lesson_report: "Review Demo Lesson Report",
            demo_faq: "F.A.Q",
            demo_progress_step: "#completedStepCount#/4 completed"
        }
    },

    market:
    {
        title: 'Market',
        buy_button: 'Buy',
        okay_button: 'Okay',
        gifts: {
            title: "Gifts"
        },
        additional_rights: {
            title: "Additional Rights",
            description: "You can continue your learning adventure without interruption by choosing the appropriate option from the options below.",
            postpone_description: "You can choose the right to postpone a lesson package that suits you.",
            postpone_right: "#count# Postpone Right",
            postpone_right_checkout: "You are paying for #count# number of lesson postpone rights.",
            market_passive_term_message: "Your selected package is an inactive package. Additional rights cannot be purchased for inactive packages.",
            market_future_term_message: "Your selected package is a future dated package. Additional rights cannot be purchased for future packages.",
            market_pending_term_message: "Your selected package has not yet been confirmed. Once your package is confirmed, you can purchase additional rights.",
        },
        speakingLesson: {
            title: 'Education Packages',
            title1: "How many days per week can you receive education?",
            title2: "How many minutes of education do you want per day?",
            title3: "How many months will you continue the education?",
            day: "days",
            minute: "minutes",
            month: "Months",
            month_single: "Month",
            discount: "discount",
            total_amount: "Total Amount",
            total_discount: "Total Discount",
            remove_discount: "Remove Applied Discounts",
            discount_content: "with discount ",
            instead_of: " instead of",
            discount_code: "Enter the discount code",
            apply: "Apply",
            offer_title: "Special for You",
            price_by_per_lesson: "Per Lesson Fee",
            total_lesson_price_with_gift: "Total #totalLessonCount# Lessons (#totalLessonCountWithoutGift# Lessons + #giftLessonCount# Gift Lessons)",
            total_lesson_price: "Total #totalLessonCount# Lesson",
            slogan: "Summer Campaign Up to %35 Rates Started. Grab the Opportunity Before Quotas are Sold Out!",
            selected_package: {
                title: "Selected package and content",
                postpone_right: "#number# postpone lesson right",
                change_teacher_and_time_right: "#number# teacher and time change right",
                missed_call_right: "#number# missed call right",
                freeze_lesson_right: "2 months of course freeze",
                gift_month: " months of gift education",
                monthly: "#monthCount# months",
                weekly: "#dayCount# days a week",
                daily: "#minuteCount# a day",
                extra_rights_one: "Videos, training materials and tests",
                extra_rights_two: "Unlimited lessons with artificial intelligence",
                extra_rights_three: "Your personal mentor",
            },
            information_messages: {
                speaking_lesson_choose_all: 'Please make all selections (Package/Day/Minute/Month) completely and click the Buy button.',
                modal_title: 'Information Message',
                same_discount_code_error: 'This discount code has already been used. You cannot reuse the same discount code!',
                same_type_code_error: 'Only one discount of the same type can be used!',
            },

        },
        checkout: {
            price_without_discount: "Total Amount Without Discount",
            price_without_tax: "Total Amount without VAT",
            tax_rate: "%#KDV# VAT",
            discount: "Applied Discount",
            sub_total: "Subtotal",
            total_amount: "Total Amount",
            package_amount: "Package Amount",
            reference_discount: "Reference Discount",
            fourty_eight_discount: "48 Hours Discount",
            campaign_discount: "Campaign Discount",
            family_package_discount: "Family Package Discount",
            second_purchase_discount: "Second Purchase Discount",
            moneybox_discount: "Moneybox Discount",
            one_shot_discount: "One shot discount",
            other_installment_discount: "Other installment options",
            discount_success_message: "Your discount code has been successfully applied.",
            discount_unsuccess_message: "You have entered an incorrect discount code. Please try again.",
            discount_limit_exceeded: "As the current discount rate has reached 100%, new discount code can not be added.",
            cancel_discount: "Cancel Discount",
            discounts_cleared: "All applied discounts have been reset!",
            click_for_pay_detail: "Click to view payment details",
            input_card_info: "Enter card information",
            card_number: "Card Number",
            card_name_surname: "Name on Card",
            card_exp_date: "Expiration Date",
            month: "Month",
            year: "Year",
            bank: "Bank",
            cvv: "CVV, is the last three digits on the back of your credit card.",
            installment_options: "Installment Options",
            installment: "Number of Installments",
            complete_button: "Complete Transaction",
            trying_to_pay: "Payment in Progress",
            pay_is_ok: "Payment Successful",
            please_wait: "Your payment has been successfully completed. Your transactions are being finalized. Please wait.",
            term_error: "Your payment has been successfully completed. However, we encountered an error while creating your new educational term. Don't worry, we will reach out to you as soon as possible.",
            for_package: "You are making a payment for the package.",
            one_shot: "One Shot",
            card_informations: "Card Informations",
            invoice_informations: "Invoice Informations",
            preliminary_information_form_title: "Preliminary information form",
            errors: {
                card_number: "You entered an incomplete/incorrect credit card number.\n",
                card_name: "The name on the card field cannot be left empty.\n",
                card_month: "The month field cannot be left empty for the expiration date.\n",
                card_year: "The year field cannot be left empty for the expiration date.\n",
                card_cvv: "You made an incomplete/incorrect entry in the CVV field.\n",
                card_bank: "Please select a bank.\n",
                card_installment: "Please select the number of installments.\n"
            }
        },
        successful: {
            title: "Your payment has been successfully completed.",
            content: "Your education consultant will contact you shortly to determine your lesson details.",
            postpone_content: "You can view your current additional rights on the attendance report page.",
            button: "Okay",
            term_error: "Your education term will be created by your education consultant."
        },
        failed: {
            title: "Your payment could not be completed.",
            content: "Please check the information and try again.",
            error_title: "Error details",
            button: "Retry",
            bank_error: "The response from your bank regarding the payment error is as follows."
        },
        information: {
            title: "You can continue your transaction by entering your information via the screen below.",
            name: "Full Name",
            email: "Email",
            identity_number: "Identity Number",
            address: "Billing Address",
            is_corporate: "I want corporate billing",
            company_name: "Company Name",
            tax_office: "Tax Office",
            tax_number: "Tax Number",
            company_address: "Company Address",
            button: "Continue",
            button_waiting: "Please wait...",
            update_error: "An error occurred while updating your information. Please try again.",
            errors: {
                name: "Please enter your full name.\n",
                email: "Please enter your email.\n",
                identity_number: "Please enter a valid Identity Number.\n",
                address: "Please enter the Billing Address.\n",
                company_name: "Please enter the Company Name.\n",
                tax_office: "Please enter the Tax Office.\n",
                tax_number: "Please enter the Tax Number.\n",
                company_address: "Please enter the Company Address.\n"
            }
        },
        completed:
        {
            title: "Congratulations.",
            content: "Your information has been successfully saved.",
            button: "Okay"
        },
        education_packages: {
            title: "Education Programs",
            description: "Choose the education program that suits you best. Review your learning outcomes, syllabus and sample books.",
            total_lesson_count: "#count# lesson",
            program_achievements: '"#title#" Program Achievements',
            word_count: "#count# Words",
            hint_count: "#count# Book Hints",
            downloading: "Downloading..."
        }
    },

    general_information_messages: {
        prev: "< ",
        next: " >",
        an_error_occured: "An error occurred during the process. Please try again.",
        minute: "minutes",
        loading: "Loading...",
        processing: "Processing...",
        under_construction_title: "In preparation...",
        under_construction_content: "Remember to keep your application up to date.",
        error404_title: "Page Not Found",
        error404_content: "The page you are looking for cannot be found. You can navigate to any desired page using the menus.",
        error500_title: "An error occurred while loading the data. Please refresh the page."
    },

    pagination: {
        next: "Next",
        previous: "Back"
    },

    lesson_type: {
        active: "Active",
        future: "Active (Future Date)",
        inactive: "Inactive",
        pending: "Pending",
        attended: "Attended the Lesson",
        missed: "Missed the Lesson",
        postponed: "Postponed",
        frozen: "Frozen",
        holiday: "Holiday",
        weekend: "Weekend",
        no_lesson: "No Lesson",
        system_postponed: "Postponed by the System",
        deleted: "Deleted"
    },

    speaking_lesson:
    {
        title: 'Speaking Lesson',
        click_for_lesson_detail: "Click for Note Details",
        letter_note_informations: {
            speaking_grammar: {
                one: "The speaker has a very basic understanding of grammar and struggles to form coherent sentences. Errors in grammar usage are frequent and may interfere with understanding.\nMany errors in grammar usage, including frequent errors in subject-verb agreement, verb tense, and word order. The number of errors could be in the range of 10-20 per minute of speech.",
                two_three: "The speaker has a limited but functional understanding of grammar and can communicate basic information with some errors. Grammar mistakes may still be frequent and noticeable.\nSome errors in grammar usage, including errors in basic structures such as singular and plural forms, articles, and simple verb tenses. The number of errors could be in the range of 5-10 per minute of speech.",
                four_five: "The speaker has a good understanding of grammar and can communicate effectively, though with occasional errors. The speaker can use complex sentence structures and express ideas with relative ease.\nOccasional errors in grammar usage, including errors in complex structures such as subordinate clauses and verb tense consistency. The number of errors could be in the range of 2-5 per minute of speech.",
                six_seven: "The speaker has a near-native command of grammar and can communicate complex ideas with little difficulty. Errors in grammar usage are rare and do not interfere with understanding.\nFew errors in grammar usage, with occasional mistakes in complex structures such as passive voice and reported speech. The number of errors could be in the range of 1-2 per minute of speech.",
                eight_nine: "The speaker has a native-like mastery of grammar and can use language flexibly and effectively for a range of purposes. The speaker can understand and use complex structures, idioms, and other nuanced language features.\nRare errors in grammar usage, with occasional mistakes in subtle nuances such as idiomatic expressions and sentence-level discourse markers. The number of errors could be less than 1 per minute of speech.",
                ten: "The speaker has a mastery of grammar that exceeds that of most native speakers. The speaker can use language creatively and with a high degree of precision, using a wide range of registers and styles as appropriate to the context.\nVirtually no errors in grammar usage, with occasional slips in pronunciation or vocabulary choice. The number of errors could be less than 1 per several minutes of speech."
            },
            speaking_vocabulary_usage: {
                one: "The speaker has a very basic vocabulary and struggles to express ideas with limited words. They may rely on frequent repetition of the same words or phrases.\nFrequent errors in word choice, pronunciation, and usage. The speaker may rely on a small set of words and struggle to convey even basic ideas. The number of errors could be in the range of 10-20 per minute of speech.",
                two_three: "The speaker has a functional vocabulary and can communicate basic information with some errors. However, their vocabulary may be limited to everyday words and phrases, and they may struggle with more complex or specialized terminology.\nSome errors in word choice, pronunciation, and usage. The speaker may struggle with more advanced vocabulary and rely on basic words and phrases. The number of errors could be in the range of 5-10 per minute of speech.",
                four_five: "The speaker has a good vocabulary and can communicate effectively in most situations. They can use a range of vocabulary to express ideas, but may still struggle with more advanced terminology.\nOccasional errors in word choice, pronunciation, and usage. The speaker can use a range of vocabulary to express ideas, but may still struggle with more advanced or specialized terminology. The number of errors could be in the range of 2-5 per minute of speech.",
                six_seven: "The speaker has a broad vocabulary and can use language flexibly and precisely to express complex ideas. They can use specialized terminology and idiomatic expressions appropriately in a range of contexts.\nFew errors in word choice, pronunciation, and usage. The speaker can use language flexibly and precisely to express complex ideas, and can understand and use specialized terminology appropriately. The number of errors could be in the range of 1-2 per minute of speech.",
                eight_nine: "The speaker has an extensive and nuanced vocabulary, and can use language creatively to achieve a range of communicative goals. They can understand and use complex vocabulary in a variety of registers and styles.\nRare errors in word choice, pronunciation, and usage. The speaker has an extensive vocabulary and can use language creatively and effectively, even in specialized or formal contexts. The number of errors could be less than 1 per minute of speech.",
                ten: "The speaker has a near-native command of vocabulary, using language with an exceptional range of precision and nuance. They can understand and use rare or archaic vocabulary, as well as complex idiomatic expressions, with ease.\nVirtually no errors in word choice, pronunciation, and usage. The speaker has an exceptional range of vocabulary and can use language with an exceptional degree of precision and nuance. The number of errors could be less than 1 per several minutes of speech."
            },
            speaking_pronunciation: {
                one: "The speaker is difficult to understand, with poor articulation and significant errors in pronunciation, stress, and intonation. They may struggle to produce even basic sounds and may not be able to be understood by native speakers.\nThe speaker may make numerous mistakes in pronunciation, such as mispronouncing many sounds, omitting or adding sounds, and using incorrect stress and intonation patterns.",
                two_three: "The speaker can produce some sounds and words correctly, but may still have difficulty with more complex sounds and pronunciations. Their intonation and stress may be off, making it difficult to understand them in certain contexts.\nThe speaker may still make some noticeable errors in pronunciation, such as mispronouncing some sounds or having trouble with more complex sounds, but can generally be understood by others.",
                four_five: "The speaker can produce most sounds and words accurately and has a good grasp of basic intonation and stress patterns. They may still make some errors, particularly with less common sounds or words, but can generally be understood by native speakers.\nThe speaker may make occasional errors in pronunciation, such as mispronouncing some less common words or having a slight accent, but can generally communicate fluently and accurately.",
                six_seven: "The speaker can produce nearly all sounds and words accurately and has a good command of intonation and stress patterns, making them sound more natural and fluent. They may still have some slight accent or occasional errors, but overall can be easily understood by native speakers.\nThe speaker may make few errors in pronunciation, and can generally produce all English sounds with accuracy and natural intonation and stress. They may still have a slight accent, but it does not interfere with their communication.",
                eight_nine: "The speaker can produce all sounds and words accurately, with near-native pronunciation and intonation. They are able to make fine distinctions in sound and can adapt their pronunciation to different dialects and contexts. They may still have a slight accent, but are generally indistinguishable from native speakers.\nThe speaker may make very few errors in pronunciation, and can produce all English sounds with near-native accuracy and natural intonation and stress. They may still have a slight accent, but it is difficult to detect.",
                ten: "The speaker has native-level pronunciation and intonation, with no discernible accent. They are able to produce subtle distinctions in sound and can adapt their pronunciation to any dialect or context. They may also be able to imitate different accents or dialects with ease.\nThe speaker is unlikely to make any noticeable errors in pronunciation, and can produce all English sounds with native-like accuracy and natural intonation and stress. They may be mistaken for a native speaker."
            },
            listening_comprehension: {
                one: "The listener has difficulty understanding even basic words and phrases in the language. They may need to rely on visual cues or context to understand the meaning of spoken language.\nThe listener may make numerous mistakes, such as not being able to identify basic vocabulary or verb forms, and having difficulty understanding even simple sentences or phrases.",
                two_three: "The listener can understand simple words and phrases, but may struggle with more complex sentences or idiomatic expressions. They may need to ask for repetition or clarification in order to understand the meaning of spoken language.\nThe listener may make some mistakes, particularly with more complex language or idiomatic expressions, but can generally understand the meaning of spoken language with repetition or clarification.",
                four_five: "The listener can understand most spoken language in familiar contexts, but may still struggle with more complex or abstract language, particularly when it is spoken quickly or with an unfamiliar accent.\nThey may also miss some details or nuances in the language. The listener may make occasional mistakes, such as missing some details or nuances in the language, particularly when it is spoken quickly or with an unfamiliar accent.",
                six_seven: "The listener can understand most spoken language, including more complex and abstract language, spoken at a normal pace and with a variety of accents. They can also understand implied meanings and cultural references.\nThe listener may make few mistakes and can understand most spoken language, including more complex and abstract language, spoken at a normal pace and with a variety of accents.",
                eight_nine: "The listener can understand spoken language with a high degree of accuracy and fluency, even in complex or technical contexts. They can also understand nuances in tone, register, and intonation.\nThe listener may make very few mistakes, and can understand spoken language with a high degree of accuracy and fluency, even in complex or technical contexts.",
                ten: "Exceptional proficiency: The listener can understand spoken language with complete accuracy and fluency, even in the most challenging contexts, such as fast-paced conversations between multiple speakers or with strong regional accents. They can also understand nuances in meaning and cultural references at a level that is equivalent to that of a native speaker.\nThe listener is unlikely to make any mistakes and can understand spoken language with complete accuracy and fluency, even in the most challenging contexts."
            },
            reading_vocabulary_awareness: {
                one: "The reader has a very limited vocabulary and struggles to understand even basic words in the language. They may need to rely heavily on a dictionary or translation tool to read simple texts.\nThe reader may make numerous mistakes, such as not recognizing basic words or being unable to infer the meaning of unfamiliar words from context.",
                two_three: "The reader has a basic vocabulary and can understand simple texts with familiar vocabulary, but may struggle with more complex or technical vocabulary.\nThe reader may make some mistakes, particularly with less familiar vocabulary, but can generally understand most of the words in simple texts.",
                four_five: "The reader has a moderate vocabulary and can understand most texts with familiar vocabulary, but may struggle with more advanced or specialized vocabulary.\nThe reader may make occasional mistakes, such as misunderstanding the meaning of more complex or technical vocabulary, but can generally understand most of the words in moderately difficult texts.",
                six_seven: "The reader has an extensive vocabulary and can understand most texts with a high degree of accuracy, including those with advanced or specialized vocabulary.\nThe reader may make few mistakes, and can generally understand most of the words in difficult or specialized texts, but may occasionally need to look up unfamiliar vocabulary.",
                eight_nine: "The reader has an exceptional vocabulary and can understand texts with a high degree of precision, even in very specialized fields or contexts.\nThe reader may make very few mistakes, and can understand most texts with a high degree of accuracy, including those with advanced or technical vocabulary.",
                ten: "The reader has a mastery of vocabulary in the language equivalent to that of a native speaker, and can understand and use highly nuanced and idiomatic language with ease. They may also be able to accurately and precisely infer meaning from context, without needing to rely on a dictionary or other tools.\nThe reader is unlikely to make any mistakes, and can understand and use highly nuanced and idiomatic language with ease. They may also be able to accurately and precisely infer meaning from context, without needing to look up unfamiliar vocabulary."
            },
            reading_pronunciation: {
                one: "The reader reads slowly and may struggle with basic comprehension, needing to sound out words and reread frequently. Their reading may be halting and choppy, with little or no attention to expression.\nThe reader may make numerous mistakes, such as mispronouncing many words, misreading or skipping words, and struggling to understand basic vocabulary and sentence structure.",
                two_three: "The reader reads with some fluency and is able to understand simple texts with basic vocabulary. Their reading may still be slow and require occasional assistance.\nThe reader may make some mistakes, such as mispronouncing some words, stumbling over more complex vocabulary, and needing some guidance to understand the text.",
                four_five: "The reader reads with moderate fluency and is able to understand moderately complex texts with some unfamiliar vocabulary. They may still need to reread some sections for better comprehension.\nThe reader may make occasional mistakes, such as mispronouncing some specialized vocabulary, missing some nuances or idioms, and occasionally needing to reread sections for better comprehension.",
                six_seven: "The reader reads with good fluency and is able to understand difficult texts with specialized vocabulary. They can read smoothly and efficiently with little difficulty.\nThe reader may make few mistakes, and can generally understand and pronounce most words with accuracy, but may occasionally struggle with highly specialized or technical vocabulary.",
                eight_nine: "The reader reads with exceptional fluency and can easily understand even the most difficult texts with advanced vocabulary. They can read with excellent speed and accuracy and comprehend at a high level.\nThe reader may make very few mistakes, and can accurately and fluently read and understand texts with advanced or specialized vocabulary, without needing to look up unfamiliar words.",
                ten: "The reader reads with near-native fluency and can read and comprehend texts as well as or better than native speakers. They can easily read a wide range of texts with advanced vocabulary and high complexity.\nThe reader is unlikely to make any mistakes, and can accurately and fluently read and understand highly nuanced and idiomatic language with ease. They may also be able to infer meaning from context with a high degree of accuracy."
            }
        },
        speaking_lesson_user: {
            practice_with_ai: 'Improve by Speaking (AI)',
            lesson_of_the_day: 'Daily Lesson',
            progress_report: "Progress Report",
            quiz_test: 'Quiz & Test',
            daily_sentence: 'Daily Sentence Pattern',
            participation_report: 'Attendance Report',
            term_schedule: 'Lesson Archive',
            education_packages: 'Education Programs',
            today: 'Today',
            it_is_not_specified: "Not specified",
            audio_note: "Your voice recordings will be deleted from the system after 5 days. Please take this into consideration."
        },
        speaking_lesson_non_user: {
            hello: 'Hello',
            hello_title: 'Welcome to Azerbaijan\'s most effective English learning system.',
            sample_lesson: 'A sample lesson in Azər Ingiliscə',
            view_packages: 'View the Education Packages',
            plan_course_title: 'Plan your lesson schedule with your dedicated Education Consultant',
            plan_course: 'Plan My Lesson',
            modal_title: 'Information Message',
            modal_message: 'Your Education Consultant will contact you as soon as possible using the phone number registered in the system. Until then, you can view our Teachers.',
            okay_button: 'View the Teachers'
        },
        daily_sentence: {
            sample_sentences: 'Sample Sentences',
            start_quiz: 'Start Quiz',
            message:'You can see the sentence pattern and test of the day for your book below.',
            book_hint_not_found: 'Book hint daily pattern is not found',
            friday_error_message:'Since the book of the day is a free talk book, there are no sentence pattern and quiz for today.',

            postponed: {
                content_1: "You have postponed your speaking lesson for the selected speaking program.",
                content_2: "You can choose another date from the calendar to see the sentence pattern and quiz.",
            },
            frozen: {
                content_1: "Hello, you have frozen your lessons for this speaking program.",
                content_2: "You can easily update your teacher and time by following the information e-mails that will be sent before your lessons start again.",
            },
            holiday: {
                content_1: "Hello, today is a holiday, you have no speaking lesson.",
                content_2: "You can choose another date from the calendar to see the sentence pattern and quiz.",
            },
            inactive: {
                content_1: "Due to your package not being active, there is no lesson for today. You can go to your previous lessons from the calendar to see the information related to your past lessons.",
                content_2: "To continue your progress from where you left off, you can review our <strong class=\"text-primary\">Education Packages</strong>.",
            },
        },
        daily_lesson: {
            choose_date: 'Select Date',
            daily_lesson_title: 'Today\'s Lesson',
            daily_lesson_title_attended: 'Lesson Evaluation',
            daily_lesson_information: 'Hello, you can find information about today\'s speaking lesson below.',
            view_missed_lesson_book: 'View Missed Lesson Book',
            your_teacher: 'Your Teacher: ',
            lesson_time: 'Your lesson time:',
            lesson_content: 'You can access your lesson materials through the sections below.',
            today_lesson_book: 'Lesson Book of the Day',
            book: 'Book',
            presentation: 'Presentation',
            practice: 'Practice',
            lesson_video: 'Lesson Video',
            book_voiceover: 'Book Audio',
            words: 'Words',
            lesson_evoluation: 'Lesson Evaluation',
            your_voice_record: 'Recording of Your Lesson',
            teacher_comment: 'Your Teacher\'s comments on the lesson',
            strong_points: 'Areas where you are strong',
            weak_points: 'Areas for improvement',
            teacher_suggestions: 'Your Teacher\'s suggestions for the lesson',
            mispronunciation: 'Mispronunciations / Incorrectly formed sentences',
            correct_pronunciation: 'Correctly Pronounced / Properly formed sentences',
            speaking_grammar_usage: 'Grammar Usage',
            speaking_vocabulary_usage: 'Vocabulary Usage',
            speaking_pronunciation: 'Pronunciation',
            listening_comprehension: 'Listening Comprehension',
            reading_vocabulary_awareness: 'Reading Word Awareness',
            reading_pronunciation: 'Reading Pronunciation',
            lesson_information: "Lesson Information",
            lesson_study: "After Lesson Activities",
            lesson_audios: "Audio Recordings",
            correct_and_speak: "Correct and Speak (AI)",
            lesson_materials: "Lesson Materials",
            teacher_ranking_and_evaluation: "Teacher Ratings & Reviews",
            lesson_ranking: "Lesson Rating",
            teacher_comments: "Teacher Comments",
            teacher_recommendations: "Teacher Suggestions",
            sentence_bank: {
                title: "Sentence Bank",
                all_sentences: "All Sentences",
                added_list: "Added to List",
                removed_list: "Delisted",
                load_more: "Load More",
                sentence_learning_list: "Sentence Learning List"
            }
        },
        no_answer: {
            comment_title: "Your teacher's comments about the course",
            comment_content: "You did not attend your lesson today.",
            call_log_title: "Course call times",
            missed_call_content: "The lesson you missed on #date# will take place on #new_date# at #time# with #teacher#.",
            missed_call_warning_one: "After the results of your missed lesson, check the book of the day again.",
            missed_call_warning_two: "Check the missed lesson book again after you receive the results of the day's lesson."
        },

        components: {
            holiday: {
                content_1: "Hello, today is a holiday, you have no speaking lesson.",
                content_2: "You can select a date to prepare for your next speaking lesson."
            },
            postponed: {
                content_1: "You have postponed your speaking lesson for the selected speaking program.",
                content_2: "You can select a date to prepare for your next speaking lesson."
            },
            frozen: {
                content_1: "Hello, you have frozen your lessons for this speaking program.",
                content_2: "Your lessons will resume on #date#. You can easily update your teacher and schedule by following the information emails that will be sent before this date."
            },
            inactive: {
                content_1: "Due to your package not being active, there is no lesson for today.",
                content_2: "You can go to your previous lessons from the calendar to see the information related to your past lessons.",
                content_3: "To continue your progress from where you left off, you can review our <strong class=\"text-primary\">Education Packages</strong>."
            },
            study_subjects: {
                title: "Study Topics",
                tab_title_1: "Section Questions",
                tab_title_2: "Sentence Patterns"
            },
            audio: {
                book_voiceover_title: "Book Audio",
                lesson_records_title: "Lesson Recordings",
                lesson_records: "Lesson Recordings"
            },
            video: {
                title: "Lesson Video"
            },
            words: {
                title: "Words"
            },
            reference_bar: {
                description: "Invite a Friend, Learn Together and Win Prizes!",
                button_text: "Invite"
            }
        },

        progress_report: {
            you_are_a_candidate: "Your progress report cannot be shown as your package is not active yet.",
            attendance_rate: "Attendance Rate for Lessons",
            progress_chart: "Progress Chart",
            same_level_title: "Where Did Those Who Started at the Same Level as Me Reach in 12 Months? (*)",
            same_level_content: "(*) The average level reached by students who take lessons 5 days a week with a participation rate of 90% or above is given."
        },
        attendance_report: {
            you_are_a_candidate: "Your attendance report cannot be shown as your package is not active yet.",
            not_use: "No date found.",
            date_content: "Lesson dated",
            report_1: {
                title: "Detailed Attendance Report",
                begin_date: "Start Date: ",
                total_lesson_count: "Total Number of Lessons: ",
                attended_lesson_count: "Attended Lessons: ",
                missed_lesson_count: 'Missed Lesson Count : ',
                remaining_lesson_count: 'Remaining Lesson Count : ',
                postpone_right_count: "Remaining Lesson Postponement right count: ",
                missed_call_right_count: "Remaining Missed Call right count: ",
                change_teacher_time_right_count: "Remaining Teacher / Time Change right count:",
                freeze_right_count: "Remaining Lesson Freeze right count: "
            },
            report_2: {
                title: "Missed lessons"
            },
            report_3: {
                title: "Lessons where postponement was used"
            },
            report_4: {
                title: "Lessons I missed"
            }
        },
        holidays: {
            information: "Live speaking lessons will not be conducted on the following days. You can access the applications, lesson materials, and tests 24/7.",
            title: "Holidays"
        },
        quiz: {
            title: "All Tests",
            description: "You can find and solve the tests for your speech package below.",
            answered_quiz: "Previously answered units are indicated with a <span class=\"material-symbols-outlined text-primary\">check</span> sign.",
            view_book: "View Book",
            show_report: "View Result",
            start: "Start",
            remaining_time: "Time Remaining",
            control: "Check",
            continue: "Continue",
            quiz_completed: "You Have Completed The Test",
            report_description: "Detailed information about this test can be found below.\n",
            solve_again: "Start Test Again",
            start_time: "Beginning time",
            end_time: "End time",
            total_question: "Total number of questions",
            right_answer: "Correct answer",
            wrong_answer: "Wrong answer",
            empty_answer: "Empty answer",
            timer_end_description: "Your test time has expired. Click the button below to see the test result.",
            quit_modal_text: "If you exit, the test will be incomplete.\n Are you sure you want to exit?",
            quit: "Exit",
            want_continue: "I want to continue",
            leave_empty: "Skip",
            last_lesson : "Last Lesson",
            show_result: "View Result",
            return_video: "Return Lesson Video",
            no_book_found: "The test of the books belonging to the package you selected was not found."
        },
        education_packages: {
            title: "Program Contents",
            start_lessons: 'Start Lessons',
            units: "Üniteler",
            icons: {
                awsome: "Perfect",
                knowledgeable: "Knowledgeable",
                completed: "Lesson Completed",
                preparation: "Preliminary Preparation",
                not_started: "Not Started",
                quiz: "Quiz",
                awsome_description: "(Worked with AI)",
                knowledgeable_description: "(Quiz solved)",
                preparation_description: "(Book reviewed)"
            }
        },
        speaking_lab: {
            title: "Speaking Lab",
            description: "Improve your English speaking skills with the Speaking Lab! Pronunciation, sentence completion and composition are waiting for you. Let's get started!",
            description_content: "Improve your English speaking skills with the Speaking Lab! Pronunciation, sentence completion and composition are waiting for you. ",
            go_lab: "Go To Lab",
            pronounce_sentence_title: "Pronounce the following sentence",
            fill_in_the_blank_title: "Pronounce the following sentence, completing the blank with the appropriate word",
            create_sentence_title: "Select the words in the correct order to form the sentence you heard",
            create_sentence_correct: "Select the words in the correct order to form the sentence you heard",
            create_sentence_wrong: "You created the sentence incorrectly. Please try again.",
            drag_drop_wrong_message: "You created the sentence incorrectly.",
            drag_drop_correct_message: "You have successfully created the sentence.",
            sentence_hint: "Hint",
            wrong_answer: "Wrong Answer",
            correct_answer: "Correct Answer",
            try_again_button: "Try Again",
            voice_again: "Voice Again",
            results_loading: "Loading Your Result...",
            your_voice: "You",
            sample_voice: "Example",
            first_card_counter_text: "Starting in #second# second...",
            second_card_counter_text: "#second# saniye içinde devam ediyor...",
            pronunce_error_text: "An error occurred while analyzing your pronunciation.",
            motivation_text: "Congratulations! You have completed #count# exercises!",
            exercise_completed_text: "Congratulations! You have successfully completed this exercise!",
            start_again_modal_text: "You have previous progress for this exercise.\nWould you like to continue from where you left off?",
            start_again: "Start Again",
            exit_exercise_question_text: "Are you sure you want to quit the exercise?",
            skip_this_question: "Skip This Question"
        }
    },

    teachers: {
        title: "Teachers",
        teacher_details: {
            birth_place: "Birthplace",
            age: "Age",
            about: "About",
            education: "Education",
            interest_fields: "Interests",
            buy_lesson_from_teacher: "Take a Lesson from a Teacher",
            not_student_content: "To take a lesson from a Teacher, you need to have an active package. You can purchase a package by visiting the Education Packages page.",
            not_student_buy_button: "View the Education Packages",
            not_student_cancel_button: "Cancel",
            teacher_audio: "Teacher Audio Recording"
        },
        teachers_list: {
            page_title: "In total, #currentCount#/#total# Azər Ingiliscə teachers are listed.",
            current_teacher_text: 'Total #currentCount#/#total# teacher',
            detail_information: "View Detail",
            age: " years old"
        },
        rating: {
            rate: "Rate",
            rate_your_teacher: 'Rate Your Teacher',
            rate_description: 'You can share your opinions about #teacherName# by clicking the button below.',
            your_comment: "Your Comment",
            your_comment_error_message: 'The comment field cannot exceed 300 characters.',
            rating_success_message: 'Your teacher rating process has been successfully completed. You are directed to the lesson of the day screen...'
        },
        filter: {
            title: "List Teachers",
            start_time: "Start Time",
            end_time: "End Time",
            lesson_time: 'Lesson Time',
            my_packages: "Packages",
            and: 'and',
            filter_teachers: 'Filter Teachers',
            teacher_feature: 'Teacher Feature',
            choose_teacher_feature: 'Select Teacher Feature',
            choose_teacher_feature_number: 'Selected Teacher Features (#count#)',
            avaliable_time_not_found: "No available lesson time for the teacher",
            teacher_features_list: {
                beginner_friendly: "Beginner friendly",
                child_friendly: "Child friendly",
                grammar_focused: "Grammar focused"
            }
        }
    },

    dashboard: {
        title: "Azər Ingiliscə",
        none_user: {
            title_1: "Hello",
            title_2: "Welcome to Azerbaijan's most effective English learning system.",
            inactive_student_title_1: "Welcome back,",
            inactive_student_title_2: "Your package has expired.",
            inactive_student_title_3: "To continue your progress from where you left off, you can review our <strong class=\"text-primary\">Education Packages</strong>.",
            inactive_student_title_4: "<strong class=\"text-primary\">Click here</strong> to access your past lessons.",
            advice_1: "This is a self-investment advice,",
            advice_2: "Learn English!",
            more_info: "Detailed Information",
            view_packages: "View the Education Packages",
            student_comments: "Testimonials from Our Students",
            comment_info_1: "Gökhan Telkenar / Speaker",
            comment_info_2: "Esra Sönmezer / Actress",
            play_video: "Watch the Video"
        },
        how_to_learn: {
            play_video: "Watch the Video",
            most_curious_about: "Frequently Asked Questions"
        },
        demo_student: {
            title: "Today's Lesson",
            try_demo_title: "Experience the Azər Ingiliscə with a Free Demo Lesson",
            try_demo_content: "Meet our native English teachers and experience the Azər Ingiliscə.",
            plan_demo_lesson: "Schedule a Demo Lesson",
            demo_lesson_reserved: "Your Demo Lesson Has Been Successfully Booked!",
            no_permission_text: "<strong>Prepare for Your Demo Lesson!</strong></br>To attend your demo lesson, you need to download the Azər Ingiliscə mobile application and provide the necessary permissions.",
            permission_text: "<strong>Everything is ready!</strong></br>Everything is ready to attend your demo lesson! Your teacher will call you via the Azər Ingiliscə mobile application and web platform when your lesson arrives.",
            change_demo_lesson_time: "You can change your lesson time #button#here#button#.",
            demo_lesson_completed: "You Have Completed Your Demo Lesson",
            lesson_completed_content: "Your demo lesson with #TEACHER# is completed. Click #button#here#button# to see your teacher's feedback.",
            lesson_info: "Hello <b>#name#</b>, your upcoming speaking lesson will start on <b>#date#</b> at <b>#time#</b>. You have approximately <b>#counter#</b> left. Your teacher will call you when your lesson time arrives. By installing our mobile application, you can receive pre-class reminders and have a better lesson experience.",
            microphone_permission: "If you want to participate in the lesson via your web browser, you must grant microphone and notification permissions.",
            download_mobil_app: "Download Mobile App",
            give_microphone_permission: "Give Microphone Permission",
            give_notification_permission: "Give Notification Permission",
        },
        student: {
            title: "Today's Lesson",
            word_of_the_day: "Word of the Day",
            idioms_of_the_day: "English Idioms of the Day",
            view_the_lesson_book: "Review the Lesson Book",
            click_for_previous_lessons: "Click for Your Past Lessons",
            want_to_postpone_class: "I want to postpone my lesson",
            all_sources: "All Resources",
            all_sources_description: "Improve yourself with video lessons and interactive quizzes.",
            video_duration: "Video Duration",
            grammar_videos: "Grammar Videos",
            business_english_videos: "Business English Videos",
            no_lesson_info: "Lesson information could not be loaded.",
            no_teacher_info: "Teacher information could not be loaded.",
            your_teacher: "Your ",
            lesson_info: "Hello <b>#name#</b>, your upcoming speaking lesson will start on <b>#date#</b> at <b>#time#</b>. You have approximately <b>#counter#</b> left. Have you prepared for your lesson?",
            day: "day",
            hour: "hour",
            minute: "minute",
            second: "second",
            dashboard_passive_lesson_error: "The selected package belongs to a previous term.",
            in_lesson_time_message: "Hello <b>#name#</b>, we are waiting for your data from your last lesson."
        },
        video_exercise: {
            title: "Enjoy Learning English with Videos!",
            content: "Step into a world of immersive videos and interactive activities to master English speaking, vocabulary, writing, listening and pronunciation.",
            button_text: "Start Exploring"
        }
    },

    profile: {
        title: "Profile",
        profile_settings: "Profile Settings",
        save_button: "Save",
        success_message: "Your profile settings have been updated successfully.",
        dont_ask_personal_information: "Please do not ask for my personal information",
        dont_ask_how_was_your_day: "Please don't ask me how was your day",
        profile_visibility_open: "Profile visibility is on",
        profile_visibility_off: "Profile visibility is off",
        following: "Followed",
        follower: "Follower",
        follow: "Follow",
        you_are_following: "You Are Following",
        add_friend: "Add Friend",
        join_date_text: "Joined in #date#",
        unlock_profile: "Unlock Profile",
        go_to_profile: "Go to my profile",
        lesson_method: {
            title: "Lesson Method",
            title_one: "Kindly stick closely to the content, including examples and activities, as outlined in the book",
            title_two: "Kindly stick to the content of the book, but allow me to express myself spontaneously and openly"
        },
        feedback: {
            title: "Type of Feedback from Teacher",
            title_one: "Give feedback at the end of the lesson",
            title_two: "Give feedback during the lesson"
        },
        reason_to_learn_english: {
            title: "Why do you want to learn English? (Multiple choices possible)",
            error_message: "It is necessary to choose at least one reason to learn English!",
            1: "To use in business life",
            2: "To rise in your career",
            3: "To use in business meetings",
            4: "Participating in an interview/job interview",
            5: "Overseas business trips",
            6: "Overseas vacation",
            7: "Going abroad to work",
            8: "Communication with close relatives or friend",
            9: "Students who will use it at school"
        },
        statistics: {
            title: "Statistics",
            weekly_streak: "Weekly Streak",
            total_lesson_duration_title: "Speaking English",
            total_lesson_duration: "#minutes# min",
        },
        recommendations: {
            title: "Friend Suggestions",
            multiple_recommend_member_text: "#name# and +#count# other person is following",
            single_recommend_member_text: "#name# follows"
        },
        follow_list: {
            following: "#count# Followed",
            follower: "#count# Follower",
            profile_click: "Go to Profile",
            following_empty_text: "People you follow will be displayed in this area",
            follower_empty_text: "People who follow you will be displayed in this area"
        },
        profile_operations: {
            report_member: "Report User",
            unfollow: "Stop Following",
            report_member_approve_text: "Are you sure you want to report <b>#name#</b>?",
            report_member_success_text: "You reported <b>#name#</b>.",
            unfollow_member_approve_text: "Are you sure you want to unfollow <b>#name#</b>?",
            unfollow_member_success_text: "You unfollowed <b>#name#</b>."
        },
        leaderboard: {
            ranking: "Ranking",
            weekly_streak: "Weekly Streaks",
            total_lesson_duration: "#duration# min Total Lesson Duration",
        },
        calendar: {
            calendar_title: "Streak Calendar",
            disable_calendar_text: "Your streak calendar will be displayed in this area",
            active_streak: "Ongoing Streak",
            end_streak: "Finished Streak Week",
            start_streak: "Start Streak"
        },
        awards: {
            all_awards_title: "All Rewards",
            award_description: "You can continue the streaks and win surprise prizes by attending at least one class each week.",
            all: "All",
            awards_title: "Rewards",
            reward_title: "#streakCount# Weekly Streak",
            reward_content: "Complete the #streakCount# weekly streak to win the reward.",
        }
    },

    support:
    {
        title: 'Support',
        teacher_time_change_title: "Teacher/Time Change",
        buy: "Buy",
        main_page: {
            send_request: "Send Ticket",
            ticket_history: "Previous Tickets",
            postpone_lesson: "Postpone Lesson",
            teacher_lesson_time_change: "Teacher/Time Change",
            missed_call: "Missed Call",
            frozen_lesson: "Freeze Lesson",
            change_password: "Change Password",
            not_student_content: "To use these features, you need to have an active package. You can purchase a package by visiting the Education Packages page.",
            not_student_buy_button: "View Education Packages",
            not_student_cancel_button: "Cancel",
            delete_account: "Delete My Account",
            holidays: 'Holidays',
            passive_term_message: "This feature is not available for your selected package. You already have an active package. Please change your selected package to use this feature.",
            future_term_message: "Your selected package is a future package. The transaction you selected cannot be performed in future packages. To select an active package, click the Change Selected Package button.",
            pending_term_message: "Your selected package has not yet been confirmed. When your package is confirmed, you can perform the selected transaction.",
            choose_active_term: "Change Selected Package"
        },
        send_request: {
            title: "You can share your questions, problems, and suggestions with us in this area.",
            placeholder: "Write your ticket in this field.",
            send: "Send",
            modal_content: "Your ticket has been successfully directed to the relevant departments.",
            modal_content_error: "The ticket to be sent cannot be less than 10 characters. Please try again.",
            request_limit_reached: "Your request cannot be forwarded because your daily limit for sending requests has been reached."
        },
        previous_request: {
            title: "You can find the previously sent tickets and the given responses below.",
            not_yet_answered: "Not answered yet."
        },
        postpone_lesson: {
            postponed_lesson_title: "Previously Deferred Lessons",
            postponed_lesson_content: "You have postponed your lesson dated #date#.",
            postpone_right: "You have <b>#counter#</b> remaining postpone lesson rights.",
            postpone_information: "To successfully postpone the lesson, the process must take place at least 2 hours before the lesson.",
            choose_postpone_date: "Select the date you want to postpone (cannot attend)",
            second_modal_message: "Postponing the lesson is an irreversible process. Are you sure you want to postpone it?",
            third_modal_message: "Your lesson has been successfully postponed.",
            postpone: "Postpone",
            you_have_frozen_your_courses: "You have frozen your lessons until #date1#. You can take action again starting from #date2#.",
            modal_1_content: "Are you sure you want to postpone the #program# program's lesson dated #date#?",
            buy_postpone_lesson: "Buy Postpone Lesson Right",
            postpone_lesson_count_description: "If you postpone #count# consecutive lessons, teacher and time changes can be made to create the most suitable program for you."
        },
        postpone_lesson_information: {
            information_title: 'Before using the "Postpone Lesson" section, remember',
            information_one: "If you have more than one package, you should select the program you want to process from the section above.",
            information_two: "Postpone Lesson is a permanent process, it cannot be undone.",
            information_three: "From the calendar below, you should select the date of the course that we will not be able to attend and click the postpone button.",
            information_four: "After click the button, you will be asked for confirmation 2 times. After giving these 2 confirmations, the course postponement process will take place.",
            information_five: "Postponed lessons are added to the end of your package schedule.",
            information_six: "You should do this at least 2 hours before your lesson.",
        },
        postpone_lesson_no_right: {
            no_postpone_right: "You do not have lesson deferral rights for your selected program.",
            no_postpone_right_information: "If you have a different program, you can select that program from above or purchase a lesson deferral right."
        },
        teacher_time_change: {
            previously_changed_courses: "Previously Changed Lessons",
            you_changed_your_lesson: "You have changed your #teacher# / #time# lesson.",
            teacher_time_change_question: "Select the teacher and time range you want to change.",
            list_hours: "List Hours",
            modal_content: "No suitable teacher found in the specified time range.\nPlease try again in a different time range.",
            teacher_change_information: "Your lesson time and teacher will be updated with the teacher/time you select from the list below.",
            process: "Action to be Taken ",
            process_continue: "will perform the update process in your program.",
            lesson_time_change_dialog: "Are you sure you want to update your lesson as #lesson# using the teacher/time change process?",
            teacher_change_success: "Your teacher/time change process has been successfully completed.",
            with: "with",
            esd_two_hour_error: "There is one active lesson for today. You can change the time 2 hours after your lesson time.",
            esd_time_frame_error: "No action can be taken for less than 2 hours of lesson time."
        },
        teacher_time_change_information: {
            information_title: "Before changing teacher/time, remember",
            information_one: "If you have multiple programs, you must select the program you want to process from the section above.",
            information_two: "Teacher/time change is a permanent change.",
            information_three: "After the change, all your lessons will be conducted with the new teacher and at the new time.",
            information_four: "Your previous teacher and time are not blocked and can be used by another student.",
            information_five: "You must make this change at least 2 hours before your lesson.",
            select_teacher: "Select Teacher"
        },
        teacher_time_change_frozen: {
            frozen: "You have frozen your lessons for this program."
        },
        teacher_time_change_not_now: {
            not_now: "Teacher/time change is currently not available.",
            not_now_information: "For teacher/time change, you can either postpone your lesson for today or try this process again after your lesson status is finalized."
        },
        teacher_time_change_no_right: {
            no_change_right: "You do not have teacher/time change rights for your selected program.",
            no_change_right_information: "If you have a different program, you can select that program from above or purchase a lesson deferral right."
        },

        /* DÜZGÜN */
        teacher_list: {
            teacher_name: "Teacher Name",
            lesson_date: "Lesson Date",
            lesson_time: "Lesson Time",
            choose: "SELECT",
            date: "Date"
        },
        missed_call: {
            buy_make_up_lesson: "Take Makeup Lesson",
            previously_missedcall_courses: 'Lessons Missed with "Missed Call" Right',
            missedcall_lesson_content: " is the date when you used it in your lesson.",
            not_used: 'You have never used the "Missed Call" right before.',
            no_suitable_instructor: 'No suitable teacher found for the makeup lesson. \n\nPlease try again later.',
            lesson_time_not_suitable_error_message: "Your selected missed-call lesson time is in the same time range as another package.",
            missed_lesson_conflicted: "Selected missed lesson time conflicted another active lesson time!"
        },
        missed_call_used: {
            used_call: 'You have already used your "Missed Call" right.',
            used_content: "The missed lesson you missed on #date1# will be held with #teacher# at #time# on #date2#."
        },
        missed_call_no_right: {
            no_right: 'You do not have the "Missed Call" right for your selected program.',
            no_right_information: 'If you have a different program, you can select that program from above or purchase the "Missed Call" right.'
        },
        missed_call_out_of_time: {
            out_of_time: 'You are outside the active hours to use the "Missed Call" section.',
            out_of_time_information: 'If you are unable to be online during the lesson or experiencing technical difficulties, you can take a makeup lesson from the "Missed Call" page.'
        },
        missed_call_information: {
            information_title: 'Before using the "Missed Call" section, remember',
            information_one: "If you have multiple programs, you must select the program you want to process from the section above.",
            information_two: 'The "Missed Call" section becomes active 20 minutes after the end of your lesson.',
            information_three: 'The "Missed Call" section must be used within 40 minutes after it becomes active.',
            information_four: 'The listed hours after clicking the Take Makeup Lesson button are the most suitable hours in the system.',
            information_five: 'The makeup lesson taken from the "Missed Call" section is a daily transaction and does not replace the teacher/time change.'
        },
        missed_call_set_lesson: {
            title: "Makeup Lesson Teacher List",
            information: "You can select your makeup lesson from the listed teachers below.",
            set_lesson_success: "Your makeup lesson selection has been successfully completed.",
            modal_transaction: "Are you sure you want to set your makeup lesson as #lesson#?",
            process_done: 'Your makeup lesson selection has been successfully completed.',
            already_used: 'You have already used your "Missed Call" right for this lesson.',
        },
        missed_call_no_lesson_result: {
            title: "Your lesson result has not been uploaded yet.",
            information: "When your lesson result is uploaded, it will be displayed on this screen. Try again after a few minutes."
        },
        freeze_lesson: {
            freeze_lesson: "Freeze Lessons",
            modal_content: `The lesson freeze process can be used only once. The action you will take will freeze your lessons for <b>2 months</b>. Do you want to freeze the lessons of your #lesson# program?"`,
            second_modal_content: "The lesson freeze process can be used <b>only once</b>. The action you will take will freeze your lessons for <b>2 months</b>. This action cannot be undone. Are you sure you want to proceed with <b>freezing the lessons</b>?",
            modal_success_message: "Your freeze lesson process has been successfully completed."
        },
        freeze_lesson_success: {
            success_message: "Your lessons have been frozen until #date#."
        },
        freeze_lesson_information: {
            information_title: "Things to remember before using the Freeze Lessons section",
            information_one: "If you have multiple programs, you must select the program you want to process from the section above.",
            information_two: "The freeze lesson right is used in a block of 2 months, not individually.",
            information_three: "The freeze lesson process cannot be canceled after it is done.",
            information_four: "After 2 months, teacher assignments will be made based on availability. Therefore, you may not continue with the same teacher.",
            information_five: "You can easily change your teacher from the Teacher/Time Change section.",
            information_six: "The freeze lesson process becomes effective from the day it is performed and cannot be applied retroactively.",
            information_seven: "You will be asked to confirm the freeze lesson process twice."
        },
        freeze_lesson_no_right: {
            no_freeze_right: "You do not have the Freeze Lesson right for your selected program.",
            no_freeze_right_information: "If you have a different program, you can select that program from above or request support from the support section."
        },
        change_password: {
            title: "You can change your password by filling in the information below.",
            modal_change_password_success: "Your <b>password</b> change process has been successfully completed.",
            modal_current_password_error: "You entered the incorrect current password. Please check the entered information and try again.",
            modal_current_password_same_error: "Your new password cannot be the same as your current password. Please check the entered information and try again."
        },
        delete_account: {
            modal_delete_account_first_content: "Are you sure you want to delete your account?",
            modal_delete_account_second_content: "When your account is deleted, all data related to your account, including past and future lesson records, will be permanently deleted. \nAccount deletion is an <b>irreversible</b> process.",
            modal_delete_account_third_content: "Your account deletion request has been received. Your account deletion process will be completed as soon as possible, and you will be informed.",
            title: "It's Sad to Lose You...",
            description: "If you have any feedback that you would like to share with us, we are ready to listen and do what we can to improve your experience.",
            write_message_button: "Write Message"
        },
        error_list: {
            term_not_found: 'Term not found',
            lesson_date_must_be_equal_or_greater_than_today: 'The lesson date must be equal to or greater than today.',
            lesson_time_is_invalid: 'Invalid lesson time',
            active_or_postponed_lesson_not_found_with_specified_date: 'No active or postponed lessons found on the specified date!',
            postpone_right_is_already_used_for_this_date: 'The deferral right is already used for this date!',
            you_are_not_in_the_postponement_time_range: 'We cannot process your lesson deferral because there is less than 2 hours remaining until your lesson. To successfully postpone a lesson, it must be done at least 2 hours before the lesson. Thank you for your understanding.',
            you_are_not_in_the_freeze_time_range: 'You are not in the time freezing range!',
            you_do_not_have_enough_postpone_right: "You don't have enough deferral rights!",
            freeze_right_already_used_by_specified_date: 'You have already frozen your lesson until the specified date!',
            you_do_not_have_any_freeze_right: "You don't have the lesson freezing right!",
            the_lesson_time_of_term_invalid: 'Invalid term lesson time!',
        },

        assign_term_teacher: {
            message_single: "Due to your absence, your lesson time has been updated to #teacherInformation#.",
            message_multiple: "Due to your absenteeism, the lesson times of the following packages have been updated."
        }
    },

    popup: {
        nps: {
            enter_your_comment: "Enter your comment here",
            success_message: "Your response has been received. Thank you...",
            comment_error_message: 'The comment field cannot exceed 250 characters.',
        },
        download_app: {
            content: "Download our mobile app to receive pre-lesson notifications and for a better lesson experience.",
            button: "Download",
        }
    },

    more: {
        title: "More",
    },

    ai_chatbot: {
        back: "Back",
        start: "Start Recording",
        stop: "Stop Recording",
        write_here: "Write here",
        level_test_ai: "Determine Your Level with AI",
        ai_volume_up: "Unmute AI",
        ai_volume_down: "Mute AI",
        switch_write_mode: "Switch to Writing Mode",
        switch_speak_mode: "Switch to Talk Mode",
        note: "Note",
        speak_end: "To end the chat, simply use 'End'.",
        write_end: "To end the chat, simply type 'End'.",
        give_microphone_permission_text: "You need to allow microphone access to use this feature. You can get help from our video to allow microphone access. You can click the switch to text mode button to continue using AI.",
        determine_your_level: "Determine Your Level with AI",
        errors: {
            correction_not_found_error: "No Correction Found for This Term",
            microphone_not_allowed_error: "You must grant microphone permission to use this page.",
            selected_term_not_found_error: "You do not have a selected term. You can select a term from the Daily Lesson page."
        }
    },

    permissions: {
        title: "Permissions",
        description: "You can manage your communication permissions from this area.",
        whatsapp: "WhatsApp",
        lesson_record_title: "You need these permissions to view your lesson records.",
        approve_lesson_record: "I give permission for the lesson records"
    },

    error404: {
        title: "Error",
    },

    learn_together: {
        learn_together: "Learn Together",
        family_package: "Family Package",
        invite_friends: "Invite Your Friends",
        family_list: "Family List",
        members: "Members",
        edit: "Edit",
        manager: "Manager",
        member: "Member",
        add_new_member: "Add New Member",
        remove_approve_message: "Are you sure you want to remove <b>#NAME#</b>?",
        remove_success_message: "You have successfully removed <b>#NAME#</b> from the family package.",
        add_member_title: "Family English: The Joy of Learning Together in a Family Package!",
        invite_message: "<b>#NAME#</b> invites you to the family package. Join a family account on Azeringilisce. Get ad-free video exercises, AI tools and hundreds of content.",
        invited_title: "<b>#NAME#</b> has invited you to the Azeringilisce Family Package!",
        invited_content: "Let's start learning English.",
        join_family: "Join Family",
        join_success_message: "You have been added to the family package of <b>#NAME#</b>.",
        already_registered_family: "You are already registered in a family!",
        already_manager: "You are already the family manager!",
        family_quota_full: "The quota of the family you want to join has been reached.",
        remaining_count: "#COUNT# members remaining",
        add_member_content: "Start learning English with your family members or friends.",
        add_member_content_title_2: "Learning English is much more affordable with the family package.",
        add_member_content_2: "This package offers you the most convenient way to learn English. Invite one or more people and start learning English at a discounted price.",
        add_member_content_title_3: "Family Package Plan",
        add_member_content_3: "Family members each receive a 10% discount.",
        share_text: "You can use the options below to invite friends or family members.",
        add_member_with_phone: "Add member by phone",
        add_member_info: "Enter the information of the person to be added.",
        add_member: "Add",
        add_member_success_message: "Family invite message sent successfully.",
        add_member_error_message: "An error occurred while sending the family invite message."
    },

    reference: {
        give_reference_title: "A Gift of English for Your Friend, Money Back for You",
        give_reference_description: "Invite your friends to Azər Ingiliscə, give your friends a #referenceDiscountRate#% discount, get #price# AZN back.* Deadline for participation: #lastDate#",
        give_reference_description_2: "*Your friends need to receive a training package before #lastDate#.",
        invite_friend_title: "Invite Your Friends to Azər Ingiliscə",
        invite_friend_description: "Invite, win! 5 friends will have the privilege of receiving free Azər Ingiliscə training for 1 week.*",
        invite_friend_information: "* Gifted lessons are 3 days long. <br/> * Your friends will earn a %#referenceDiscountRate# discount and you will also earn a %#moneyboxDiscountRate# discount for each friend who purchases the package.",
        invite_activated: "#activeReferenceStudentCount#/#targetReferenceStudentCount# Invitation Activated",
        copy_link_and_share: "You can copy and share the link.",
        copy: "Copy",
        copied: "Copied",
        whatsapp_x_message_text: "I discovered a great platform to learn English, it's called Azər Ingiliscə! I benefited from it a lot, I think you will be interested in it too. Here is the link:",
        mail_message_text: "Friends, I have great news for you! I started learning English with the Azər Ingiliscə app and it is very enjoyable. I'm practicing and having fun. Would you like to try it too? If you use my referral code, you can get #referenceDiscountRate#% discount. Come on, let's speak English together! 😊 #AzərIngiliscə #EnglishLearning",
        reference_message: "<b>#NAME#</b> invites you to Konuşarak Öğren with discounted advantages. Join Konuşarak Öğren now. Benefit from ad-free video exercises, AI tools and hundreds of free content.",
        invite_reference_with_phone: "Invite with phone",
        invite_reference_add_info: "Enter the information of the person you want to invite.",
        invite_reference_success_message: "Reference message sent successfully.",
        invite_reference_error_message: "An error occurred while sending the reference message."
    },

    video_practice: {
        title: "Learn with Videos",
        complete: "Complete",
        home: {
            video_duration: "Duration",
            current_video_count: 'Total #currentCount#/#total# video',
        },
        video: {
            video_speed: "Playback Speed",
            turkish_subtitle: "Azerbaijani Subtitle"
        },
        detail_search: {
            title: "Filter Videos",
            list_videos: "List Videos",
            selected_data_title: "Selected #selectedTagTitle# (#count#)"
        },
        word_card: {
            synonyms: "Synonyms",
            phonetics: "Phonetics",
            english_description: "English Description",
            sample_sentence: "Example Sentence",
            sample_sentence_translation: "Example Sentence in Azerbaijani",
            add_member_word_store_success_message: "The word <span class='font-bold'>#word#</span> has been successfully added to your vocabulary list.",
            add_member_word_store_error_message: "The word <span class='font-bold'>#word#</span> is already in your vocabulary list.",
            add_learn_word_list: "Add List",
            word_pronunciation: "Pronounce",
            share: "Share",
            word_origin: "Word Origin",
            word_origin_translation: "Word Origin in Azerbaijani",
            writing_exercise: "Writing Exercise",
            today_english_word: "Word of the Day",
            reset: "Reset",
            use_keyboard: "You can use your keyboard to fill in the blanks below.",
            share_text: "You can use the following options to share.",
            give_microphone_permission: "You need to allow microphone access to use this feature. You can get help from our video to allow microphone access."
        },
        pronunciation: {
            score_breakdown: "Score Breakdown",
            pronunciation_score: "Pronunciation score",
            accuracy_score: "Accuracy score",
            completeness_score: "Completeness Score",
            fluency_score: "Fluency Score",
            prosody_score: "Prosody Score",
            pronunciation_description: "Overall score indicating the quality of pronunciation of the specified speech. This score is the weighted sum of the Accuracy Score, Fluency Score, Completeness Score (if applicable) and Prosody Score (if applicable).",
            accuracy_description: "Pronunciation accuracy of speech. Accuracy indicates how closely the phonemes match the pronunciation of a local speaker. Word and full text accuracy scores are summed from the phoneme-level accuracy score.",
            completeness_description: "Completeness of speech as measured by the ratio of words pronounced to the input reference text.",
            fluency_description: "Fluency of the given speech. Fluency ensures how closely the speech matches a native speaker's use of silent interruptions between words.",
            prosody_description: "The meter of a speech. Meter indicates the nature of a speech, such as stress, intonation, rate and rhythm of speech.",
            click_microphone_for_try_again: "You can click on the microphone sign again to try again.",
            omission: "Omission",
            mispronunciation: "Mispronunciation",
            none: "None"
        },
        word: {
            title: "Learn Word",
            description: "You can discover #count# important English word#multiple# we have selected."
        },
        dialog: {
            title: "Read Dialogue",
            description: "You can explore the dialogs of #count# important English words we have selected."
        },
        grammar: {
            title: "Learn Grammar",
        }
    },

    social_button: {
        whatsapp: "Share via Whatsapp",
        mail: "Share via Email",
        copy_link: "Copy Link",
        download: "Download"
    }
}