import React from 'react'
import {Outlet} from "react-router-dom";
import {Menu} from '../../components';
import {useLocalization} from '../../hooks/useLocalization';
import {LayoutProvider, useLayout} from "../../store/LayoutProvider";

const MarketLayout = () => {
  const strings = useLocalization();

  return (
      <LayoutProvider>
          <Menu title={strings.market.title} />

          <section className="container">

              <Outlet />

              <MarketSidebarContent />

          </section>
      </LayoutProvider>
  )
}

const MarketSidebarContent = () => {
    const { currentPage } = useLayout();

    return (
        <>
            {currentPage}
        </>
    );
}

export default MarketLayout;