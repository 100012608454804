import {useLocalization} from "../../hooks/useLocalization";
import useAuth from "../../hooks/useAuth";
import {useSelector} from "react-redux";
import useAnalytics from "../../hooks/useAnalytics";
import {useEffect, useState} from "react";
import {GetHolidaysService} from "../../services/SpeakingLesson/SpeakingLessonService";
import {getLanguageNameForService} from "../../utils";

const Holidays = () => {

    const strings = useLocalization();
    const { token } = useAuth();
    const language = useSelector(state => state.localizationStore.language);
    const analytics = useAnalytics();
    const [data, setData] = useState(false);

    const getHolidays = async (token, language) => {
        const result = await GetHolidaysService(token, language);
        if (result.statusCode === 200) {
            setData(JSON.parse(result.message));
        } else {
            if (result.message)
                analytics.useAnalytics('Error_Occured', { error: `${result.message}` });
            else {
                analytics.useAnalytics('Error_Occured', {
                    error: `An unexpected error occurred.`,
                });
            }

        }
    }

    useEffect(()=> {
        getHolidays(token, getLanguageNameForService(language));
    },[language])

    return (
        <div className="main-content">
            <div className="left-page">
                <div className="mt-5 gray-section bg-title-bg px-[10px] xs:px-[10px] text-start text-sm">
                    <p className="mt-0 font-bold text-center max-w-lg mx-auto px-5">
                        {strings.speaking_lesson.holidays.information}
                    </p>
                </div>

                <div className="transparent-section mt-5 text-sm text-start">
                    <h5 className="text-secondary">{strings.speaking_lesson.holidays.title}</h5>
                    {data && data.map((item, index) => (
                        <div key={index} className="my-3">
                            {index !== 0 && <hr className="border-transparency-border"/>}
                            <p className="mt-2">{item.date} - {item.description}</p>
                        </div>
                    ))}
                </div>

                <div className="left-margin-bottom"></div>

            </div>
        </div>
    )
}

export default Holidays;