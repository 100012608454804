import {useEffect, useRef, useState} from "react";
import {Button, ModalOneButton, ModalOverlay} from "../../../components"
import {PasswordEye, PasswordEyeSlash} from "../../../assets/svg";
import {useLocalization} from "../../../hooks/useLocalization";
import {checkPassword, clearError, showError} from "../../../services/Auth/AuthValidation"
import useAnalytics from "../../../hooks/useAnalytics";
import {ChangePasswordService} from "../../../services/Support/SupportService";
import useAuth from "../../../hooks/useAuth";
import {ServiceErrorMessages} from "../../../components/Constants";
import {closeModal, openModal} from "../../../utils";

const ChangePassword = () => {

  const strings = useLocalization();
  const analytics = useAnalytics();
  const { member, token } = useAuth();

  const currentPasswordRef = useRef()
  const passwordRef = useRef()
  const passwordRef2 = useRef()
  const currentPasswordErrorRef = useRef()
  const passwordErrorRef = useRef()

  const modalRef = useRef()
  const modalSuccessRef = useRef()
  const modalOverlayRef = useRef()

  const [currentPassword, setCurrentPassword] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')

  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)

  const [errorModalMessage, setErrorModalMessage] = useState('')

  const passwordHandle = (setFunc, e) => {
    setFunc(e)
  }

  const submitHandle = async e => {
    e.preventDefault();

    showError(checkPassword(password), passwordErrorRef);
    showError(checkPassword(password2), passwordErrorRef);

    if(!checkPassword(currentPassword)) {
      showError(false, currentPasswordErrorRef)
    }

    if (password !== password2) {
      showError(false, passwordErrorRef)
    }
    else if (checkPassword(currentPassword)) {
      if (checkPassword(password)) {
        analytics.useAnalytics('Password_Change_Submit');
        clearError(passwordErrorRef)
        clearError(currentPasswordErrorRef)

        const result = await ChangePasswordService(member.MemberId, currentPassword, password, token);

        if (result.statusCode === 200) {
          analytics.useAnalytics('Password_Changed');
          openModal(modalOverlayRef, modalSuccessRef);
          setCurrentPassword(currentPassword => "");
          setPassword(password => "");
          setPassword2(password2 => "");
          currentPasswordRef.current.value = "";
          passwordRef.current.value = "";
          passwordRef2.current.value = "";
        } else {
          if (result.message === ServiceErrorMessages.NEW_PASSWORD_SAME_ERROR) {
            setErrorModalMessage(strings.support.change_password.modal_current_password_same_error);
          } else if (result.message === ServiceErrorMessages.CURRENT_PASSWORD_ERROR) {
            setErrorModalMessage(strings.support.change_password.modal_current_password_error);
          }
          if(passwordRef.current.value !== "" || passwordRef2.current.value !== "") {
            openModal(modalOverlayRef, modalRef);
          } else {
            showError(false, passwordErrorRef)
          }
        }
      }
      else if(checkPassword(password2) && password === password2){
        openModal(modalOverlayRef, modalRef);
      }
    }
  }

  //current password
  useEffect(() => {
    currentPasswordRef.current.type = showCurrentPassword ? 'input' : 'password'
  }, [showCurrentPassword])

  //new password 
  useEffect(() => {
    passwordRef.current.type = showPassword ? 'input' : 'password'
  })

  //new password again
  useEffect(() => {
    passwordRef2.current.type = showPassword2 ? 'input' : 'password'
  })

  //current password border
  useEffect(() => {
    clearError(passwordErrorRef)
    clearError(currentPasswordErrorRef)

    const element = currentPasswordRef.current;

    if (currentPassword === '') {
      element.classList.remove('border-red-600')
      element.classList.remove('border-green-600')
    }
    else {
      if (checkPassword(currentPassword)) {
        element.classList.remove('border-red-600')
        element.classList.add('border-green-600')
      }
      else {
        element.classList.add('border-red-600')
        element.classList.remove('border-green-600')
      }
    }
  }, [currentPassword])

  //password and password 2 border
  useEffect(() => {
    clearError(passwordErrorRef);
    clearError(currentPasswordErrorRef)

    const element = passwordRef.current;
    const element2 = passwordRef2.current;

    if (password === '' && password2 === '') {
      element.classList.remove('border-red-600')
      element.classList.remove('border-green-600')
      element2.classList.remove('border-red-600')
      element2.classList.remove('border-green-600')
    }
    else {
      if ((password === password2) && checkPassword(password) && checkPassword(password2)) {
        element.classList.remove('border-red-600')
        element.classList.add('border-green-600')
        element2.classList.remove('border-red-600')
        element2.classList.add('border-green-600')
        clearError(passwordErrorRef);
        clearError(currentPasswordErrorRef)
      }
      else {
        element.classList.add('border-red-600')
        element.classList.remove('border-green-600')
        element2.classList.add('border-red-600')
        element2.classList.remove('border-green-600')
      }
    }

  }, [password, password2])

  return (
    <>
      <div className="main-content">
        <div className="left-page">
          <div className="mt-5 gray-section bg-title-bg px-[10px] xs:px-[10px] text-center text-sm">
            <p className="font-bold px-5">
              {strings.support.change_password.title}
            </p>
          </div>

          <div className="btn-outer-div mt-10 sm:mt-8">
            <form id="form" className="font-bold" onSubmit={submitHandle}>
              <div className="mb-[6px] relative">
                <label className="block text-start text-base-text-light" htmlFor="password_new">
                  {strings.auth.form.current_password}
                </label>
                <input
                  ref={currentPasswordRef}
                  className="keypress input-area w-full block "
                  id="password_new"
                  type="password"
                  name="password"
                  placeholder={strings.auth.form.enter_current_password}
                  onChange={(e) => passwordHandle(setCurrentPassword, e.target.value)}
                />
                <span className="toggle-password">
                  <img
                    src={showCurrentPassword ? PasswordEye : PasswordEyeSlash}
                    width="20"
                    alt={showCurrentPassword ? "password-eye" : "password-eyeslash"}
                    onClick={() => setShowCurrentPassword(showCurrentPassword => !showCurrentPassword)}
                  />
                </span>
              </div>
              <p
                id="curren_password_error"
                className="text-[11px] text-[#FF0000] text-start hidden"
                ref={currentPasswordErrorRef}
              >
                {strings.auth.validation_messages.invalid_password}
              </p>
              <div className="mb-[6px] relative">
                <label className="block text-start text-base-text-light" htmlFor="password">
                  {strings.auth.form.your_password}
                </label>
                <input
                  ref={passwordRef}
                  className="keypress input-area w-full block"
                  id="password"
                  type="password"
                  name="password"
                  placeholder={strings.auth.form.create_your_password}
                  onChange={(e) => passwordHandle(setPassword, e.target.value)}
                />
                <span className="toggle-password">
                  <img
                    src={showPassword ? PasswordEye : PasswordEyeSlash}
                    width="20"
                    alt={showPassword ? "password-eye" : "password-eye-slash"}
                    onClick={() => setShowPassword(showPassword => !showPassword)}
                  />
                </span>
              </div>
              <div className="mb-[6px] relative">
                <label className="block text-start text-base-text-light" htmlFor="password2">
                  {strings.auth.form.your_password_again}
                </label>
                <input
                  ref={passwordRef2}
                  className="keypress input-area w-full block"
                  id="password2"
                  type="password"
                  name="password"
                  placeholder={strings.auth.form.enter_your_password_again}
                  onChange={(e) => passwordHandle(setPassword2, e.target.value)}
                />
                <span className="toggle-password">
                  <img
                    src={showPassword2 ? PasswordEye : PasswordEyeSlash}
                    width="20"
                    alt={showPassword2 ? "password-eye" : "password-eye-slash"}
                    onClick={() => setShowPassword2(showPassword2 => !showPassword2)}
                  />
                </span>
              </div>
              <p
                id="password_error"
                className="text-[11px] text-[#FF0000] text-start hidden"
                ref={passwordErrorRef}
              >
                {strings.auth.validation_messages.invalid_change_password}
              </p>
              <Button
                type="submit"
                id="submit"
                classnames="button primary-button button-mt"
                text={strings.auth.form.change_button}
              />
            </form>
          </div>

          <div className="left-margin-bottom"></div>
        </div>
      </div>

      {/* Modal */}
      <ModalOverlay ref={modalOverlayRef} />
      <ModalOneButton
        ref={modalRef}
        overlayRef={modalOverlayRef}
        title={strings.auth.information_messages.modal_title}
        message={errorModalMessage}
        buttonText={strings.auth.form.okay_button}
        buttonClick={() => closeModal(modalOverlayRef, modalRef)}
      />

      <ModalOneButton
        ref={modalSuccessRef}
        overlayRef={modalOverlayRef}
        title={strings.auth.information_messages.modal_title}
        message={strings.support.change_password.modal_change_password_success}
        buttonText={strings.auth.form.okay_button}
        buttonClick={() => closeModal(modalOverlayRef, modalSuccessRef)}
      />
    </>
  );
};

export default ChangePassword;
