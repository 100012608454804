import React, {useEffect, useRef, useState} from 'react'
import {Star} from './components';
import {useLocalization} from '../../hooks/useLocalization';
import {Loading, ModalOneButton, ModalOverlay} from '../../components';
import {useLocation, useNavigate} from 'react-router-dom';
import {GetTeacherDetailsService} from '../../services/Teacher/TeacherService';
import useAuth from '../../hooks/useAuth';
import useAnalytics from '../../hooks/useAnalytics';
import {Default_Teacher} from '../../assets/svg';
import {AddRateService, GetTeacherReviewListService} from '../../services/Teacher/TeacherRatingService';
import {useSelector} from 'react-redux';
import {url} from '../../routes/utility';
import {closeModal, getLanguageIdByLanguageKey, openModal} from '../../utils';

const TeacherRating = () => {

    const strings = useLocalization();
    const { token, member } = useAuth();

    const analytics = useAnalytics();
    const navigate = useNavigate();
    const language = useSelector(state => state.localizationStore.language);

    const location = useLocation();
    const teacherId = location?.state?.teacherId;

    const modalRef = useRef();
    const successModalRef = useRef();
    const modalOverlayRef = useRef();

    const [loading, setLoading] = useState(true);
    const [teacherDetail, setTeacherDetail] = useState({})

    const [ratingInput, setRatingInput] = useState([]);
    const [ratingCategories, setRatingCategories] = useState([]);
    const [ratingCategoryIds, setRatingCategoryIds] = useState([]);
    const [comment, setComment] = useState("");

    const [modalErrorMessage, setModalErrorMessage] = useState();

    // Sayfaki eğitmen bilgilerini getirir
    const getTeacherDetail = async (teacherId, token) => {
            
        const result = await GetTeacherDetailsService(teacherId, token);

        let resultMsg = JSON.parse(result.message);
        if (result.statusCode !== 200) {
            if (resultMsg)
                analytics.useAnalytics('Error_Occured', { error: `${resultMsg}` });
            else {
                analytics.useAnalytics('Error_Occured', {
                error: `An unexpected error occurred.`,
                });
            }
        } else {
            setTeacherDetail(resultMsg);
        }

        setLoading(loading => false);
    };

    // Değerlendirme alanlarını servisten getirir
    const getTeacherReviewListService = async (languageId, token) => {
        const result = await GetTeacherReviewListService(languageId, token);

        if(result.statusCode === 200) {
            const resultMsg = JSON.parse(result.message);
            
            let categoryIds = [];
            
            resultMsg?.forEach(categoryId => {
                if (!categoryIds.includes(categoryId.reviewCategoryId)) {
                    categoryIds.push(categoryId.reviewCategoryId);
                }
            });

            setRatingCategoryIds(ratingCategoryIds => categoryIds);
            setRatingCategories(ratingCategories => resultMsg);
        }
    } 
    
    // Dinamik olarak yıldız değelerini alır.
    // Örnek: 5 adet kategori varsa, 5 adet input alır. Servisten gelen veriye göre şekillenir.
    const handleInputChange = (index, value) => {
        const newRatingInput = [...ratingInput];
        newRatingInput[index] = value;
        setRatingInput(newRatingInput);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        let valid = true;
        let errorMessage = "";

        if(ratingCategories.length !== ratingInput.length) {
            valid = false;
            errorMessage += strings.modal.required_field_error + "\n";
        }

        if(comment.length > 300) {
            valid = false;
            errorMessage += strings.teachers.rating.your_comment_error_message;
        }

        if(valid) {
            let model = {
                teacherId: teacherId,
                memberId: member.MemberId,
                comment: comment,
                rate: ratingInput,
                teacherReviewCategoryId: ratingCategoryIds
            }

            const result = await AddRateService(model, token);

            if(result.statusCode === 204) {
                openModal(modalOverlayRef, successModalRef);
                setTimeout(() => {navigate(url("speakinglesson.dailylesson"));}, 5000);
            } else {
                openModal(modalOverlayRef, modalRef);
                setModalErrorMessage(modalErrorMessage => strings.general_information_messages.an_error_occured);
            }
        } else {
            setModalErrorMessage(modalErrorMessage => errorMessage);
            openModal(modalOverlayRef, modalRef);
        }
    };

    useEffect(() => {
        if(teacherId) {
            getTeacherDetail(teacherId, token);
        }
    }, [teacherId, token])

    useEffect(() => {
        getTeacherReviewListService(getLanguageIdByLanguageKey(language), token);
    }, [language, token])

    return (
        <div className='main-content'>
            {loading ? (
                <Loading />
            ) : (
                <div className="bg-white p-8 max-w-lg mx-auto">
                    <div className='flex items-center justify-center'>
                        <h5 className="font-[18px] text-primary font-bold">{strings.teachers.rating.rate_your_teacher}</h5>
                    </div>
                    <div className="flex flex-col items-center justify-center mb-4 mt-4 gap-2">
                        <img src={teacherDetail?.profilePictureUrl ? teacherDetail?.profilePictureUrl : Default_Teacher} alt="Teacher Icon" className="w-[110px] h-[110px] rounded-full" />
                        <p className='font-bold'>{strings.dashboard.student.your_teacher} {teacherDetail?.fullName.replace(" (Sanal Egitmen)", "")}</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col gap-4 mb-4">
                            {ratingCategories.map(({title}, index) => (
                                <Star 
                                    key={index}
                                    text={title}
                                    onChange={(rating) => handleInputChange(index, rating)}
                                />
                            ))}
                        </div>

                        <div className="flex flex-col mb-2">
                            <label htmlFor="comment" className="font-bold">
                                {strings.teachers.rating.your_comment}
                            </label>
                            <textarea
                                id="comment"
                                name="comment"
                                rows="4"
                                className="market-info-input max-w-full placeholder:pt-1 pt-1"
                                value={comment ? comment : ''}
                                onChange={(e) => setComment(e.target.value)}
                            ></textarea>
                        </div>
                        <div className='flex items-center justify-center'>
                            <button
                                className="button xs:w-[220px] primary-button mb-14"
                            >
                                {strings.support.send_request.send}
                            </button>
                        </div>
                    </form>
                </div>
            )}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={modalErrorMessage}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => { closeModal(modalOverlayRef, modalRef); setModalErrorMessage("")}}
            />
            <ModalOneButton
                ref={successModalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.teachers.rating.rating_success_message}
                showCancelButton={false}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => { closeModal(modalOverlayRef, successModalRef); navigate(url("speakinglesson.dailylesson"));}}
            />
        </div>
    )
}

export default TeacherRating