export const wordsAndIdioms = [
    {
        "idioms": [
          ["A little bird told me", "Balaca bir quş mənə dedi"],
          ["A penny for your thoughts", "Nə fikirləşirsən?"],
          ["Abide by a decision", "Qərara əməl etmək"]
        ],
        "word": [
          "have",
          "sahib olmaq",
          "I have an older brother",
          "Mənim böyük qardaşım var (mənim var)."
        ]
      },
      {
        "idioms": [
          ["Add fuel to the fire", "Yangına körükle gitmek"],
          ["Against the clock", "Zamana qarşı"],
          ["Age before beauty", "Ön tərəfdən zəhmət olmasa / Ön tərəfdən yaşlılar"]
        ],
        "word": [
          "some",
          "biraz,bəzi",
          "Ken went to the supermarket to buy some eggs.",
          "Ken yumurta almaq üçün supermarketə getdi."
        ]
      },
      {
        "idioms": [
          ["All bets are off", "Hər şey ləğv edilir"],
          ["All eyes on me", "bütün gözlər üstümdə"],
          ["All heart", "Ürəkdən"]
        ],
        "word": [
          "other",
          "başqa",
          "We cannot see the other side of the moon.",
          "Ayın digər tərəfini görə bilmirik."
        ]
      },
      {
        "idioms": [
          ["All over bar the shouting", "Son nəticə"],
          ["All over the place", "Hər şey hər yerdə"],
          ["All over the shop", "Qarmaqarışıq"]
        ],
        "word": [
          "all",
          "bütün, hamısı",
          "All my friends went to the park.",
          "Bütün dostlarım parka getdi."
        ]
      },
      {
        "idioms": [
          ["All talk and no trousers", "Söz çox, hərəkət yox"],
          ["All your eggs in one basket", "Hamısını riskə atmaq"],
          ["And all that jazz", "Və buna bənzər çoxlu cəfəngiyatlar"]
        ],
        "word": [
          "word",
          "söz",
          "I was remembering the words of my old friend.",
          "Köhnə bir dostumun sözlərini xatırladım."
        ]
      },
      {
        "idioms": [
          ["Angry as a bear", "Ayı kadar kızgın"],
          ["Ants in your pants", "İçi içine sığmayan"],
          ["Apple of your eye", "Birinin gözbebeği olmak"]
        ],
        "word": [
          "each",
          "hər biri",
          "She kissed each of her children.",
          "Övladlarının hər birini öpdü."
        ]
      },
      {
        "idioms": [
          ["Apron strings", "Həddindən artıq öhdəlik"],
          ["Argue the toss", "Nuh deyib peyğəmbər deməmək"],
          ["Arm and a leg", "Baha almaq"]
        ],
        "word": ["kind", "növ", "What kind of person is he?", "O necə insandır?"]
      },
      {
        "idioms": [
          ["Caught with your hand in the cookie jar", "Qarışmaq"],
          ["Chalk and cheese", "Arada dağlar qədər fərq var"],
          ["Champ at the bit", "İçi içinə sığmamaq"]
        ],
        "word": [
          "write",
          "yazmaq",
          "An author writes stories.",
          "Müəllif hekayələr yazır."
        ]
      },
      {
        "idioms": [
          ["Change of heart", "Fikir dəyistirmək"],
          ["Cheap shot", "Beldən aşağı vurmaq"],
          ["Cherry pick", "İncə ələyib sıx toxumaq"]
        ],
        "word": ["so", "çok, bu səbəndən", "She is so beautiful.", "O çox gözəl."]
      },
      {
        "idioms": [
          ["Chew on a bone", "Sıx düşünmə"],
          ["Child`s play", "Uşaq oyuncağı"],
          ["Chip on your shoulder", "Qəzəb içində olmaq"]
        ],
        "word": [
          "more",
          "daha",
          "Please sir, I want more.",
          "Xahiş edirəm cənab, mən daha çox istəyirəm."
        ]
      },
      {
        "idioms": [
          ["Cigarette paper", "Ətlə dırnaq kimi olmaq"],
          ["Circle the wagons", "Qardını almaq"],
          ["Circling the drain", "Qaçınılmaz sona yaxınlaşmaq"]
        ],
        "word": [
          "day",
          "gün",
          "Sunday is the last day of the week.",
          "Bazar həftənın son günü."
        ]
      },
      {
        "idioms": [
          ["Clean bill of health", "Sağlamlıq kağızı alan biri"],
          ["Clean sheet", "Yeni / Təmiz səhifə"],
          ["Clean slate", "Ağ səhifə açmaq"]
        ],
        "word": [
          "sound",
          "səs",
          "The sound of barking dogs bothers me while I am sleeping.",
          "Yatanda itlərin hürən səsi məni narahat edir."
        ]
      },
      {
        "idioms": [
          ["Close but no cigar", "Gözəl cəhd amma alınmadı"],
          ["Close to your heart", "Şəxsə müraciət edir"],
          ["Closed book to me", "Bağlı qutu"]
        ],
        "word": [
          "number",
          "nömrə",
          "Messi is the number one player in the world.",
          "Messi dünyanın bir nömrəli oyunçusudur."
        ]
      },
      {
        "idioms": [
          ["Cold feet", "Gözü yeməmək"],
          ["Cold light of day", "Sakit başla"]
        ],
        "word": [
          "call",
          "zəng etmək",
          "A girl called her boyfriend at night.",
          "Qız gecə saatlarında sevgilisinə zəng edib."
        ]
      },
      {
        "idioms": [
          ["Give a big hand", "Alqışlamaq"],
          ["Give me five", "Vur!/Vur bir beşlik!"],
          ["Give your eye teeth", "Hər şeyi vermək / Hər şeyi ortaya qoymaq"]
        ],
        "word": [
          "people",
          "insanlar",
          "People sleep when they are tired.",
          "İnsanlar yorğun odluqda yatırlar."
        ]
      },
      {
        "idioms": [
          [
            "Glass ceiling",
            "Qadınların və azlıqların iş yerlərində yüksəlmələrinin qarşısını almaq"
          ],
          ["Go awry", "Tərs getmək"],
          ["Go bananas", "Dəli olmaq"]
        ],
        "word": [
          "find",
          "tapmaq",
          "The police needs to find the evidence to put him into a jail.",
          "Polis onu həbs etmək üçün dəlil tapmalıdır."
        ]
      },
      {
        "idioms": [
          ["Go down like a lead balloon", "Xoş qarşılanmamaq"],
          ["Go for the jugular", "Zəif yerə vurmaq"],
          ["Go over like a lead balloon", "Tamamilə uğursuz olmaq"]
        ],
        "word": [
          "work",
          "işləmək",
          "Many employees work at weekends.",
          "Bir çox işçi həftə sonları işləyir."
        ]
      },
      {
        "idioms": [
          ["Go play in traffic", "Rədd ol  gözüm görməsin səni!"],
          ["Go the distance", "Sona qədər getmək"]
        ],
        "word": [
          "place",
          "yer",
          "Keep your credit cards in a safe place.",
          "Kredit kartlarını təhlükəsiz yerdə saxla."
        ]
      },
      {
        "idioms": [
          ["Golden rule", "Qızıl qayda"],
          ["Going overboard", "Şişirtmək"],
          ["Good egg", "Yaxşı adam"]
        ],
        "word": [
          "attendance",
          "Davamiyyət",
          "Students whose attendance falls below 80% will receive a warning letter.",
          "Davamiyyəti 80%-dən aşağı düşən tələbələr xəbərdarlıq məktubu alırlar."
        ]
      },
      {
        "idioms": [
          ["Gravy train", "Küncü dönmək"],
          ["Grease monkey", "Makinist/Çarkçı/Usta/Tamirci"],
          ["Great guns", "Maşinist/Mühəndis/Usda/Mexanik"]
        ],
        "word": [
          "authority",
          "səlahiyyət",
          "We don`t have the authority to kick kids out of school unless they have been especially violent.",
          "Uşaqları zorakılıq nümayiş etdirməyincə, məktəbdən qovmaq səlahiyyətimiz yoxdur."
        ]
      },
      {
        "idioms": [
          ["Grey Cardinal", "Gizli gücünü istifadə etmək"],
          ["Grey matter", "Ağıl/Zəka/Beyin"],
          ["Grey suits", "Pərdə arxasında olanlar"]
        ],
        "word": [
          "equipment",
          "avadanlıq",
          "He is saving up to buy himself some new football equipment.",
          "Yeni futbol avadanlıqları almaq üçün qənaət edir."
        ]
      },
      {
        "idioms": [
          ["Know a hawk from a handsaw", "Yaxşı bilmək"],
          ["Know full well", "Əmin olmaq"],
          ["Know the ropes", "Bilikli insan"]
        ],
        "word": [
          "burst",
          "partlayış",
          "The fireworks burst in the air with brilliant colors.",
          "Parlaq rənglərlə havada atəşfəşanlıq partladı."
        ]
      },
      {
        "idioms": [
          [
            "Know where all the bodies are buried",
            "Bir şeyi bütün təfərrüatları ilə bilmək"
          ],
          [
            "Know which way the wind blows",
            "Küləyin hansı tərəfdən əsəcəyini bilmək"
          ],
          ["Know your place", "Öz yerini bil"]
        ],
        "word": [
          "comparison",
          "müqayisə etmək",
          "Country life is very peaceful in comparison with city life.",
          "Kənd həyatı şəhər həyatı ile müqayisədə daha dincdir."
        ]
      },
      {
        "idioms": [
          ["Labor of love", "Könüllü iş"],
          ["Lame duck", "Topal ördək/Şikəst"],
          ["Landslide victory", "Böyük səs çoxluğu ilə qələbə"]
        ],
        "word": [
          "dependable",
          "etibarlı",
          "He is a good friend of mine, very helpful, and always dependable.",
          "O, mənim yaxşı dostumdur, çox köməkçi və etibarlıdır."
        ]
      },
      {
        "idioms": [
          ["Lap of the gods", "Allah'a qalıb/Heç nə edə bilməmək"],
          ["Last hurrah", "Son şou/Son performans"],
          ["Last straw", "Stəkanı daşıran son damcı"]
        ],
        "word": [
          "patience",
          "səbr",
          "Jean-Jacques Rousseau once observed that patience is bitter, but its fruit is sweet.",
          "Jean-Jacques Rousseau demişdir: 'Səbr acıdır, amma meyvəsi şirindir'."
        ]
      },
      {
        "idioms": [
          ["Laugh to see a pudding crawl", "Her şeye gülen bir kişi"],
          ["Laughing stock", "Komik duruma düşmek"],
          ["Laughter is the best medicine", "Gülmek en iyi ilaçtır"]
        ],
        "word": [
          "poet",
          "şair",
          "I know a poet whose poems are widely read.",
          "Şeirləri çox oxunan bir şair tanıyıram."
        ]
      },
      {
        "idioms": [
          ["Law unto yourself", "Bildiğini eləmək"],
          ["Lay down the law", "Himayə etmək"],
          ["Lean and mean", "əllərini sallayıb və gözləmək"]
        ],
        "word": [
          "impolite",
          "kobud",
          "It would have been impolite to refuse.",
          "İmtina etmək kobudluq olardı."
        ]
      },
      {
        "idioms": [
          ["Learn the ropes", "İşin necə edildiyini öyrənmək"],
          ["Leave well alone", "Heç nəyə qarışmamaq"],
          ["Left to your own devices", "Öz halına buraxmaq"]
        ],
        "word": [
          "raise",
          "yüksəltmək",
          "I just got a raise of over 50 cents an hour.",
          "Saatlıq 50 cent maaşım yüksəldi."
        ]
      },
      {
        "idioms": [
          ["Lend an ear", "Qulaq asmaq"],
          ["Let bygones be bygones", "Köhnə dəftərləri bağlamaq"],
          ["Let sleeping dogs lie", "Mənə toxunmayan ilan min il yaşayar"]
        ],
        "word": [
          "similarity",
          "oxşarlıq",
          "Despite the twins`s physical similarity, their characters are totally different.",
          "Əkizlərin fiziki oxşarlığına baxmayaraq, xarakterləri çox fərqlidir."
        ]
      },
      {
        "idioms": [
          ["Let the cat out of the bag", "Ağzından qaçırmaq"],
          ["Let the chips fall where they may", "İşi olacağına bıraxmaq"],
          [
            "Let the genie out of the bottle",
            "Ox yaydan çıxdı/Oxun yaydan çıxmış olması"
          ]
        ],
        "word": [
          "solution",
          "həll",
          "Love is the key to the solution of the problems of the world.",
          "Dünyadakı problemləri həll etmək üçün sevgi açardır."
        ]
      },
      {
        "idioms": [
          ["Let your hair down", "Rahatlamaq/İçini tökmək"],
          ["Let`s call it a day", "Bugünlük bu qədər yetər"],
          ["Letter of the law", "Qanunun təmin edilməsi"]
        ],
        "word": [
          "adult",
          "yetkin",
          "You cannot get a tattoo until you are an adult.",
          "Yetkin olana qədər döymə etdirə bilməzsiniz."
        ]
      }
]