import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Button, Loading, ModalOneButton, ModalOverlay, ModalTwoButtons} from '../../components';
import useAuth from '../../hooks/useAuth';
import {useLocalization} from '../../hooks/useLocalization';
import {url} from '../../routes/utility';
import {
    AddDemoTerm,
    CheckMemberDemoRight,
    TeachersFreeTimesForMemberDemo
} from '../../services/MemberDemoRight/MemberDemoRightServices';
import {AutoMissedLesson} from '../../services/Support/SupportService';
import {
    addTimeZone,
    closeModal,
    formatNoneZeroDateToZeroDateForDotForOnlyDays,
    getTimeZone,
    openModal
} from '../../utils';
import useAnalytics from '../../hooks/useAnalytics';
import {EventLogs, ServiceErrorMessages} from '../../components/Constants';
import useToken from "../../hooks/useToken";
import {setTermId} from "../../store/TermSlice";
import useDemoAvailability from "../../hooks/useDemoAvailability";

const Demo = () => {

    const strings = useLocalization();
    const timeZone = getTimeZone();
    const analytics = useAnalytics();
    const location = useLocation();
    const source = location.state?.source || "sidebar"
    //Demo ders süresini default olarak 10 dk şeklinde belirliyoruz.
    const lessonDuration = 10;
    const { member } = useAuth();
    const tokenHook = useToken();
    const dispatch = useDispatch();
    const memberId = member?.MemberId;
    const selectedTermId = useSelector(state => state.termStore.termId);
    const navigate = useNavigate();
    const demoAvailability = useDemoAvailability(memberId);

    const modalOverlayRef = useRef();
    const modalRef = useRef();
    const secondModalRef = useRef();
    const thirdModalRef = useRef();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([])
    const [lesson, setLesson] = useState('');
    const [selectedTeacher, setSelectedTeacher] = useState({});
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isMemberDemoRightExist, setIsMemberDemoRightExist] = useState(true);
    const [errorText, setErrorText] = useState(null)

    // 2024-02-26T17:22:14.039 formatında lessonDate'i döndürür.
    const getLessonDate = () => {
        const now = new Date();
        const timeZoneOffsetInMinutes = now.getTimezoneOffset();
        const timeZoneOffsetInMilliseconds = timeZoneOffsetInMinutes * 60 * 1000;
        const lessonDate = new Date(now.getTime() - timeZoneOffsetInMilliseconds).toISOString().slice(0, -1);

        return (lessonDate);
    }

    //Boş eğitmenlerin verisini getiren servis
    const teachersFreeTimesForMemberDemo = async (lessonDate, lessonDuration, timeZone) => {

        const model = {
            lessonDate: lessonDate,
            lessonDuration: lessonDuration,
            timeZone: timeZone
        }

        setLoading(loading => true);

        const result = await TeachersFreeTimesForMemberDemo(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setData(data => resultContent);
        } else {
            openModal(modalOverlayRef, secondModalRef);
        }
        setLoading(loading => false);
    }

    const checkMemberDemoRight = async (memberId) => {
        setLoading(loading => true);

        const result = await CheckMemberDemoRight(memberId);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setIsMemberDemoRightExist(isMemberDemoRightExist => resultContent);
        } else {
            setIsMemberDemoRightExist(isMemberDemoRightExist => false);
        }
        setLoading(loading => false);
    }

    useEffect(() => {
        memberId && checkMemberDemoRight(memberId)
    }, [memberId])

    //eğer demo hakkı olmayan bir kullanıcı bu ekrana gelmeye çalışırsa bir önceki sayfaya yönlendiriliyor.
    useEffect(() => {
        if(demoAvailability)
            !isMemberDemoRightExist && !loading && source !== "dashboard" && navigate(-1);
        else
            navigate(-1);
    },[isMemberDemoRightExist, loading, demoAvailability])

    //Demo dersi almak için eğitmen seçildiğinde kullanılan servis
    const addDemoTerm = async (memberId, teacherId, lessonTime, lessonDate, teacherName) => {
        setButtonLoading(buttonLoading => true);

        let [day, month, year] = lessonDate.split('.');
        const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${lessonTime}`;

        const model = {
            memberId: memberId,
            teacherId: teacherId,
            lessonDate: new Date(formattedDate),
            lessonTime: lessonTime + ".0000000"
        }

        const result = await AddDemoTerm(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            analytics.useAnalytics(
                EventLogs.DEMO_LESSON_CREATED,
                {
                    teacher: teacherName,
                    date: formattedDate.split("T")?.[0] || "",
                    lesson_time: addTimeZone(lessonTime, timeZone)
                }
            );
            await tokenHook?.generateToken(member?.MemberId);
            dispatch(setTermId(resultContent?.termId));
            closeModal(modalOverlayRef, modalRef);
            navigate(url("demo.unitselection"), { state: { "lesson": resultContent }});
        } else {
            closeModal(modalOverlayRef, modalRef);
            openModal(modalOverlayRef, secondModalRef);
        }
        setButtonLoading(buttonLoading => false);
    }

    //Demo dersi almak için eğitmen seçildiğinde kullanılan servis
    const autoMissedLesson = async (memberId, teacherId, lessonTime, lessonDate) => {
        setButtonLoading(buttonLoading => true);

        let [day, month, year] = lessonDate.split('.');
        let formattedLessonDate = new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${lessonTime}`);

        const model = {
            termId: selectedTermId,
            lessonDate: new Date(formattedLessonDate),
            missedDate: new Date(formattedLessonDate),
            lessonTime: lessonTime + ".0000000",
            teacherId: teacherId,
            createUserId: memberId
        }

        const result = await AutoMissedLesson(model);

        if (result.status === 200) {
            closeModal(modalOverlayRef, modalRef);
            openModal(modalOverlayRef, thirdModalRef);
            analytics.useAnalytics(EventLogs.DEMO_AUTO_MISSED_LESSON);
        } else {
            if (result.content === ServiceErrorMessages.ESD_TWO_HOURS_ERROR)
                setErrorText(strings.support.teacher_time_change.esd_two_hour_error);
            else if (result.content === ServiceErrorMessages.TERM_NOT_FOUND)
                setErrorText(strings.demo.term_not_found);
            else if (result.content === ServiceErrorMessages.MISSED_CALL_NOT_FOUND)
                setErrorText(strings.demo.missed_call_not_found);
            else
                setErrorText(strings.general_information_messages.an_error_occured);
            closeModal(modalOverlayRef, modalRef);
            openModal(modalOverlayRef, secondModalRef);
        }
        setButtonLoading(buttonLoading => false);
    }

    useEffect(() => {
        teachersFreeTimesForMemberDemo(getLessonDate(), lessonDuration, timeZone)
    }, [])

    return (
        <>
            <div className="main-content">
                {loading && (
                    <Loading />
                )}
                {!loading && (
                    <div className="transparent-section mt-5">
                        <h5 className="text-secondary">{strings.demo.page_title}</h5>
                        <p className="mt-3 text-center">{strings.demo.description}</p>
                        <div className="mt-5">
                            <div className="font-bold flex justify-between">
                                <p className="w-1/4 text-start">{strings.support.teacher_list.teacher_name}</p>
                                <p className="w-1/4 text-start">{strings.support.teacher_list.lesson_date}</p>
                                <p className="w-1/4 text-start">{strings.support.teacher_list.lesson_time}</p>
                                <p className="w-1/4"> </p>
                            </div>
                            {data.map((teacher, index) => (
                                <div key={index}>
                                    <hr className="my-2" />
                                    <div className="flex justify-between">
                                        <p className="w-1/4 text-start">{teacher.teacherName}</p>
                                        <p className="w-1/4 text-start">{formatNoneZeroDateToZeroDateForDotForOnlyDays(teacher.lessonDate)}</p>
                                        <p className="w-1/4 text-start">{addTimeZone(teacher?.lessonTime?.slice(0, 5), timeZone)}</p>
                                        <p className="w-1/4">
                                            <Button
                                                classnames="button primary-button-outline max-w-max px-2 h-[25px] text-sm mt-0"
                                                action={() => {
                                                    // analytics.useAnalytics(EventLogs.CHOOSE_MISSED_CALL);
                                                    setLesson(lesson => `${teacher.lessonDate}-${addTimeZone(teacher?.lessonTime?.slice(0, 5), timeZone)}`);
                                                    setSelectedTeacher(selectedTeacher => teacher);
                                                    openModal(modalOverlayRef, modalRef);
                                                }}
                                                text={strings.support.teacher_list.choose}
                                            />
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="left-margin-bottom"></div>
            </div>
            {/* Modal */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.transaction_information}
                message={strings.demo.approve_message.replace("#date#", lesson.split("-")[0]).replace("#hour#", lesson.split("-")[1])}
                buttonText1={buttonLoading
                    ? strings.general_information_messages.processing
                    : strings.modal.approve
                }
                buttonText2={strings.modal.dont_approve}
                buttonClick1={() => {
                    source === "dashboard" && autoMissedLesson(memberId, selectedTeacher?.teacherId, selectedTeacher?.lessonTime, selectedTeacher?.lessonDate);
                    source !== "dashboard" && addDemoTerm(memberId, selectedTeacher?.teacherId, selectedTeacher?.lessonTime, selectedTeacher?.lessonDate, selectedTeacher?.teacherName);
                }}
                buttonClick2={() => {
                    closeModal(modalOverlayRef, modalRef);
                    setSelectedTeacher(selectedTeacher => { });
                }}
            />
            <ModalOneButton
                ref={secondModalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={errorText}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => {
                    closeModal(modalOverlayRef, secondModalRef);
                }}
            />
            <ModalOneButton
                ref={thirdModalRef}
                overlayRef={modalOverlayRef}
                showCancelButton={false}
                title={strings.auth.information_messages.modal_title}
                message={strings.demo.success_message.replace("#date#", lesson.split("-")[0]).replace("#hour#", lesson.split("-")[1])}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => {
                    closeModal(modalOverlayRef, thirdModalRef);
                    navigate(url("dashboard"));
                }}
            />
        </>
    )
}

export default Demo