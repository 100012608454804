import {useLocation, useRoutes} from "react-router-dom";
import useGtmHook from "./hooks/useGtmHook";
import routes from "./routes/routes";
import {useEffect} from "react";
import useMember from "./hooks/useMember";

function App() {
  //for gtm
  const location = useLocation();
  const gtm = useGtmHook();
  useMember();

  useEffect(() => {
    gtm.dataLayer();
  }, [location]);

  return useRoutes(routes);
}

export default App;