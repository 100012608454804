import classNames from "classnames";
import {Accordion} from "../../../../../../components";
import {useLocalization} from "../../../../../../hooks/useLocalization";
import {Default_Teacher} from "../../../../../../assets/svg";
import {addTimeZone, getTimeZone} from "../../../../../../utils";
import React, {useEffect, useState} from "react";
import {CheckPageVisibilityService} from "../../../../../../services/Teacher/TeacherRatingService";
import useAuth from "../../../../../../hooks/useAuth";
import {useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {url} from "../../../../../../routes/utility";
import useAccordionActivity from "../../../../../../hooks/useAccordionActivity";
import {LessonResultAccordionType} from "../../../../../../components/Constants";

const LessonInfoAttended = ({ teacherInfo, lessonCallLogs, lessonResultId, lessonResultAccordionActivities }) => {

    const strings = useLocalization();
    const { member, token } = useAuth();
    const { activities, addAccordionActivity } = useAccordionActivity(lessonResultId, lessonResultAccordionActivities);
    const navigate = useNavigate();
    const timeZone = getTimeZone();
    const selectedTermId = useSelector(state => state.termStore.termId) || false;

    const [ratingButtonVisibility, setRatingButtonVisibility] = useState(false);

    useEffect(() => {
        //Eğitmeni değerlendir butonunun görünümünü servisten gelen değere göre kontrol ediyoruz

        if(teacherInfo?.id && selectedTermId) {
            const model = {
                memberId: member?.MemberId,
                termId: selectedTermId,
                teacherId: teacherInfo?.id
            };

            CheckPageVisibilityService(model, token)
                .then(result => {
                    if (result.statusCode === 200) {
                        const resultMsg = JSON.parse(result.message);
                        setRatingButtonVisibility(ratingButtonVisibility => resultMsg);
                    }
                })
                .catch()
        }
    }, []);

    return (
        <Accordion
            title={strings.speaking_lesson.daily_lesson.lesson_information}
            disableOutsideClick={true}
            isScrollable={false}
            isDownArrowIcon={false}
            openCloseCheckboxStatus={
                activities
                    ?.find(l => LessonResultAccordionType.LESSON_INFO === l.lessonResultAccordionType)?.isOpened
                || false
            }
            action={() => addAccordionActivity(LessonResultAccordionType.LESSON_INFO)}
        >
            <div className="pb-5 pt-3">
                <p className="text-center font-bold mb-3">
                    {strings.speaking_lesson.daily_lesson.your_teacher} {teacherInfo?.fullName}
                </p>
                <img
                    className={classNames("rounded-full w-[100px] mx-auto", {
                        "cursor-pointer": !(teacherInfo?.id === 1320 || teacherInfo?.id === 1500 || teacherInfo?.id === 1501)
                    })}
                    onClick={() => !(teacherInfo?.id === 1320 || teacherInfo?.id === 1500 || teacherInfo?.id === 1501) && navigate(url("teachers.detail", { teacherId: teacherInfo?.id }))}
                    src={teacherInfo?.profilePicture ?? Default_Teacher}
                    alt=""
                />

                <div className="flex flex-col items-center gap-4 mt-2">
                    {lessonCallLogs?.length > 0 && (
                        <div className="w-full lg:w-1/2 max-lg:pb-4">
                            <div className="font-bold max-w-[95%] relative pb-2 text-secondary text-start">
                                {strings.speaking_lesson.no_answer.call_log_title}
                                <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary rounded"></div>
                            </div>
                            <div className='max-h-[300px] overflow-auto mt-1'>
                                {lessonCallLogs.map((item, index) => (
                                    <p key={index} className="mt-1 text-start">{addTimeZone(item, timeZone)}</p>
                                ))}
                            </div>
                        </div>
                    )}

                    {ratingButtonVisibility &&
                        <div className="w-full lg:w-1/2 lg:pr-4">
                            <div className="mx-auto text-start text-sm flex flex-col lg:flex-row">
                                <div className="w-full">
                                    <div className="font-bold relative pb-2 text-secondary">
                                        {strings.teachers.rating.rate_your_teacher}
                                        <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary rounded"></div>
                                    </div>
                                    <div className="flex flex-col gap-4 items-center mt-4 justify-center">
                                        <p>{strings.teachers.rating.rate_description.replace("#teacherName#", teacherInfo?.fullName)}</p>
                                        <Link
                                            className="button xs:w-[200px] primary-button mb-2"
                                            to={url("teachers.rating")}
                                            state={{ teacherId: teacherInfo?.id }}
                                        >
                                            {strings.teachers.rating.rate}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Accordion>
    )
}

export default LessonInfoAttended;
