import classNames from 'classnames';
import { getCookie } from 'ko-basic-cookie';
import React, { useEffect, useRef, useState } from 'react';
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../../node_modules/intl-tel-input/build/css/intlTelInput.css';
import '../../assets/css/login.css';
import { ModalOneButton, ModalOverlay } from '../../components';
import {cookieName, EventLogs, ServiceErrorMessages} from '../../components/Constants';
import useAnalytics from '../../hooks/useAnalytics';
import { useLocalization } from '../../hooks/useLocalization';
import { url } from '../../routes/utility';
import { SendVerificationCodeServiceV2 } from '../../services/Auth/AuthService';
import { clearError, closeModal, openModal, showError, validPhoneNumber } from '../../services/Auth/AuthValidation';

const ForgotPassword = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const token = getCookie(cookieName) || false;

    const phoneErrorRef = useRef();
    const phoneInputRef = useRef();
    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const [value, setValue] = useState({ iso2: 'az', dialCode: '994', phone: '' });
    const [phoneNumber, setPhoneNumber] = useState('+');
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false)

    //intl tel input values
    let inputProps = { placeholder: value.iso2 === 'az' ? '5X XXX XX XX' : strings.auth.form.enter_your_phone_number };
    const intlTelOpts = { preferredCountries: ['az', 'tr', 'ru'] };

    const phoneNumberHandle = val => {
        if (val.phone.startsWith('0')) {
            val.phone = val.phone.slice(1);
        }
        if (val.dialCode !== value.dialCode) {
            setValue({ iso2: val.iso2, dialCode: val.dialCode, phone: '' });
        } else {
            setValue(val);
        }
    };

    const submitHandle = async e => {
        e.preventDefault();
        const valid = validPhoneNumber(phoneNumber);

        //handle operations
        if (valid) {

            setLoading(loading => true)

            const result = await SendVerificationCodeServiceV2(phoneNumber);
            
            //if there is no error do operations
            if (result.status === 200) {

                //get member Id
                const memberId = result.content.slice(1,-1);
                localStorage.setItem('memberId', JSON.stringify(memberId))

                //send info to analytics
                analytics.useAnalytics(
                    EventLogs.FORGET_PASSWORD,
                    undefined,
                    true,
                    false
                );
                navigate(url("auth.verify") + window.location.search, { state: { "from": pathname } });


                //if there is an error send error informations to analytics and open a modal
            } else {
                if (result.content) {
                    analytics.useAnalytics('Error_Occured', {
                        error: `${result.content}`,
                    });
                }
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }

                // Error Handling
                if(result.content === ServiceErrorMessages.PASSWORD_TIMEOUT_ERROR) {
                    setErrorMessage(strings.auth.information_messages.code_not_expired);
                } else if (result.content === ServiceErrorMessages.PHONE_NUMBER_NOT_FOUND) {
                    setErrorMessage(strings.auth.validation_messages.invalid_username_or_password);
                } else {
                    setErrorMessage(strings.onboarding.information_messages.an_error_occured);
                }
                
                openModal(modalOverlayRef, modalRef);
            }
            setLoading(loading => false)
        } else {
            showError(valid, phoneErrorRef);
        }

    }

    //phone number use effect
    useEffect(() => {

        clearError(phoneErrorRef);
        //set phone number
        let phoneNum = '+' + value.dialCode + value.phone;
        setPhoneNumber(phoneNumber => phoneNum);

        //for borders
        const element = phoneInputRef.current.children[1];

        if (value.phone === '') {
            element.classList.remove('border-red-600')
            element.classList.remove('border-green-600')
        } else {
            if (validPhoneNumber(phoneNum)) {
                element.classList.remove('border-red-600')
                element.classList.add('border-green-600')
            }
            else {
                element.classList.add('border-red-600')
                element.classList.remove('border-green-600')
            }
        }

    }, [value])

    //login control & analytics
    useEffect(() => {

        if (token) {
            navigate(url("home"));
        }

    }, []);

    return (
        <>
            <form id="form" className="font-bold" onSubmit={submitHandle}>
                <div className="mb-[6px]" ref={phoneInputRef}>
                    <label className="block text-start text-base-text-light" htmlFor="phone">
                        {strings.auth.form.phone_number}
                    </label>
                    <ReactIntlTelInput className="keypress input-area w-full"
                        inputProps={inputProps}
                        intlTelOpts={intlTelOpts}
                        value={value}
                        onChange={phoneNumberHandle}

                    />
                </div>
                <p id="phone_error" className="mb-2 text-[11px] text-[#FF0000] text-start ml-2 hidden" ref={phoneErrorRef}>{strings.auth.validation_messages.invalid_phone}</p>

                <button type="submit" disabled={loading} className={classNames("button button-mt primary-button", {
                    "opacity-25 disabled:pointer-events-none": loading
                })}>
                    {loading ?
                        strings.general_information_messages.processing
                        : strings.auth.form.send_button}
                </button>

                <p className="text-[12px] text-center mt-4">{strings.auth.information_messages.forgot_password_sentence_1}
                    {strings.auth.information_messages.forgot_password_sentence_2}
                </p>
                <Link to={{
                    pathname: url('auth.login'),
                    search: window.location.search
                }}
                    className='button text-button'>{strings.auth.information_messages.forgot_password_back}
                </Link>
            </form>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={errorMessage}
                overlayRef={modalOverlayRef}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => {
                    closeModal(modalOverlayRef, modalRef); 
                    // navigate(url("auth.verify"), { state: { "from": pathname } });
                }}
            />
        </>
    )
}

export default ForgotPassword