import {useContext, useEffect} from "react";
import {useLocation} from "react-router-dom"
import {FirebaseContext} from "../store/FirebaseProvider";
import {getAnalytics, logEvent} from "firebase/analytics";
import {useDispatch, useSelector} from "react-redux";
import {setAuth} from "../store/AuthSlice";
import useAnalytics from "./useAnalytics";
import {cookieName, ScreenViewLogs, TermStatusTypes} from "../components/Constants";
import {getCookie} from "ko-basic-cookie";
import {LogService} from "../services/Log/LogService";
import {addTimeZone, decodeToken, getLevelCollectionById, getTimeZone} from "../utils";

const useGtmHook = () => {
  
    const location = useLocation();
  
    const token = getCookie(cookieName);
    const member = token ? decodeToken(token) : false;
    const isLoggedIn = useSelector((state) => state.authStore.isLoggedIn);
    const termId = useSelector(state => state.termStore.termId);
    const timeZone = getTimeZone();

    const dispatch = useDispatch();
    const sendAnalytics = useAnalytics();
    
    //set analytics
    const firebase = useContext(FirebaseContext).firebase;
    const analytics = getAnalytics(firebase);

    //send data to analytics
    const dataLayer = () => {
      logEvent(analytics, 'screen_view', { screen_name: location.pathname });
      window.customDataLayer.push({ event: 'screen_view', screen_name: location.pathname });
    }

    //Backend'e screen_view logları gönderiliyor.
    useEffect(() => {
      const logDescription = ScreenViewLogs[location.pathname];
      if(logDescription !== undefined) {
        
        LogService(logDescription, member.MemberId, termId)
            .then()
            .catch();

      } else if(location.pathname.includes('/mobile_market/')) {

        const path = location.pathname.split('/')[1];
        const logDescriptionMobile = ScreenViewLogs[path];

        LogService(logDescriptionMobile, member.MemberId, termId)
            .then()
            .catch();
      }
    }, [location])

    useEffect(() => {
      if(!isLoggedIn && member){
        const memberId = member?.MemberId;
        const brand = member?.BrandId;
        const job = member?.Profession;
        const userType = member?.Status;
        const contactEmail = member?.MemberEmail ? member?.MemberEmail : 'not set';
        const phoneNumber = member?.PhoneNumber;
        const consultantEmail = member?.SalesPersonMailAddress;
        const consultantName = member?.SalesPersonName;
        const consultantTeamLeader = member?.TeamLeadName?.toString();
        const consultantTeamEmail = member?.TeamLeadMailAddress?.toString();
        const englishLevel = member?.EnglishLevel;
        const reasonToLearnEnglish = member?.ReasonToLearnEnglish;
        const purchaseCount = member?.TermsCount;
        const termsToken = localStorage.getItem("terms") || false;
        const terms = termsToken ? decodeToken(termsToken) : false;
    
        let packages = "";
        let lessonTimes = "";
        let lessonDurations = "";
        let totalLessonCounts = "";
        let remainingLessonCounts = "";

        terms && JSON.parse(terms).map((item,index) => {
          if(item.Status === TermStatusTypes.Active) {
            if (index > 0) {
              packages += " - ";
              lessonTimes += " - ";
              lessonDurations += " - ";
              totalLessonCounts += " - ";
              remainingLessonCounts += " - ";
            }

            packages += item?.ProductName;
            lessonTimes += addTimeZone(item?.LessonTime, timeZone);
            lessonDurations += item?.LessonDuration;
            totalLessonCounts += item?.TotalLessonCount;
            remainingLessonCounts += item?.RemainingLessonCount;
          }
        })
    
        let analyticsObject = {
          user_memberid: memberId,
          consultant_name: member?.SalesPersonName ? consultantName : "",
          consultant_email: member?.SalesPersonMailAddress ? consultantEmail : "",
          contact_email: contactEmail,
          contact_phone: member?.PhoneNumber ? phoneNumber : "",
          consultant_team_leader: member?.TeamLeadName ? consultantTeamLeader : "",
          consultant_team_email: member?.TeamLeadMailAddress ? consultantTeamEmail : "",
          job: member?.Profession ? job : "",
          purchase_count: member?.TermsCount ? purchaseCount : "",
          package: packages,
          brand: member?.BrandId ? brand : "",
          language: "az",
          platform: "web",
          english_level: member?.EnglishLevel
              ? englishLevel
              : member?.MemberLevelCollectionId.length > 0
                  ? getLevelCollectionById(+member?.MemberLevelCollectionId)
                  : "",
          reasonToLearnEnglish: member?.ReasonToLearnEnglish ? reasonToLearnEnglish : "",
          lesson_time: lessonTimes,
          lesson_duration: lessonDurations,
          total_lesson_count: totalLessonCounts,
          remaining_lesson_count: remainingLessonCounts,
          user_type:
            parseInt(userType) === 2
              ? 'Student'
            : parseInt(userType) === 3
              ? 'InactiveStudent'
            : 'Candidate',
        };

        //Kullanıcı 'candidate' ise onboarding ekranlarında veri gönderilmesi önlenmesi için eklenmiştir.
        if(userType === '1'){
          if(!location.pathname.includes("onboarding") && location.pathname !== "/success"){
            //Firebase Analytics
            sendAnalytics.useCustomAnalytics('user_properties' ,analyticsObject, true);
            dispatch(setAuth(true));

            //Google Tag Manager
            window.customDataLayer.push({
              user_memberid: memberId,
              consultant_name: member?.SalesPersonName ? consultantName : "",
              consultant_email: member?.SalesPersonMailAddress ? consultantEmail : "",
              contact_email: contactEmail,
              contact_phone: member?.PhoneNumber ? phoneNumber : "",
              consultant_team_leader: member?.TeamLeadName ? consultantTeamLeader : "",
              consultant_team_email: member?.TeamLeadMailAddress ? consultantTeamEmail : "",
              job: member?.Profession ? job : "",
              purchase_count: member?.TermsCount ? purchaseCount : "",
              package: packages,
              brand: member?.BrandId ? brand : "",
              language: "az",
              platform: "web",
              english_level: member?.EnglishLevel ? englishLevel : "",
              reasonToLearnEnglish: member?.ReasonToLearnEnglish ? reasonToLearnEnglish : "",
              lesson_time: lessonTimes,
              lesson_duration: lessonDurations,
              total_lesson_count: totalLessonCounts,
              remaining_lesson_count: remainingLessonCounts,
              user_type:
                parseInt(userType) === 2
                  ? 'Student'
                : parseInt(userType) === 3
                  ? 'InactiveStudent'
                : 'Candidate',
            });
          }
        } else {
          //Firebase Analytics
          sendAnalytics.useCustomAnalytics('user_properties', analyticsObject, true);
          dispatch(setAuth(true));
          
          //Google Tag Manager
          window.customDataLayer.push({
            user_memberid: memberId,
            consultant_name: member?.SalesPersonName ? consultantName : "",
            consultant_email: member?.SalesPersonMailAddress ? consultantEmail : "",
            contact_email: contactEmail,
            contact_phone: member?.PhoneNumber ? phoneNumber : "",
            consultant_team_leader: member?.TeamLeadName ? consultantTeamLeader : "",
            consultant_team_email: member?.TeamLeadMailAddress ? consultantTeamEmail : "",
            job: member?.Profession ? job : "",
            purchase_count: member?.TermsCount ? purchaseCount : "",
            package: packages,
            brand: member?.BrandId ? brand : "",
            language: "az",
            platform: "web",
            english_level: member?.EnglishLevel ? englishLevel : "",
            reasonToLearnEnglish: member?.ReasonToLearnEnglish ? reasonToLearnEnglish : "",
            lesson_time: lessonTimes,
            lesson_duration: lessonDurations,
            total_lesson_count: totalLessonCounts,
            remaining_lesson_count: remainingLessonCounts,
            user_type:
              parseInt(userType) === 2
                ? 'Student'
              : parseInt(userType) === 3
                ? 'InactiveStudent'
              : 'Candidate',
          });
        }
      }
    }, [isLoggedIn, member]);

    return { dataLayer };
}

export default useGtmHook