import React from 'react'
import { useLocalization } from "../../hooks/useLocalization"
import { Menu, SideBar} from "../../components"
import { Outlet } from 'react-router-dom'

const MoreLayout = () => {
  const strings = useLocalization()
  return (
    <>
        <Menu title={ strings.more.title }/>

        <section className='container'>

            <Outlet />

            <SideBar />

        </section>
    </>
  )
}

export default MoreLayout