import {createSlice} from "@reduxjs/toolkit";
import {cookieName, termStorageName} from "../components/Constants";
import {getCookie, removeCookie, setCookie} from 'ko-basic-cookie';

export const MemberSlice = createSlice({
  name: 'member',
  initialState: { token: getCookie(cookieName), terms: localStorage.getItem(termStorageName) || '' },
  reducers: {
    memberLogin: (state, action) => {
      state.token = action.payload.token;
      state.terms = action.payload.terms;
      setCookie(cookieName, state.token, 30);
      state.terms !== "" && localStorage.setItem(termStorageName, JSON.stringify(state.terms));

    },
    memberLogout: (state, action) => {
      state.token = '';
      removeCookie(cookieName);
      const language = localStorage.getItem('language');

      localStorage.clear();

      /*
        Kullancı çıkış yaptıktan sonra seçili dili set ediliyor.
        Bu sayede kullanıcı giriş yaptığında da aynı dilin seçili gelmesi sağlanıyor.
      */
      language && localStorage.setItem('language', language);
    },
  },
})

export const { memberLogin, memberLogout } = MemberSlice.actions

export default MemberSlice.reducer