import {addTimeZone, closeModal, getTimeZone, openModal} from "../../../utils";
import {Button, Loading, ModalOneButton, ModalOverlay, ModalTwoButtons} from "../../../components";
import {useLocalization} from "../../../hooks/useLocalization";
import React, {useEffect, useRef, useState} from "react";
import {GetTeacherFreeTimeByTeacherId, UpdateTeacherTime} from "../../../services/Teacher/TeacherService";
import {useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import {url} from "../../../routes/utility";
import {ServiceErrorMessages} from "../../../components/Constants";
import useAnalytics from "../../../hooks/useAnalytics";
import useToken from "../../../hooks/useToken";
import {useNavigate} from "react-router-dom";
import {GetTeacherLessonTimeChangeListService} from "../../../services/Support/SupportService";
import {ChangeTeacherTimeNoRight, ChangeTeacherTimeNotAvailable, Frozen} from "../../Support/components";

const TeacherTimeChange = (props) => {

    const {teacherId} = props;
    const strings = useLocalization();
    const {member, token} = useAuth();
    const analytics = useAnalytics();
    const tokenHook = useToken();
    const navigate = useNavigate();

    const timeZone = getTimeZone(member.Timezone);
    const terms = JSON.parse(member.Terms);
    const selectedTermId = useSelector(state => state.termStore.termId);
    const selectedTerm = terms.find(term => term.TermId === selectedTermId);
    const termInfo = `${selectedTerm.ProductName} / ${addTimeZone(selectedTerm.LessonTime, timeZone)} / ${selectedTerm.LessonDuration} ${strings.general_information_messages.minute}`

    const modalRef = useRef();
    const modalOverlayRef = useRef();
    const secondModalRef = useRef();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [selectedLessonTime, setSelectedLessonTime] = useState("");
    const [modalText, setModalText] = useState('');
    const [error, setError] = useState(false);

    const [lessonFrozen, setLessonFrozen] = useState(false);
    const [isAvailableToChange, setIsAvailableToChange] = useState(true);
    const [rightCount, setRightCount] = useState(0);
    const [changeHistory, setChangeHistory] = useState([]);

    const getTeacherFreeTimeByRange = async (teacherId, termId) => {

        let model = {
            teacherId: teacherId,
            termId: termId,
            beginTime: "03:00:00", //default(UTC)
            endTime: "21:00:00" //default(UTC)
        };

        const result = await GetTeacherFreeTimeByTeacherId(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setData(data => resultContent.teacherFreeLessonTimeItems);
        }

        setListLoading(loading => false);
    }

    const updateTeacherTime = async (termId, memberId, lessonTime) => {

        let model = {
            teacherId: teacherId,
            lessonTime: lessonTime,
            termId: termId,
            memberId: memberId,
            createUserId: memberId
        };

        const result = await UpdateTeacherTime(model);

        if (result.status !== 200) {
            if (result.content) {
                analytics.useAnalytics('Error_Occured', { error: `${result.content}` });

                if (result.content === ServiceErrorMessages.ESD_TWO_HOURS_ERROR)
                    setModalText(strings.support.teacher_time_change.esd_two_hour_error);
                else if (result.content === ServiceErrorMessages.ESD_TIME_FRAME_ERROR)
                    setModalText(strings.support.teacher_time_change.esd_time_frame_error);
                else
                    setModalText(strings.general_information_messages.an_error_occured);
            }
            else {
                analytics.useAnalytics('Error_Occured', {
                    error: `An unexpected error occurred.`,
                });

                setModalText(strings.general_information_messages.an_error_occured);
            }
            setError(error => true);
        } else {
            tokenHook.generateToken(member.MemberId);
            setError(error => false);
            analytics.useAnalytics('Egitmen_Saat_Degisikligi_Approved');

            setModalText(strings.support.teacher_time_change.teacher_change_success);
        }
    }

    //get informations for selected term
    const getTeacherTimeChangeStatus = async (termId) => {
        const result = await GetTeacherLessonTimeChangeListService(termId, token);

        let resultMsg = JSON.parse(result.message);
        setChangeHistory(changeHistory => resultMsg?.teacherLessonTimeChangeHistory);

        if (resultMsg?.activeFreezeHistory !== "null") {
            setLessonFrozen(lessonFrozen => resultMsg.activeFreezeHistory);
        } else {
            setLessonFrozen(lessonFrozen => false);
        }

        if (result.statusCode !== 200) {

            if (resultMsg)
                analytics.useAnalytics('Error_Occured', { error: `${resultMsg}` });
            else {
                analytics.useAnalytics('Error_Occured', {
                    error: `An unexpected error occurred.`,
                });
            }

        } else {
            setIsAvailableToChange(resultMsg?.isAvailableToChange);
            setRightCount(resultMsg.teacherLessonTimeChangeRightCount);
        }

        setLoading(loading => false);
    };

    useEffect(() => {
        if (teacherId && selectedTermId) {
            setListLoading(loading => true);
            getTeacherFreeTimeByRange(teacherId, selectedTermId);
        }
    }, [selectedTermId, teacherId])

    useEffect(() => {
        if(selectedTermId) {
            setLoading(loading => true);
            getTeacherTimeChangeStatus(selectedTermId);
        }
    }, [selectedTermId])

    return (
        <>
            <div className="transparent-section mt-5 text-sm text-center">
                <h5 className="text-secondary mb-3">{strings.teachers.teacher_details.buy_lesson_from_teacher}</h5>
                {isAvailableToChange && (<p className="font-bold">{strings.support.teacher_time_change.teacher_change_information}</p>)}
                <hr/>

                {loading && (
                    <div className="my-3">
                        <Loading />
                    </div>
                )}

                {!loading && (
                    lessonFrozen
                        ? (<Frozen data={lessonFrozen} history={changeHistory} />)
                        : (rightCount !== undefined && rightCount === 0
                            ? (<ChangeTeacherTimeNoRight data={changeHistory} />)
                            : (isAvailableToChange ? (
                                    <>
                                        {listLoading && (
                                            <div className="my-3">
                                                <Loading />
                                            </div>
                                        )}
                                        {!listLoading && (
                                            <>
                                                {data.length > 0 && (
                                                    <p>
                                                        {strings.support.teacher_time_change.process} <strong
                                                        className="text-base">{termInfo}</strong> {strings.support.teacher_time_change.process_continue}
                                                    </p>
                                                )}
                                                <div className="mt-5">
                                                    {data.length > 0 && (
                                                        <>
                                                            <div className="font-bold flex justify-between">
                                                                <p className="w-2/4 text-start">{strings.support.teacher_list.teacher_name}</p>
                                                                <p className="w-1/4 text-start">{strings.support.teacher_list.lesson_time}</p>
                                                                <p className="w-1/4"></p>
                                                            </div>

                                                            {data.map((item, index) => (
                                                                <div key={index}>
                                                                    <hr className="my-2"/>
                                                                    <div className="flex justify-between">
                                                                        <p className="w-2/4 text-start">{item.teacherName}</p>
                                                                        <p className="w-1/4 text-start">{addTimeZone(item?.lessonTime?.slice(0, 5), timeZone)}</p>
                                                                        <p className="w-1/4">
                                                                            <Button
                                                                                classnames="button primary-button-outline max-w-max px-2 h-[25px] text-sm mt-0"
                                                                                action={() => {
                                                                                    analytics.useAnalytics('Egitmen_Saat_Degisikligi_Sec');
                                                                                    setModalText(modalText => `${item.teacherName} - ${addTimeZone(item?.lessonTime?.slice(0, 5), timeZone)}`);
                                                                                    setSelectedLessonTime(selectedLessonTime => item?.lessonTime);
                                                                                    openModal(modalOverlayRef, modalRef);
                                                                                }}
                                                                                text={strings.support.teacher_list.choose}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                    {data.length === 0 && (
                                                        <p className="text-center my-3">{strings.teachers.filter.avaliable_time_not_found}</p>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (<ChangeTeacherTimeNotAvailable data={changeHistory} />)
                            ))
                )}
            </div>

            <ModalOverlay ref={modalOverlayRef} />
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.transaction_information}
                message={strings.support.teacher_time_change.lesson_time_change_dialog
                    .replace(/#lesson#/g, modalText)}
                buttonText1={strings.modal.approve}
                buttonText2={strings.modal.dont_approve}
                buttonClick1={() => {
                    updateTeacherTime(selectedTermId, member.MemberId, selectedLessonTime);
                    closeModal(modalOverlayRef, modalRef);
                    openModal(modalOverlayRef, secondModalRef);
                }}
                buttonClick2={() => {
                    closeModal(modalOverlayRef, modalRef);
                }}
            />
            <ModalOneButton
                ref={secondModalRef}
                title={strings.modal.information_message}
                message={modalText}
                overlayRef={modalOverlayRef}
                buttonText={strings.modal.okey}
                buttonClick={() => {
                    error
                        ?
                        closeModal(modalOverlayRef, secondModalRef)
                        :
                        navigate(url("dashboard"));
                    setError(error => false);
                }}
                closeClick={() => {
                    error
                        ?
                        closeModal(modalOverlayRef, secondModalRef)
                        :
                        navigate(url("dashboard"));
                    setError(error => false);
                }}
            />
        </>
    )
}

export default TeacherTimeChange;