import {useLocalization} from "../../../../hooks/useLocalization";
import React from "react";

const SelectedPackageContent = (props) => {

    const { selectedProduct } = props;

    const strings = useLocalization();

    return (
        Object.keys(selectedProduct).length > 0 && (
            <div className="mx-auto px-2 xs:px-[15px] py-[10px] mt-5 text-sm text-center">
                <h5 className="text-primary mb-3">
                    {strings.market.speakingLesson.selected_package.title}
                </h5>

                <p className="font-bold">
                    {strings.market.speakingLesson.selected_package.monthly
                        .replace("#monthCount#", selectedProduct.month)}{" "}
                    {strings.market.speakingLesson.selected_package.weekly
                        .replace("#dayCount#", selectedProduct.dayCount)}{" "}
                    {strings.market.speakingLesson.selected_package.daily
                        .replace("#minuteCount#", selectedProduct.minute)}{" "}
                </p>

                <p className="mb-5">
                    {selectedProduct.title}{" "}
                    {selectedProduct.giftMonth > 0 && " + " + selectedProduct?.giftMonth + strings.market.speakingLesson.selected_package.gift_month}
                </p>

                <hr className="max-w-[330px] mx-auto" />

                <div className="flex flex-col gap-0.5 text-base-text font-bold mb-5">
                    <span>{strings.market.speakingLesson.selected_package.extra_rights_one}</span>
                    <span>{strings.market.speakingLesson.selected_package.extra_rights_two}</span>
                    <span>{strings.market.speakingLesson.selected_package.extra_rights_three}</span>
                </div>

                <hr className="max-w-[330px] mx-auto" />

                <div className="flex flex-col gap-0.5">
                    <span>{strings.market.speakingLesson.selected_package.postpone_right.replace("#number#", selectedProduct.productDetail?.postponeRightCount)}</span>
                    <span>{strings.market.speakingLesson.selected_package.change_teacher_and_time_right.replace("#number#", selectedProduct.productDetail?.teacherHourChangeRightCount)}</span>
                    <span>{strings.market.speakingLesson.selected_package.missed_call_right.replace("#number#", selectedProduct.productDetail?.missedRightCount)}</span>
                    <span>{selectedProduct.productDetail?.freezeRightCount > 0 && strings.market.speakingLesson.selected_package.freeze_lesson_right.replace("#number#", selectedProduct.productDetail?.freezeRightCount)}</span>
                </div>
                
            </div>
        )
    )
}

export default SelectedPackageContent;