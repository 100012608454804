import {Fragment} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import classNames from "classnames";
import {Arrow_Down} from "../assets/svg";

const Dropdown = (props) => {

    const {
        selected,
        setSelected,
        data,
        isDisabled = false,
        classnames = "max-w-max min-w-[150px] lg-xl:min-w-[130px]",
        innerClassnames = ""
    } = props;

    return (
        <div className={`${classnames}`}>
            <Listbox value={selected} onChange={setSelected} disabled={isDisabled}>
                <div className="relative mt-1">
                    <Listbox.Button
                        className={classNames("relative w-full cursor-default rounded-[10px] bg-white py-2 text-start pl-2 border border-onboarding-border pr-3 focus:outline-none focus-visible:border-indigo-500 sm:text-sm", {
                            [innerClassnames] : [!!innerClassnames]
                        })}
                    >
                        <span className="block truncate">{selected.name}</span>
                        {!isDisabled && (
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <img src={Arrow_Down} className="" alt="arrow_down"/>
                            </span>
                        )}
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className="absolute mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-10">
                            {data.map((item) => (
                                <Listbox.Option
                                    key={item.id}
                                    className={({active}) => classNames("relative cursor-default select-none py-2 text-start pl-2", {
                                        "text-secondary": active || item.id === selected.id
                                    })}
                                    value={item}
                                >
                                    {({selected}) => (
                                        <>
                                            <span
                                                className={classNames("block truncate", {
                                                    "font-medium": selected,
                                                    "font-normal": !selected
                                                })}
                                            >
                                              {item.name}
                                            </span>
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}

export default Dropdown;
