import {useLocalization} from "../../hooks/useLocalization";
import React, {useCallback, useEffect, useState} from "react";
import {GetBookUnitSentenceFilter, IncreaseSentenceClickCount} from "../../services/SentenceBank/SentenceBankService";
import {useLocation, useNavigate} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {getLanguageIdByLanguageKey} from "../../utils";
import {url} from "../../routes/utility";
import {Loading, NoRecordsFound, SentenceRow} from "../../components";
import usePageLeave from "../../hooks/usePageLeave";
import useAnalytics from "../../hooks/useAnalytics";
import {EventLogs} from "../../components/Constants";

const SentenceBank = () => {

    const strings = useLocalization();
    const { member } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const analytics = useAnalytics();
    const { setCallback } = usePageLeave();

    const pageLimit = 20;
    const bookInfo = location.state || false;

    const [sentences, setSentences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [clickedSentenceIds, setClickedSentenceIds] = useState([]);

    const [start, setStart] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const handleLoadMore = () => {
        if ((start + pageLimit) < totalRecords)
            setStart(start + pageLimit);

        analytics.useAnalytics(EventLogs.SENTENCE_BANK_LOAD_MORE_BUTTON_CLICKED, undefined, false, true);
    }

    const handleClickedSentence = (clickedSentenceId) => {
        setClickedSentenceIds(clickedSentences => [...clickedSentences, clickedSentenceId]);
    }

    const handlePageLeave = useCallback(() => {
        clickedSentenceIds.length > 0 &&
            IncreaseSentenceClickCount(clickedSentenceIds)
                .then()
                .catch()

        setClickedSentenceIds([]);
    }, [clickedSentenceIds]);

    useEffect(() => {
        setCallback(handlePageLeave);
    }, [setCallback, handlePageLeave]);

    useEffect(() => {
        if(bookInfo) {
            localStorage.setItem("selectedDate", bookInfo.selectedDate);

            const model = {
                start: start,
                length: pageLimit,
                memberId: member?.MemberId,
                bookUnitId: bookInfo?.bookUnitId,
                dailyDate: +bookInfo?.selectedDate.replaceAll("-", ""),
                languageTitleType: getLanguageIdByLanguageKey('az')
            };

            GetBookUnitSentenceFilter(model)
                .then(result => {
                    if(result.status === 200) {
                        const resultContent = JSON.parse(result.content);
                        setTotalRecords(resultContent.recordsTotal);
                        if (start === 0)
                            setSentences(resultContent.data);
                        else
                            setSentences(sentences => [...sentences, ...resultContent.data]);
                    }

                    setLoading(false);
                })
                .catch()
        } else {
            navigate(url("dashboard"));
        }
    }, [bookInfo, pageLimit, start]);

    return (
        <div className="main-content">
            <div className="w-full flex items-center ml-2 justify-between mt-5">
                <p className="text-secondary font-bold">{strings.speaking_lesson.daily_lesson.sentence_bank.title}</p>
                <button
                    className="ml-auto mr-2 border border-onboarding-border hover:bg-onboarding-bg-select focus:bg-onboarding-bg-select focus:text-base gap-2 h-8 max-w-max px-3 flex flex-row items-center justify-center rounded-[10px]"
                    onClick={() => navigate(url("speakinglesson.dailylesson"))}
                >
                    <span className="material-symbols-outlined">keyboard_return</span>
                    {strings.ai_chatbot.back}
                </button>
            </div>
            <div className="transparent-section mt-5">
                {loading && <Loading classnames="mb-5"/>}

                {!loading &&
                    <>
                        {sentences.length === 0 && <NoRecordsFound/>}

                        {sentences.length > 0 &&
                            <>
                                {sentences.map((item, index) => (
                                    <div key={index}>
                                        <SentenceRow
                                            data={item}
                                            showHr={sentences.length !== index + 1}
                                            handleClickedSentence={handleClickedSentence}
                                        />
                                    </div>
                                ))}
                                {totalRecords !== sentences.length &&
                                    <button
                                        className="text-center font-bold text-primary text-[14px] hover:text-secondary"
                                        onClick={handleLoadMore}
                                    >
                                        {strings.speaking_lesson.daily_lesson.sentence_bank.load_more}
                                    </button>
                                }
                            </>
                        }
                    </>
                }
            </div>
            <div className="left-margin-bottom"></div>
        </div>
    );
}

export default SentenceBank;