import { useState, useEffect, useCallback } from "react";
import { useLocalization } from "../../../../hooks/useLocalization";
import useAnalytics from "../../../../hooks/useAnalytics";
import {EventLogs} from "../../../../components/Constants";

const SpeakingLabCard = ({ isFirstSequence, onNext, cardData }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();

    const [countdown, setCountdown] = useState(10);

    const handleNext = useCallback(() => {
        if (countdown === 0) {
            onNext();
        }
    }, [countdown, onNext]);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            handleNext();
        }
    }, [countdown, handleNext]);

    useEffect(() => {
        analytics.useAnalytics(
            EventLogs.SPEAKING_LAB_INFO_CARD_VIEWED,
            undefined,
            false,
            true
        );
    }, []);

    return (
        <>
            <div className="gray-section min-h-[300px] mt-5 flex flex-col items-center justify-between p-4">
                <div className="h-2 bg-green-500 rounded-full mb-4"></div>
                <div>
                    <h2 className="text-xl font-bold text-center mb-2">{cardData.content}</h2>
                    <p className="text-center mb-4">{cardData.contentTranslation}</p>
                </div>
                <p className="text-center text-sm text-gray-500">
                    {isFirstSequence
                        ? strings.speaking_lesson.speaking_lab.first_card_counter_text.replace("#second#", countdown)
                        : strings.speaking_lesson.speaking_lab.second_card_counter_text.replace("#second#", countdown)
                    }
                </p>
            </div>

            <div className="btn-outer-div mt-5">
                <button 
                    className="button primary-button w-full"
                    onClick={onNext}
                >
                    {strings.speaking_lesson.quiz.continue}
                </button>
            </div>
        </>
    );
}

export default SpeakingLabCard;