import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import {Button} from "../../../../components";
import SpeakDialog from "../Tools/SpeakDialog";
import AnswerStatus from "../Common/AnswerStatus";
import {AnswerStatutes} from "../../../../components/Constants";
import {closeAnswerStatus, openAnswerStatus, getLogDescriptionAndParameter} from "../../../../utils";
import {useDispatch} from "react-redux";
import {removeId, setCorrectId, setMistakeId} from "../../../../store/ExerciseReportSlice";
import useAnalytics from "../../../../hooks/useAnalytics";
import {Info} from "../../../../assets/svg";

const Pronunciation = (props) => {

    const { selectedQuestion, questionsLength, questionIndex, setQuestionIndex, setInnerStep, setComponentStep, logs } = props;
    const strings = useLocalization();
    const analytics = useAnalytics();
    const dispatch = useDispatch();

    const answerStatusRef = useRef();

    const [questionText, setQuestionText] = useState("");
    const [isRecording, setIsRecording] = useState(false);
    const [answerStatus, setAnswerStatus] = useState(AnswerStatutes.NOT_ANSWERED);
    let questionArr = JSON.parse(selectedQuestion.content) || [];

    const handlePrevious = () => {
        if(questionIndex === 0) {
            setInnerStep();
            setAnswerStatus(answerStatus => AnswerStatutes.NOT_ANSWERED);
        } else {
            setQuestionIndex(questionIndex => questionIndex - 1);
        }
    }

    const handleNext = () => {

        let logInfoContinue = getLogDescriptionAndParameter(logs?.continue);

        if(questionsLength === 1) {
            //'Devam Et' butonunda log atılıyor
            logInfoContinue && analytics.useAnalytics(logInfoContinue.logDescription, logInfoContinue.parameterObject);

            setComponentStep();
            setAnswerStatus(answerStatus => AnswerStatutes.NOT_ANSWERED);
        } else {
            //'Devam Et' butonunda log atılıyor
            logInfoContinue && analytics.useAnalytics(logInfoContinue.logDescription, logInfoContinue.parameterObject);

            setQuestionIndex(questionIndex => questionIndex + 1);
        }
    }

    // Soruya geri gelindiğinde store'dan ilgili soruyu siler.
    useEffect(() => {
        dispatch(removeId(selectedQuestion.id));
    }, [selectedQuestion.id]);

    useEffect(() => {

        let result;

        if(answerStatus === AnswerStatutes.CORRECT) {
            dispatch(setCorrectId(selectedQuestion.id));
            result = true;
            openAnswerStatus(answerStatusRef);
        }

        if(answerStatus === AnswerStatutes.WRONG) {
            dispatch(setMistakeId(selectedQuestion.id));
            result = false;
            openAnswerStatus(answerStatusRef);
        }

        let logInfo = getLogDescriptionAndParameter(logs?.check);
        if(logInfo) {
            logInfo.parameterObject['result'] = result;
            analytics.useAnalytics(logInfo.logDescription, logInfo.parameterObject);
        }
    }, [answerStatus]);

    useEffect(() => {
        if(isRecording)
            closeAnswerStatus(answerStatusRef);
    }, [isRecording])


    /*
        * Diyalog sorusu array şeklinde geliyor.
        * Kullanıcı okuma yaptığında array şeklinde karşılaştırma yapamayacağımız için diyalog text
          haline getiriliyorç
    */
    useEffect(() => {
        if(questionArr.length > 0) {
            let tmpQuestionText = "";

            questionArr.map((question, index) => {
                tmpQuestionText += question.text + (index === questionArr.length -1 ? "" : " ");
            })

            setQuestionText(questionText => tmpQuestionText);
        }
    }, [])

    return (
        <div className="flex flex-col">
            <div className="mt-10 gray-section px-[10px] xs:px-[10px] text-start">

                <h5 className="mb-3">{strings.onboarding_exercises.read_dialog}</h5>

                {questionArr.map((speaker, index) => (
                    <div key={index}>
                        <p>
                            <span
                                className={classNames("font-bold transition-opacity duration-200", {
                                    "opacity-0": isRecording
                                })}
                            >
                                {speaker.title}:{" "}
                            </span>
                            {speaker.text}
                        </p>
                    </div>
                ))}

                <SpeakDialog
                    questionText={questionText}
                    setAnswerStatus={setAnswerStatus}
                    isRecording={isRecording}
                    setIsRecording={setIsRecording}
                />

                <div className="my-5 flex gap-1.5 items-center">
                    <img src={Info} width={24} height={24} alt="info"/>
                    <p className="text-warning">{strings.onboarding_exercises.dialog_info}</p>
                </div>

                <div className="flex gap-8 my-5 justify-center">
                    <Button
                        classnames="button primary-button-outline max-w-[150px]"
                        text={strings.pagination.previous}
                        action={handlePrevious}
                    />
                    <Button
                        classnames="button primary-button max-w-[150px]"
                        text={strings.speaking_lesson.quiz.continue}
                        action={handleNext}
                    />
                </div>
            </div>

            <AnswerStatus ref={answerStatusRef} answerStatus={answerStatus}/>
        </div>
    )
}

export default Pronunciation;