import {useLocalization} from "../../hooks/useLocalization";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Loading} from "../../components";
import {url} from "../../routes/utility";
import {GetVideoGrammar} from "../../services/VideoPractice/VideoPracticeService";
import {useSelector} from "react-redux";
import {getLanguageIdByLanguageKey, sendEventByDefaultParameters} from "../../utils";
import {EventLogs, GrammarContentType} from "../../components/Constants";
import {GrammarAI} from "./components";
import useAnalytics from "../../hooks/useAnalytics";

const Grammar = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const { videoId } = useParams();
    const language = useSelector(state => state.localizationStore.language);
    const videoInfo = JSON.parse(localStorage.getItem("videoInfo")) || false;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [iframeLoading, setIframeLoading] = useState(true);
    const [startTest, setStartTest] = useState(false);

    // base event fonksiyonu
    const sendEvent = (event, parameters) => {
        sendEventByDefaultParameters(
            event,
            analytics,
            videoInfo?.videoTags,
            {
                video_name: videoInfo?.videoName,
                gramer: data?.titleEnglish,
                ...parameters
            },
            { seviye: '', ilgi_alanlari: '' }
        );
    }

    // Verinin içerisinden grammar content bulan fonksiyon
    const getGrammarContentByContentType = (data, contentType) => {
        const tempContent = data.grammarContents
            .find(item =>
                item.grammarContentType.id === contentType
            );
        return tempContent?.content ?? "";
    }

    const handleBackButton = () => {
        navigate(url("videopractice.videoflow.dialog", {videoId: videoId}));
        sendEvent(EventLogs.VIDEO_EXERCISE_GRAMMAR_BACK_CLICK);
    }

    const handleContinueButton = () => {
        navigate(url("videopractice.videoflow.ai", {videoId: videoId}));
        sendEvent(EventLogs.VIDEO_EXERCISE_GRAMMAR_CONTINUE_CLICK);
    }

    const handleStartTestButton = () => {
        setStartTest(true);
        sendEvent(EventLogs.VIDEO_EXERCISE_GRAMMAR_START_TEST_CLICK);
    }

    useEffect(() => {
        setLoading(true);

        GetVideoGrammar(videoId, getLanguageIdByLanguageKey(language))
            .then(result => {
                if(result.status === 200) {
                    const resultContent = JSON.parse(result.content);
                    setData({
                        title: resultContent?.grammarCategoryTitleTranslation,
                        titleEnglish: resultContent?.grammarCategoryTitle,
                        description: getGrammarContentByContentType(resultContent, GrammarContentType.DESCRIPTION),
                        videoUrl: getGrammarContentByContentType(resultContent, GrammarContentType.VIDEO)?.length > 0
                            ? `https://www.youtube.com/embed/${getGrammarContentByContentType(resultContent, GrammarContentType.VIDEO)}`
                            : ""
                    });
                }

                setLoading(false);
            })
            .catch()
    }, [language])

    return (
        <>
            {!startTest &&
                <>
                    <div className="mt-10 gray-section px-[10px] xs:px-[10px] text-center flex flex-col gap-3">
                        {loading && <Loading classnames="mb-4"/>}

                        {(!loading && Object.keys(data).length > 0) &&
                            <div className="flex flex-col gap-3 items-center mb-2">
                                <p className="font-bold text-[18px] text-primary text-center">
                                    {strings.video_practice.grammar.title}
                                </p>

                                <p className="w-full text-center max-w-[600px] font-bold text-secondary">{data?.title}</p>

                                <p className="w-full text-center max-w-[600px]">{data?.description}</p>

                                {data?.videoUrl.length > 0 &&
                                    <div className="aspect-video max-w-[500px] mx-auto relative w-full">
                                        {iframeLoading && (
                                            // Video yüklenene kadar onun yerine bir skeleton loader ekledik
                                            <div className="animate-pulse bg-gray-300 rounded-md aspect-video w-full flex items-center justify-center">
                                                <div className="inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.25em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
                                            </div>
                                        )}
                                        <iframe
                                            width="100%"
                                            src={data.videoUrl}
                                            onLoad={() => setIframeLoading(false)}
                                            className={`aspect-video rounded-[10px] ${iframeLoading ? 'hidden' : 'block'}`}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className="flex flex-col gap-1 mt-7">
                        <div className="flex justify-center">
                            <Button
                                type="button"
                                classnames="button primary-button max-w-[324px]"
                                action={handleStartTestButton}
                                text={strings.speaking_lesson.daily_sentence.start_quiz}
                            />
                        </div>
                        <div className="flex gap-8 my-5 justify-center">
                            <Button
                                type="button"
                                classnames="button primary-button-outline max-w-[150px]"
                                action={handleBackButton}
                                text={strings.pagination.previous}
                            />
                            <Button
                                type="button"
                                classnames="button primary-button max-w-[150px]"
                                action={handleContinueButton}
                                text={strings.speaking_lesson.quiz.continue}
                            />
                        </div>
                    </div>
                </>
            }

            {startTest &&
                <GrammarAI
                    videoId={videoId}
                    setStartTest={setStartTest}
                    grammarTitle={data?.titleEnglish}
                />
            }
        </>
    )
}

export default Grammar;