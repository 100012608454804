import { getCookie } from 'ko-basic-cookie';
import { decodeToken } from '../utils';
import { cookieName, termStorageName } from '../components/Constants';

const useAuth = () => {

    const token = getCookie(cookieName);
    const storageTerms = token && (localStorage.getItem(termStorageName) || false);
    const member = token && decodeToken(token);

    if (storageTerms) {
        const terms = JSON.parse(storageTerms);
        member["Terms"] = decodeToken(terms);
    }
    
    return { member, token }
}

export default useAuth