import React, {useEffect} from "react";
import Confetti from 'react-confetti'
import {Success} from "../../../assets/svg";
import {url} from "../../../routes/utility";
import {Link} from "react-router-dom";
import {useLocalization} from "../../../hooks/useLocalization";
import {useDispatch} from "react-redux";
import {clearReport} from "../../../store/ExerciseReportSlice";
import {CreateExerciseResult} from "../../../services/OnboardingExercise/OnboardingExerciseService";
import useAnalytics from "../../../hooks/useAnalytics";
import {EventLogs, StepArray} from "../../../components/Constants";
import ExerciseStep from "../../Exercises/components/Common/ExerciseStep";

const OnboardingCongratulate = (props) => {

    const { report, day } = props;

    const strings = useLocalization();
    const analytics = useAnalytics();
    const dispatch = useDispatch();

    const sendReport = () => {
        CreateExerciseResult(report)
            .then(r => {})
            .catch(e => {});

        //Sonuç servise gönderildikten sonra reduxta tutulan veriler siliniyor.
        dispatch(clearReport());
    }

    useEffect(() => {
        //Sonuç servise gönderiliyor.
        sendReport();
        //Egzersiz tamamlandığında log atılıyor
        analytics.useAnalytics(EventLogs.ONBOARDING_EXERCISE_GRAMMAR_COMPLETED, { day_count: day });
    }, []);

    return (
        <div className="main-content">

            <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                gravity={0.04}
                run={true}
                numberOfPieces={500}
                recycle={false}
                initialVelocityY={{ min: 0, max: 15 }}
            />

            <ExerciseStep step={4} data={StepArray}/>

            <div className="mt-10 gray-section px-[10px] xs:px-[10px] flex flex-col items-center justify-center gap-8">
                <h5>{strings.onboarding_exercises.congratulate}</h5>

                <img
                    src={Success}
                    alt="success"
                    width={100}
                    height={100}
                />

                <p>{strings.onboarding_exercises.final_message}</p>

                {/* Eğer egzersizin son günü değil ise "Yarın görüşmek üzere" mesajı gösteriliyor. */}
                {day !== 3 && (
                    <p>{strings.onboarding_exercises.final_message_2}</p>
                )}

                <Link
                    to={url("dashboard")}
                    className="button w-[95%] xs:w-[330px] primary-button mb-5"
                >
                    {strings.modal.okey}
                </Link>
            </div>
        </div>
    )
}

export default OnboardingCongratulate;