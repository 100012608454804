import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Views } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import "../../assets/css/react-big-calendar.css";
import { Calendar, Loading, TermDropDown } from '../../components';
import { ColorDescriptions } from "../../components/Constants";
import { ISOStringToDate, calendarTranslationByTitle, colorForStatus, getTimeZone, isItSpecified } from '../../utils';
import useAuth from '../../hooks/useAuth';
import { useLocalization } from '../../hooks/useLocalization';
import { url } from '../../routes/utility';
import {
  GetTermScheduleByTermId
} from '../../services/SpeakingLesson/SpeakingLessonService';

const TermSchedule = () => {

  const strings = useLocalization();
    
  const { member } = useAuth();
  const timeZone = getTimeZone(member.Timezone);
  const terms = JSON.parse(member.Terms);
  const language = useSelector(state => state.localizationStore.language);
  
  const [termId, setTermId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [event, setEvent] = useState([]);
  const [currentView, setCurrentView] = useState(Views.MONTH);
  const [rangeStart, setRangeStart] = useState(0);
  const [rangeEnd, setRangeEnd] = useState(0);
  const [rangeEndMinute, setRangeEndMinute] = useState(0);

  // const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  
  //from child dropdown
  const changeTerm = term => {
    setTermId(term.TermId);
    setLoading(loading => true)
  };

  const getTermScheduleByTermId = async (termId) => {

      const result = await GetTermScheduleByTermId(termId)
  
      if (result.statusCode === 200) {
        let resultContent = JSON.parse(result.message);
        setData(data => resultContent);
        let tmpRangeArr = [];
        setLoading(loading => true);
  
        //Bir term içerisinde hem gelecek, hem aktif hem de geçmiş dersler olabilir.
        //Bu yüzden 'Day' ve 'Week' görünümünde bir aralık belirlemek için takvim verisindeki tüm ders saatleri bir array içerisinde atılıyor.
        resultContent.length > 0 && resultContent.map((item) => {
          let tmpStartTime = ISOStringToDate(item.startTime, timeZone).split('T')[1].split(':')[0];
          let tmpEndTime = ISOStringToDate(item.endTime, timeZone).split('T')[1].split(':')[0];
  
          tmpRangeArr.push(tmpStartTime);
          tmpRangeArr.push(tmpEndTime);
        });
  
        //Array içerisinden en küçük ve en büyük değerler alınarak bir aralık belirleniyor.
        //Aralık belirlendiğinde takvimin 'Day' ve 'Week' görünümü için min ve max değerler belirleniyor.
        if(tmpRangeArr.length > 0){
          let tmpRangeStart = parseInt(Math.min(...tmpRangeArr)) - 2;
          let tmpRangeEnd = parseInt(Math.max(...tmpRangeArr)) + 3;
  
          if(tmpRangeEnd >= 24){
            tmpRangeEnd = 23;
            setRangeEndMinute(rangeEndMinute => 59);
          }
  
          setRangeStart(rangeStart => tmpRangeStart);
          setRangeEnd(rangeEnd => tmpRangeEnd);
        }
      }
  }

  useEffect(() => {
    const notSpecified = strings.speaking_lesson.speaking_lesson_user.it_is_not_specified;

    let arrayList = [];
    
    data.length > 0 && data.map((item) => {
      arrayList.push({
        scheduleId: item.scheduleId,
        //bookTitle'larda çevirileri yazdırmak için kullanılan method
        title: (item.status === 1 || item.status === 2) ? isItSpecified(item.bookTitle, notSpecified) : isItSpecified(calendarTranslationByTitle(item.bookTitle, language), notSpecified),
        start: ISOStringToDate(item.startTime, timeZone), // Başlama saati
        end: ISOStringToDate(item.endTime, timeZone), // Bitiş saati
        status: item.status,
        color: colorForStatus(item.status)
      });
    });

    setEvent(event => arrayList);
  }, [data])

  const eventWrapper = ({ event }) => {
    const status = event.status || false;
    const backgroundColor = colorForStatus(status);
    
    return (
      <Link
        className={` ${(status === 9 || status === 10) && 'cursor-default'}`}
        to={(status !== 9 || status !== 10) && url("speakinglesson.dailylesson")}
        //Kullanıcının takvime tıkladığında seçtiği tarihi localstorage'a kaydediyoruz.
        onClick={() => {localStorage.setItem('selectedDate', event.start);}}
      >
        <div className={`${backgroundColor} p-1 m-1 group relative rounded-md`}>
          <p className={`${(status === 8 || status === 9) ? 'text-base-text' : 'text-white'} min-w-max`}>{event.title}</p>
        </div>
      </Link>
    );
  };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setCurrentView(Views.DAY);
    } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      setCurrentView(Views.WEEK);
    } else {
      setCurrentView(Views.MONTH);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    let abortController = new AbortController();
    
    if(termId) {
      setLoading(loading => false);
      getTermScheduleByTermId(termId);
    }
    
    return () => {
      abortController.abort();
    }
  }, [termId])

  return (
    <div className='mt-5 w-full main-content'>
      <div className="mb-5 gray-section bg-title-bg px-[10px] xs:px-[10px] text-start">
        <TermDropDown terms={terms} changeTerm={changeTerm} />
      </div>
      <div className={`${loading ? 'hidden' : ''}`}><Loading /></div> 
      <div className={`h-full ${!loading ? 'hidden' : ''}`}>
        <Calendar 
          views={[Views.MONTH, Views.DAY, Views.WEEK]} // İsteğe bağlı, görünümleri belirtmek için
          view={currentView}
          // onNavigate={(date) => {
          //   setSelectedMonth(selectedMonth => date.getMonth() + 1);
          //   setSelectedYear(selectedYear => date.getFullYear());
          // }}
          onView={(view) => setCurrentView(view)}
          startAccessor={(event) => moment(event.start).toDate()} // Başlangıç saati
          endAccessor={(event) => moment(event.end).toDate()}
          min={new Date().setHours(rangeStart, 0, 0)}
          max={new Date().setHours(rangeEnd, rangeEndMinute, rangeEndMinute)}
          events={event}
          components={{
            event: eventWrapper
          }}
          className="!pb-[120px] mt-10"
        />

        <div className="-mt-24 flex flex-wrap gap-x-5 gap-y-4">
          {ColorDescriptions.map((item, index) => (
            <div key={index} className="flex gap-1 items-center">
              <div className={`md-lg:w-[24px] md-lg:h-[24px] w-[30px] h-[30px] bg-${item.color} rounded-[10px] flex justify-center items-center`}></div>  
              <p>{language === 'en' ? item.eng : language === 'az' ? item.name : ""}</p>
            </div>            
          ))}
        </div>
      </div>
    </div>
  )
}

export default TermSchedule