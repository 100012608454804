import {ProgressBar, RadialBar} from "../../../components";
import React from "react";
import {useLocalization} from "../../../hooks/useLocalization";
import classNames from "classnames";
import {useSelector} from "react-redux";

const ScoreCard = ({ result, classnames = "" }) => {

    const strings = useLocalization();
    const language = useSelector(state => state.localizationStore.language);

    return (
        <div
            className={classNames("flex flex-col items-start gap-4", {
                [classnames]: !!classnames
            })}
        >
            <div className="grid grid-cols-3 gap-2">
                <div className="flex flex-col items-center">
                    <div className="flex items-center justify-center gap-0.5">
                        <div className="text-[14px] leading-[14px] flex mb-1 items-center justify-center">
                            <span
                                className="mr-1 font-bold">{strings.video_practice.pronunciation.pronunciation_score}</span>
                            <div className="relative group">
                                <span
                                    className="material-symbols-outlined text-[16px] cursor-default translate-y-[1px]">info</span>
                                <div
                                    className={classNames("tooltip-top z-50", {
                                        "-top-[175px]": language === 'en',
                                        "-top-[133px]": language === 'az'
                                    })}
                                >
                                    {strings.video_practice.pronunciation.pronunciation_description}
                                </div>
                            </div>
                        </div>
                    </div>
                    <RadialBar
                        score={result?.pron_score}
                        height={180}
                        fontSize={24}
                        offsetY={10}
                        size="50%"
                    />
                </div>

                <div className="flex flex-col gap-2 col-span-2">

                    <p className="text-[14px] -mb-2 font-bold">{strings.video_practice.pronunciation.score_breakdown}</p>

                    <div className="flex flex-col gap-1">
                        <div className="flex items-center justify-between gap-0.5">
                            <div className="flex gap-0.5">
                                <p className="text-[12px] mb-1">{strings.video_practice.pronunciation.accuracy_score}</p>
                                <div className="relative group">
                                    <span className="material-symbols-outlined text-[16px] cursor-default">info</span>
                                    <div className="tooltip-top -top-[173px]">
                                        {strings.video_practice.pronunciation.accuracy_description}
                                    </div>
                                </div>
                            </div>
                            <p className="text-[12px]">{result?.accuracy_score}/100</p>
                        </div>
                        <div className="h-3 border bg-[#d9d9d9]">
                            <ProgressBar width={result?.accuracy_score}/>
                        </div>
                    </div>

                    <div className="flex flex-col gap-1">
                        <div className="flex items-center justify-between gap-0.5">
                            <div className="flex gap-0.5">
                                <p className="text-[12px] mb-1">{strings.video_practice.pronunciation.fluency_score}</p>
                                <div className="relative group">
                                    <span className="material-symbols-outlined text-[16px] cursor-default">info</span>
                                    <div
                                        className={classNames("tooltip-top", {
                                            "-top-[115px]": language === 'en',
                                            "-top-[133px]": language === 'az'
                                        })}
                                    >
                                        {strings.video_practice.pronunciation.fluency_description}
                                    </div>
                                </div>
                            </div>
                            <p className="text-[12px]">{result?.fluency_score}/100</p>
                        </div>
                        <ProgressBar width={result?.fluency_score}/>
                    </div>

                    <div className="flex flex-col gap-1">
                        <div className="flex items-center justify-between gap-0.5">
                            <div className="flex gap-0.5">
                                <p className="text-[12px] mb-1">{strings.video_practice.pronunciation.prosody_score}</p>
                                <div className="relative group">
                                    <span className="material-symbols-outlined text-[16px] cursor-default">info</span>
                                    <div
                                        className={classNames("tooltip-top", {
                                            "-top-[113px]": language === 'en',
                                            "-top-[92px]": language === 'az'
                                        })}
                                    >
                                        {strings.video_practice.pronunciation.prosody_description}
                                    </div>
                                </div>
                            </div>
                            <p className="text-[12px]">{result?.prosody_score}/100</p>
                        </div>
                        <ProgressBar width={result?.prosody_score}/>
                    </div>

                    <div className="flex flex-col gap-1">
                        <div className="flex items-center justify-between gap-0.5">
                            <div className="flex gap-0.5">
                                <p className="text-[12px] mb-1">{strings.video_practice.pronunciation.completeness_score}</p>
                                <div className="relative group">
                                    <span className="material-symbols-outlined text-[16px] cursor-default">info</span>
                                    <div
                                        className={classNames("tooltip-top", {
                                            "-top-[92px]": language === 'en',
                                            "-top-[73px]": language === 'az'
                                        })}
                                    >
                                        {strings.video_practice.pronunciation.completeness_description}
                                    </div>
                                </div>
                            </div>
                            <p className="text-[12px]">{result?.completeness_score}/100</p>
                        </div>
                        <ProgressBar width={result?.completeness_score}/>
                    </div>
                </div>
            </div>
            <div className="flex gap-4 ml-6">
                <div className="flex items-center gap-1">
                    <div className="h-[10px] w-[10px] bg-red"></div>
                    <p className="text-[12px]">0 ~ 59</p>
                </div>
                <div className="flex items-center gap-1">
                    <div className="h-[10px] w-[10px] bg-secondary"></div>
                    <p className="text-[12px]">60 ~ 79</p>
                </div>
                <div className="flex items-center gap-1">
                    <div className="h-[10px] w-[10px] bg-green"></div>
                    <p className="text-[12px]">80 ~ 100</p>
                </div>
            </div>
        </div>
    )
}

export default ScoreCard;