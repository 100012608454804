import {useLocalization} from "../../../../hooks/useLocalization";
import {PackageContent} from "../index";
import classNames from "classnames";

const CheckoutInfo = (props) => {

    const { packageInfo, buttonLoading, handlePayment, successfulPayment } = props;
    const strings = useLocalization();

    return (
        <div className="hidden md:flex w-[30%] md:h-fit py-5 transparent-section mt-4 mb-5">
            <div className="flex flex-col mx-auto">
                {/* Onay Butonu */}
                <button
                    type='button'
                    className={classNames("button primary-button md-810:text-[16px]", {
                        "disabled:opacity-25 disabled:pointer-events-none": buttonLoading
                    })}
                    disabled={buttonLoading}
                    onClick={handlePayment}
                >
                    {buttonLoading
                        ? successfulPayment
                            ? strings.market.checkout.pay_is_ok
                            : strings.market.checkout.trying_to_pay
                        : strings.market.checkout.complete_button
                    }
                </button>

                <PackageContent
                    packageInfo={packageInfo}
                />
            </div>
        </div>
    );
}

export default CheckoutInfo;