import {useLocalization} from "../../hooks/useLocalization";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {GetFollowersAndFollowings} from "../../services/Profile/ProfileService";
import useAuth from "../../hooks/useAuth";
import classNames from "classnames";
import {Loading, LoadingSpinner} from "../../components";
import {formatDateWithMonthAndYear, getDefaultPhotoByGender} from "../../utils";
import {useSelector} from "react-redux";
import {EventLogs, MemberProfileStatus} from "../../components/Constants";
import {url} from "../../routes/utility";
import {NoContentFound} from "../../assets/svg";
import useFollowUnfollow from "../../hooks/useFollowUnfollow";
import useMemberUsername from "../../hooks/useMemberUsername";
import useAnalytics from "../../hooks/useAnalytics";

const ProfileFollowList = () => {

    const strings = useLocalization();
    const { username } = useParams();
    const { member } = useAuth();
    const { follow, followLoading } = useFollowUnfollow();
    const analytics = useAnalytics();
    const memberUsername = useMemberUsername(member?.MemberId);
    const navigate = useNavigate();
    const location = useLocation();

    const language = useSelector(state => state.localizationStore.language);
    const source = location?.state?.source || "followings";

    const [followersAndFollowings, setFollowersAndFollowings] = useState({});
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(source)

    const tabs = [
        {
            key: "followings",
            value: strings.profile.follow_list.following
        },
        {
            key: "followers",
            value: strings.profile.follow_list.follower
        }
    ];

    const getFollowersAndFollowings = (memberId) => {
        memberId &&
        GetFollowersAndFollowings(member?.MemberId, username)
            .then(result => {
                if(result.status === 200)
                    setFollowersAndFollowings(JSON.parse(result.content));
                else
                    navigate(url("dashboard"));

                setLoading(false);
            })
            .catch()
    }

    const handleProfileClick = (item) => {
        MemberProfileStatus.PUBLIC === item.profileStatus && item.isFollow &&
        navigate(url("profile.profilesub", { username: item.username }));
    }

    const handleFollow = (e, memberId) => {
        e.stopPropagation();
        follow(member?.MemberId, memberId, updateFollowersAndFollowingsData)
    }

    const updateFollowersAndFollowingsData = (memberId) => {

        /*
            * Kişi kendi profilinde ise bir takip işlemi yaptığında listelerin yenilenmesi
            * için servise gidiliyor.
        */
        if(memberUsername === username) {
            getFollowersAndFollowings(member?.MemberId);
        }

        /*
            * İşlem kullanıcının kendi profili dışında yapılıyor ise servise gidilmeyip,
            * sadece veri manipüle ediliyor.
        */
        if(memberUsername !== username) {
            setFollowersAndFollowings(prevState => {
                const data = [...prevState[activeTab]];
                const tempItemIndex = data.findIndex(x => x.memberId === memberId);

                if (tempItemIndex > -1) {
                    data[tempItemIndex] = {...data[tempItemIndex], isFollow: true};

                    return {
                        ...prevState,
                        [activeTab]: data
                    };
                }

                return prevState;
            });
        }
    };

    useEffect(() => {
        getFollowersAndFollowings(member?.MemberId);
    }, []);

    useEffect(() => {
        activeTab === "followings"
            ? analytics.useAnalytics(EventLogs.FOLLOWING_VIEWED, undefined, false, true)
            : analytics.useAnalytics(EventLogs.FOLLOWERS_VIEWED, undefined, false ,true);
    }, [activeTab])

    return (
        <div className="main-content">
            <div className="transparent-section mt-5">

                {loading && <Loading classnames="mb-5" />}

                {!loading &&
                    <>
                        <div className="mx-auto mt-5">
                            <ul id="tabs" className="flex justify-between mx-auto text-start">
                                {tabs.map((tab, index) => (
                                    <li
                                        key={index}
                                        className="font-bold w-full relative pb-1 cursor-pointer"
                                        onClick={() => setActiveTab(tab.key)}
                                    >
                                        <div
                                            className={classNames("", {
                                                "text-primary": activeTab === tab.key,
                                                "text-base-text": activeTab !== tab.key
                                            })}
                                        >
                                            {tab.key === "followers" &&
                                                tab.value.replace("#count#", followersAndFollowings.followers.length)}

                                            {tab.key === "followings" &&
                                                tab.value.replace("#count#", followersAndFollowings.followings.length)}
                                        </div>
                                        <div
                                            className={classNames("absolute bottom-0 left-0 right-0 h-[3px] bg-primary", {
                                                "bg-primary": activeTab === tab.key,
                                                "bg-onboarding-border": activeTab !== tab.key
                                            })}
                                        >
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="mt-5">
                            {followersAndFollowings[activeTab].length > 0 &&
                                followersAndFollowings[activeTab].map((item, index) => (
                                    <div key={index}>
                                        <div
                                            className={classNames("flex justify-between items-center p-2", {
                                                "mb-5": followersAndFollowings[activeTab].length - 1 === index,
                                                "hover:bg-box hover:rounded-[10px] cursor-pointer":
                                                    MemberProfileStatus.PUBLIC === item.profileStatus && item.isFollow
                                            })}
                                            onClick={() => handleProfileClick(item)}
                                        >
                                            <div className="flex gap-3">
                                                <img
                                                    src={getDefaultPhotoByGender(item?.gender, item?.memberId) ?? ""}
                                                    width="45"
                                                    height="40"
                                                    alt="profile-photo"
                                                />
                                                <div className="flex flex-col items-start justify-center">
                                                    <button
                                                        type="button"
                                                        className="font-bold"
                                                    >
                                                        {item?.fullName}
                                                    </button>
                                                    <p className="text-[14px] text-secondary">
                                                        {strings.profile.join_date_text
                                                            ?.replace("#date#", formatDateWithMonthAndYear(item?.joinDate, language))}
                                                    </p>
                                                </div>
                                            </div>

                                            {MemberProfileStatus.PRIVATE !== item?.profileStatus &&
                                                <>
                                                    {item?.isFollow &&
                                                        <div className="relative group mt-1">
                                                            <button
                                                                type="button"
                                                                className="material-symbols-outlined text-[20px] text-[#333] font-bold"
                                                            >
                                                                arrow_forward_ios
                                                            </button>
                                                            <div className="tooltip-top -top-[36px]">
                                                                {strings.profile.follow_list.profile_click}
                                                            </div>
                                                        </div>
                                                    }

                                                    {!item?.isFollow &&
                                                        <div className="relative group mt-1">
                                                            <button
                                                                type="button"
                                                                className="material-symbols-outlined text-[22px] text-[#333]"
                                                                onClick={(e) => handleFollow(e, item?.memberId)}
                                                            >
                                                                {!followLoading && "add_circle"}

                                                                {followLoading && <LoadingSpinner />}
                                                            </button>
                                                            <div className="tooltip-top -top-[36px]">
                                                                {strings.profile.follow}
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                        {followersAndFollowings[activeTab].length - 1 !== index && <hr className="my-2.5"/>}
                                    </div>
                                ))
                            }

                            {followersAndFollowings[activeTab].length === 0 &&
                                <div className="flex flex-col items-center mt-5 mb-5">
                                    <img
                                        src={NoContentFound || ""}
                                        width="50"
                                        height="50"
                                        alt="no-content-found"
                                    />
                                    <p>
                                        {activeTab === "followers" &&
                                            strings.profile.follow_list.follower_empty_text
                                        }

                                        {activeTab === "followings" &&
                                            strings.profile.follow_list.following_empty_text
                                        }
                                    </p>
                                </div>
                            }
                        </div>
                    </>
                }
            </div>

            <div className="left-margin-bottom"></div>
        </div>
    );
}

export default ProfileFollowList;