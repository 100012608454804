import {useNavigate, useParams} from "react-router-dom";
import {Button, Loading, ModalOneButton, ModalOverlay, RadialBar} from "../../components";
import {url} from "../../routes/utility";
import React, {useEffect, useRef, useState} from "react";
import {useLocalization} from "../../hooks/useLocalization";
import {AddBlack, BookBlack, EditBlack, MicrophoneBlack} from "../../assets/svg";
import useAuth from "../../hooks/useAuth";
import {closeModal, openModal, sendEventByDefaultParameters} from "../../utils";
import {AddMemberWordStore, GetVideoWords} from "../../services/VideoPractice/VideoPracticeService";
import classNames from "classnames";
import {EventLogs, LanguageTitleType, ServiceErrorMessages, WordStoreType} from "../../components/Constants";
import {OriginModal, WordModal, WritingExerciseModal} from "../Exercises/components";
import PronunciationModal from "../Exercises/components/Modals/PronunciationModal";
import useAnalytics from "../../hooks/useAnalytics";

const Word = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const { videoId } = useParams();
    const { member } = useAuth();
    const videoInfo = JSON.parse(localStorage.getItem("videoInfo")) || false;

    const modalOverlayRef = useRef();
    const modalRef = useRef();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedWordData, setSelectedWordData] = useState({});
    const [modalMessage, setModalMessage] = useState("");

    // Butonlardaki pencereleri kontrol eden stateler
    const [openWordModal, setOpenWordModal] = useState(false);
    const [openPronunciationModal, setOpenPronunciationModal] = useState(false);
    const [openOriginModal, setOpenOriginModal] = useState(false);
    const [openWritingExerciseModal, setOpenWritingExerciseModal] = useState(false);

    // base event fonksiyonu
    const sendEvent = (event, parameters) => {
        sendEventByDefaultParameters(
            event,
            analytics,
            videoInfo?.videoTags,
            {
                video_name: videoInfo?.videoName,
                ...parameters
            },
            { seviye: '', ilgi_alanlari: '' }
        );
    }

    // Video kelimelerini getiren servis
    const getVideoWords = () => {
        const model = {
            videoId: videoId,
            memberId: member?.MemberId,
            languageTitleType: LanguageTitleType.AZERBAIJANI
        };

        setLoading(true);

        GetVideoWords(model)
            .then(result => {
                if(result.status === 200) {
                    setData(JSON.parse(result.content));
                }

                setLoading(false);
            })
            .catch()
    }

    // Kelime haznesine ekleme yapan servis
    const addMemberWordStore = (wordId, word) => {
        const model = {
            memberId: member?.MemberId,
            wordId: wordId,
            wordStoreType: WordStoreType.VIDEO
        };

        AddMemberWordStore(model)
            .then(result => {
                if(result.status === 200) {
                    // Servise giderek verileri güncelliyoruz
                    getVideoWords();

                    setModalMessage(strings.video_practice.word_card.add_member_word_store_success_message
                        .replace("#word#", word)
                    );
                } else {
                    if(result.content === ServiceErrorMessages.MEMBER_WORD_ALREADY_EXITS) {
                        setModalMessage(strings.video_practice.word_card.add_member_word_store_error_message
                            .replace("#word#", word)
                        );
                    } else {
                        setModalMessage(strings.onboarding.information_messages.an_error_occured);
                    }
                }

                openModal(modalOverlayRef, modalRef);
            })
            .catch();
    }

    const fillData = (item) => {
        setSelectedWordData({
           wordId: item?.id,
           word: item?.word,
           sentence: item?.wordExerciseSentence,
           origin: item?.wordOrigin,
           originEnglish: item?.wordOriginEnglish,
           phonetic: item?.phonetic,
           wordType: item?.wordType,
           isInMemberWordStore: item?.isInMemberWordStore
        });
    }

    const handleBackButton = () => {
        navigate(url("videopractice.videoflow.video", {videoId: videoId}));
        sendEvent(EventLogs.VIDEO_EXERCISE_WORD_LIST_BACK_CLICK); //LOGGED
    }

    const handleContinueButton = () => {
        navigate(url("videopractice.videoflow.dialog", {videoId: videoId}));
        sendEvent(EventLogs.VIDEO_EXERCISE_WORD_LIST_CONTINUE_CLICK); //LOGGED
    }

    const handleAddMemberWordStoreModal = (e, item) => {
        e.stopPropagation();

        addMemberWordStore(item.id, item.word);
        sendEvent(
            EventLogs.VIDEO_EXERCISE_WORD_LIST_ADD_TO_LIST_CLICK,
            {
                word: item.word
            }
        ); //LOGGED
    }

    const handleOpenWordModal = (item) => {
        fillData(item);
        setOpenWordModal(true);
        document.body.style.overflow = 'hidden';
    }

    const handleOpenPronunciationModal = (e, item) => {
        e.stopPropagation();
        fillData(item);
        setOpenPronunciationModal(true);
        document.body.style.overflow = 'hidden';

        sendEvent(
            EventLogs.VIDEO_EXERCISE_WORD_LIST_PRONUNCIATION_CLICK,
            {
                word: item.word
            }
        ); //LOGGED
    }

    const handleOpenPronunciationModalFromScore = (e, item) => {
        e.stopPropagation();
        fillData(item);
        setOpenPronunciationModal(true);
        document.body.style.overflow = 'hidden';

        sendEvent(
            EventLogs.VIDEO_EXERCISE_WORD_LIST_PRONUNCIATION_CLICK,
            {
                word: item.word
            }
        ); //LOGGED
    }

    const handleOpenWritingExerciseModal = (e, item) => {
        e.stopPropagation();
        fillData(item);
        setOpenWritingExerciseModal(true);

        sendEvent(
            EventLogs.VIDEO_EXERCISE_WORD_LIST_WRITE_CLICK,
            {
                word: item.word
            }
        ); //LOGGED
    }

    const handleOpenOriginModal = (e, item) => {
        e.stopPropagation();
        fillData(item);
        setOpenOriginModal(true);
        document.body.style.overflow = 'hidden';

        sendEvent(
            EventLogs.VIDEO_EXERCISE_WORD_LIST_MORE_INFO_CLICK,
            {
                word: item.word
            }
        ); //LOGGED
    }

    const handleClosePronunciationModal = () => {
        setOpenPronunciationModal(false);
        getVideoWords();
    }

    useEffect(() => {
        getVideoWords();
    }, [])

    return (
        <>
            <div className="mt-10 gray-section px-[10px] xs:px-[10px] text-center flex flex-col gap-3">
                {loading && <Loading classnames="mb-4" />}

                {!loading &&
                    <>
                        <p className="font-bold text-[18px] text-primary text-center">
                            {strings.video_practice.word.title}
                        </p>

                        <p className="text-center">
                            {strings.video_practice.word.description
                                .replace("#count#", data.length)
                                .replace("#multiple#", data.length > 1 ? "s" : "")
                            }
                        </p>

                        {data.length > 0 &&
                            data.map((item, index) => (
                                <div
                                    key={index}
                                    className={classNames("flex cursor-pointer justify-between items-center w-full h-11 border border-onboarding-border bg-white hover:bg-onboarding-bg rounded-[10px] px-4", {
                                        "mb-2" : data.length - 1 === index
                                    })}
                                    onClick={() => handleOpenWordModal(item)}
                                >
                                    <p className="font-bold">{item.word}</p>

                                    <div className="flex items-center justify-center gap-3 mt-1">
                                        {!item.isInMemberWordStore &&
                                            <div className="relative group" onClick={(e) => handleAddMemberWordStoreModal(e, item)}>
                                                <button type="button">
                                                    <img src={AddBlack} width={24} height={24} alt="add"/>
                                                </button>
                                                <div className="tooltip-top -top-[38px]">{strings.video_practice.word_card.add_learn_word_list}</div>
                                            </div>
                                        }

                                        <div className="relative group">
                                            <button type="button" onClick={(e) => handleOpenOriginModal(e, item)}>
                                                <img src={BookBlack} width={24} height={24} alt="reading"/>
                                            </button>
                                            <div className="tooltip-top -top-[38px]">{strings.video_practice.word_card.word_origin}</div>
                                        </div>

                                        {item?.wordExerciseSentence?.length > 0 &&
                                            <div className="relative group">
                                                <button
                                                    type="button"
                                                    onClick={(e) => handleOpenWritingExerciseModal(e, item)}
                                                >
                                                    <img src={EditBlack} width={24} height={24} alt="edit"/>
                                                </button>
                                                <div className="tooltip-top -top-[38px]">{strings.video_practice.word_card.writing_exercise}</div>
                                            </div>
                                        }

                                        <div className="relative group">
                                            <button type="button" onClick={(e) => handleOpenPronunciationModal(e, item)}>
                                                <img src={MicrophoneBlack} width={24} height={24} alt="microphone"/>
                                            </button>
                                            <div className="tooltip-top -top-[38px]">{strings.video_practice.word_card.word_pronunciation}</div>
                                        </div>

                                        <div className="-ml-2">
                                            <RadialBar
                                                score={item.pronunciationScore}
                                                height={82}
                                                fontSize={11}
                                                offsetY={4}
                                                size="25%"
                                                classnames="w-[32px] h-[64px]"
                                                action={(e) => handleOpenPronunciationModalFromScore(e, item)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                }
            </div>

            <div className="flex gap-8 my-5 justify-center">
                <Button
                    type="button"
                    classnames="button primary-button-outline max-w-[150px]"
                    action={handleBackButton}
                    text={strings.pagination.previous}
                />
                <Button
                    type="button"
                    classnames="button primary-button max-w-[150px]"
                    action={handleContinueButton}
                    text={strings.speaking_lesson.quiz.continue}
                />
            </div>

            {openWordModal &&
                <WordModal
                    source="video"
                    wordId={selectedWordData?.wordId}
                    handleClose={() => setOpenWordModal(false)}
                />
            }

            {openPronunciationModal &&
                <PronunciationModal
                    pronunciationInfo={{
                        pronunciationId: selectedWordData?.wordId,
                        content: selectedWordData?.word,
                        phonetic: selectedWordData?.phonetic,
                        source: 'word-card',
                        logParameters: {
                            video_name: videoInfo?.videoName,
                            word: selectedWordData?.word,
                            is_selected: selectedWordData?.isInMemberWordStore
                        }
                    }}
                    setOpenPronunciationModal={handleClosePronunciationModal}
                    cancelScroll={true}
                />
            }

            {openOriginModal &&
                <OriginModal
                    wordInfo={{
                        wordId: selectedWordData?.wordId,
                        originDescription: selectedWordData?.origin,
                        originDescriptionEnglish: selectedWordData?.originEnglish,
                        wordType: selectedWordData?.wordType,
                        logParameters: {
                            video_name: videoInfo?.videoName,
                            word: selectedWordData?.word,
                            is_selected: selectedWordData?.isInMemberWordStore
                        }
                    }}
                    setOpenOriginModal={setOpenOriginModal}
                    cancelScroll={true}
                />
            }

            {openWritingExerciseModal &&
                <WritingExerciseModal
                    writingExerciseInfo={{
                        word: selectedWordData?.word,
                        sentence: selectedWordData?.sentence,
                        logParameters: {
                            video_name: videoInfo?.videoName,
                            word: selectedWordData?.word,
                            is_selected: selectedWordData?.isInMemberWordStore
                        }
                    }}
                    setOpenWritingExerciseModal={setOpenWritingExerciseModal}
                />
            }

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                overlayRef={modalOverlayRef}
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={modalMessage}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => {
                    closeModal(modalOverlayRef, modalRef);
                }}
            />
        </>
    )
}

export default Word;