import {useEffect, useRef, useState} from 'react'
import {useLocalization} from '../../../hooks/useLocalization'
import useAuth from '../../../hooks/useAuth';
import {InformationAccordion} from '../components';
import {Button, ModalOneButton, ModalOverlay, ModalTwoButtons} from '../../../components';
import useAnalytics from '../../../hooks/useAnalytics';
import {FreezeClassesService} from '../../../services/Support/SupportService';
import {EventLogs} from "../../../components/Constants";
import {closeModal, openModal} from "../../../utils"

const Freeze = ({ termId, lesson, getFreezeData }) => {
    const strings = useLocalization();
    const analytics = useAnalytics();
    const { token } = useAuth();

    const [error, setError] = useState();
    const [completed, setCompleted] = useState(false);
    const [firstPermission, setFirstPermission] = useState(false);
    const [secondPermission, setSecondPermission] = useState(false);


    const modalRef = useRef();
    const secondModalRef = useRef();
    const thirdModalRef = useRef();
    const modalOverlayRef = useRef();

    const generalInformationData = [
        { id: 1, text: strings.support.freeze_lesson_information.information_one },
        { id: 2, text: strings.support.freeze_lesson_information.information_two },
        {
            id: 3,
            text: strings.support.freeze_lesson_information.information_three,
        },
        { id: 4, text: strings.support.freeze_lesson_information.information_four },
        { id: 5, text: strings.support.freeze_lesson_information.information_five },
        { id: 6, text: strings.support.freeze_lesson_information.information_six },
        {
            id: 7,
            text: strings.support.freeze_lesson_information.information_seven,
        },
    ];

    const errorList = [
        { 'term not found!': strings.support.error_list.term_not_found },
        { 'lessontime is invalid!': strings.support.error_list.lesson_time_is_invalid },
        {
            'Freeze right already used by specified date!':
                strings.support.error_list.freeze_right_already_used_by_specified_date,
        },
        {
            'You do not have any freeze right!':
                strings.support.error_list.you_do_not_have_any_freeze_right,
        },
        {
            'The lesson time of term is invalid':
                strings.support.error_list.the_lesson_time_of_term_invalid,
        },
        {
            'You are not in the freeze time range!':
                strings.support.error_list.you_are_not_in_the_freeze_time_range,
        },
    ];

    const errorTranslation = err => {

        errorList.map(error => {
            if (Object.keys(error) == err) {
                let errorMsg = Array.isArray(Object.values(error)) ? Object.values(error)[0] : JSON.stringify(Object.values(error));
                setError(errorMsg);
                analytics.useAnalytics('Error_Occured', {
                    error: `${errorMsg}`,
                });
            }
        });
    };

    const freezeRequest = async () => {
        try {
            const result = await FreezeClassesService(termId, token);
            if (result.statusCode !== 200) {
                analytics.useAnalytics(EventLogs.FREEZE_LESSON_OPERATION_FAILED);
                if (result.message) {
                    result.message.errors
                        ? errorTranslation(result.message.errors.LessonDate[0])
                        : errorTranslation(result.message);
                } else {
                    setError(strings.general_information_messages.an_error_occured);
                }
                return;
            } else {
                if (result.message !== {}) {
                    analytics.useAnalytics(EventLogs.FREEZE_LESSON_STEP_TWO);
                    setError(strings.support.freeze_lesson.modal_success_message);
                    setCompleted(completed => true);
                }
            }
        } catch (error) {
            setError(strings.general_information_messages.an_error_occured);
        } finally {
            closeModal(modalOverlayRef, modalRef);
            closeModal(modalOverlayRef, secondModalRef);
            openModal(modalOverlayRef, thirdModalRef);
        }

    };

    useEffect(() => {
        //close first and second modal, open third modal
        if (firstPermission && secondPermission) {
            freezeRequest(termId);
        }

    }, [firstPermission, secondPermission])

    return (
        <>

            <InformationAccordion
                title={strings.support.freeze_lesson_information.information_title}
                generalInformationData={generalInformationData}
            />

            <div className="btn-outer-div">
                <Button
                    classnames="button primary-button my-7"
                    action={() => {
                        analytics.useAnalytics(EventLogs.FREEZE_LESSON_START_CLICKED);
                        openModal(modalOverlayRef, modalRef);
                    }}
                    text={strings.support.freeze_lesson.freeze_lesson}
                />
            </div>


            {/* Modal */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.transaction_information}
                message={strings.support.freeze_lesson.modal_content
                    .replace(/#lesson#/g, lesson)}
                buttonText1={strings.modal.approve}
                buttonText2={strings.modal.dont_approve}
                buttonClick1={() => {
                    setFirstPermission(firstPermission => true);
                    analytics.useAnalytics(EventLogs.FREEZE_LESSON_STEP_ONE);
                    closeModal(modalOverlayRef, modalRef);
                    openModal(modalOverlayRef, secondModalRef);
                }}
                buttonClick2={() => {
                    closeModal(modalOverlayRef, modalRef);
                    setFirstPermission(firstPermission => false);
                    setSecondPermission(secondPermission => false);
                }}
            />

            <ModalTwoButtons
                ref={secondModalRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.transaction_information}
                message={strings.support.freeze_lesson.second_modal_content}
                buttonText1={strings.modal.approve}
                buttonText2={strings.modal.dont_approve}
                buttonClick1={() => {
                    setSecondPermission(secondPermission => true);
                }}
                buttonClick2={() => {
                    setFirstPermission(firstPermission => false);
                    setSecondPermission(secondPermission => false);
                    closeModal(modalOverlayRef, secondModalRef);
                }}
            />

            <ModalOneButton
                ref={thirdModalRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.information_message}
                message={error}
                buttonClick={() => {
                    closeModal(modalOverlayRef, thirdModalRef);
                    completed && getFreezeData(termId);
                }}
                buttonText={strings.modal.okey}
            />

        </>
    )
}

export default Freeze