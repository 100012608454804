import React from 'react'
import {Accordion, AudioSubtitle} from "../../../../../../components";
import {useLocalization} from "../../../../../../hooks/useLocalization";
import useAccordionActivity from "../../../../../../hooks/useAccordionActivity";
import {LessonResultAccordionType} from "../../../../../../components/Constants";

const LessonAudio = ({ title, src, event, lessonResultId, lessonResultAccordionActivities }) => {

    const strings = useLocalization();
    const { activities, addAccordionActivity } = useAccordionActivity(
        lessonResultId,
        lessonResultAccordionActivities
    );

    return (
        <Accordion
            title={strings.speaking_lesson.daily_lesson.lesson_audios}
            disableOutsideClick={true}
            isScrollable={false}
            isDownArrowIcon={false}
            openCloseCheckboxStatus={
                activities
                    ?.find(l => LessonResultAccordionType.LESSON_RECORDS === l.lessonResultAccordionType)?.isOpened
                || false
            }
            action={() => addAccordionActivity(LessonResultAccordionType.LESSON_RECORDS)}
        >
            <div className="pb-5">
                <div className="transparent-section mt-5">
                    <h5 className="text-primary">{title}</h5>
                    {src.map((item, index) => (
                        <AudioSubtitle
                            key={`${item}-${index}`}
                            src={item}
                            event={event}
                        />
                    ))}
                    <p className="text-base-text-light text-[14px] italic">
                        <span className="font-bold not-italic">{strings.ai_chatbot.note}:</span>{" "}
                        {strings.speaking_lesson.speaking_lesson_user.audio_note}
                    </p>
                </div>
            </div>
        </Accordion>
    )
}

export default LessonAudio