import {ModalFullScreenWhite} from "../../../../components";
import React, {useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import {ShareModal} from "../index";
import {sendEventByDefaultParameters} from "../../../../utils";
import useAnalytics from "../../../../hooks/useAnalytics";
import {EventLogs} from "../../../../components/Constants";

const OriginModal = (props) => {

    const {
        setOpenOriginModal,
        wordInfo,
        cancelScroll = false
    } = props;

    const strings = useLocalization();
    const analytics = useAnalytics();
    const videoInfo = JSON.parse(localStorage.getItem("videoInfo")) || false;

    const [openShareModal, setOpenShareModal] = useState(false);

    // base event fonksiyonu
    const sendEvent = (event) => {
        sendEventByDefaultParameters(
            event,
            analytics,
            videoInfo.videoTags,
            wordInfo?.logParameters,
            { seviye: '', ilgi_alanlari: '' }
        );
    }

    // Kelime kökeni kapatma buton işlemlerini yapan fonksiyon
    const handleCloseOriginModal = () => {
        if(cancelScroll)
            document.body.style.overflow = 'auto';
        setOpenOriginModal(false);
        sendEvent(EventLogs.VIDEO_EXERCISE_WORD_MORE_INFO_BACK); //LOGGED
    }

    // Paylaşma butonu işlemlerini yapan fonksiyon
    const handleShareButton = () => {
        setOpenShareModal(true);
        sendEvent(EventLogs.VIDEO_EXERCISE_WORD_MORE_INFO_SHARE_CLICK); //LOGGED
    }

    return (
        <>
            <ModalFullScreenWhite
                handleCloseClick={handleCloseOriginModal}
                children={
                    <div className="flex items-center justify-center mt-4">
                        <div className="max-w-[500px] flex flex-col gap-4 px-4 md:px-0">
                            <p className="text-secondary text-center text-[18px] font-bold">{strings.video_practice.word_card.word_origin}</p>
                            <button
                                type="button"
                                className="ml-auto group relative"
                                onClick={handleShareButton}
                            >
                                <span className="material-symbols-outlined">share</span>
                                <div className="tooltip-top -top-[38px]">{strings.video_practice.word_card.share}</div>
                            </button>
                            <div className="h-[80vh] overflow-y-hidden hover:overflow-y-auto">
                                <p className="font-bold">{strings.video_practice.word_card.word_origin}</p>
                                <p>{wordInfo?.originDescriptionEnglish}</p>
                                <p className="font-bold mt-5">{strings.video_practice.word_card.word_origin_translation}</p>
                                <p >{wordInfo?.originDescription}</p>
                            </div>
                        </div>
                    </div>
                }
            />
            {openShareModal &&
                <ShareModal
                    shareModalInfo={{
                        wordId: wordInfo?.wordId,
                        wordType: wordInfo?.wordType,
                        shareType: 'origin'
                    }}
                    setOpenShareModal={setOpenShareModal}
                />
            }
        </>
    );
}

export default OriginModal;