import {getDefaultPhotoByGender} from "../../../../utils";
import {useLocalization} from "../../../../hooks/useLocalization";
import {useEffect, useState} from "react";
import {GetFollowRecommendations} from "../../../../services/Profile/ProfileService";
import {Loading} from "../../../../components";
import {MemberProfileStatus} from "../../../../components/Constants";
import {Link} from "react-router-dom";
import useFollowUnfollow from "../../../../hooks/useFollowUnfollow";
import useAuth from "../../../../hooks/useAuth";

const Recommendations = (props) => {

    const { getMemberProfile, sendRequestToRecommendations, setSendRequestToRecommendations } = props;

    const strings = useLocalization();
    const { member } = useAuth();
    const { follow } = useFollowUnfollow();

    const [recommendations, setRecommendations] = useState([]);
    const [loading, setLoading] = useState(true);

    const getRandomFirstName = (name) => {
        if (name.length === 0) return '';
        const randomIndex = Math.floor(Math.random() * name.length);
        return name[randomIndex].fullname.split(' ')[0];
    };

    const getFollowRecommendations = (memberId) => {
        GetFollowRecommendations(memberId)
            .then((result) => {
                if(result.status === 200) {
                    const resultContent = JSON.parse(result.content)
                        .filter(item => !item.isFromMyContacts);

                    setRecommendations(resultContent);
                    setSendRequestToRecommendations(false);
                }

                setLoading(false);
            })
            .catch()
    }

    const removeFollowedMemberFromData = (memberId) => {
        setRecommendations(recommendations => recommendations.filter(r => r.memberId !== memberId));
    }

    useEffect(() => {
        member?.MemberId &&
            getFollowRecommendations(member?.MemberId);
    }, []);

    useEffect(() => {
        (member?.MemberId && setSendRequestToRecommendations) &&
            getFollowRecommendations(member?.MemberId);
    }, [sendRequestToRecommendations]);

    return (
        recommendations.length > 0 &&
            <div className="mt-4">
                <p className="text-start font-bold">
                    {strings.profile.recommendations.title}
                </p>

                {loading && <Loading classnames="!mt-2" />}

                {!loading &&
                    <div className="flex gap-2 mt-1.5 max-w-max overflow-x-auto sm:overflow-x-hidden sm:hover:overflow-x-auto">
                        {recommendations.map((item, index) => {

                            const commonMembers = item?.commonMemberList
                                .filter(c => c.profileStatus === MemberProfileStatus.PUBLIC);

                            return (
                                <div
                                    key={index}
                                    className="flex flex-shrink-0 flex-col items-center gap-2 py-3 bg-box w-[155px] h-[185px] rounded-[10px] mb-2"
                                >
                                    <div className="flex flex-col items-center gap-1">
                                        <img
                                            src={getDefaultPhotoByGender(item?.gender, item?.memberId) ?? ""}
                                            width="50"
                                            height="50"
                                            alt="recommend-profile-photo"
                                        />
                                        <Link
                                            to={`/profile/${item.username}`}
                                            className="text-center text-primary hover:text-secondary font-bold underline w-[136px] overflow-hidden whitespace-nowrap truncate"
                                        >
                                            {item?.fullname}
                                        </Link>
                                    </div>

                                    {commonMembers.length === 1 &&
                                        <p className="text-[14px] text-center h-[42px]">
                                            {strings.profile.recommendations.single_recommend_member_text
                                                ?.replace("#name#",
                                                    commonMembers[0]?.fullname
                                                        ?.split(" ")[0]
                                                )
                                            }
                                        </p>
                                    }

                                    {commonMembers.length > 1 &&
                                        <p className="text-[14px] text-center h-[42px]">
                                            {strings.profile.recommendations.multiple_recommend_member_text
                                                ?.replace("#name#", getRandomFirstName(item?.commonMemberList))
                                                ?.replace("#count#", (item?.commonMemberList.length - 1).toString())}
                                        </p>
                                    }

                                    <button
                                        type="button"
                                        className="font-bold text-[14px] text-center rounded-[10px] !h-[25px] w-[76px] transition-colors duration-300 bg-primary text-white hover:bg-secondary"
                                        onClick={() =>
                                            follow(
                                                member?.MemberId,
                                                item?.memberId,
                                                () => {
                                                    removeFollowedMemberFromData(item?.memberId);
                                                    getMemberProfile();
                                                }
                                            )
                                        }
                                    >
                                        {strings.profile.follow}
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
    );
}

export default Recommendations;