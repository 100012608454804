export const staticData = {
    "studentQuestions": [
        {
            "title": {
                "tr": "Yeni öğrenen birisi olarak, Amerikalı eğitmenlerle nasıl konuşacağım?",
                "en": "Yeni öğrenen birisi olarak, Amerikalı eğitmenlerle nasıl konuşacağım?",
                "az": "Yeni öyrənən biri kimi mən amerikalı təlimçilərlə necə danışa bilərəm?",
            },
            "text": {
                "tr": "Eğitmenlerimiz sıfırdan başlayan, çekingen ve endişeli öğrencilerimize o kadar alıştılar ki! Bu yüzden yavaş yavaş ve tane tane başlıyoruz. Kitaplarınız da nispeten kolay oluyor. Ta ki siz alışana kadar. Alıştıktan sonra hızınız yavaş yavaş artıyor. Yüzme öğrenmek gibi düşünün. Suya girmeden yüzemezsiniz değil mi?",
                "en": "Eğitmenlerimiz sıfırdan başlayan, çekingen ve endişeli öğrencilerimize o kadar alıştılar ki! Bu yüzden yavaş yavaş ve tane tane başlıyoruz. Kitaplarınız da nispeten kolay oluyor. Ta ki siz alışana kadar. Alıştıktan sonra hızınız yavaş yavaş artıyor. Yüzme öğrenmek gibi düşünün. Suya girmeden yüzemezsiniz değil mi?",
                "az": "Təlimçilərimiz sıfırdan başlayan utancaq və narahat tələbələrimizə çox öyrəşiblər! Beləliklə, yavaş-yavaş və bir-bir başlayırıq. Kitablarınız da siz öyrəşənə qədər nisbətən asan olur. Buna öyrəşdikdən sonra sürətiniz tədricən artacaq. Bunu üzməyi öyrənmək kimi düşünün. Suya girmədən üzə bilməzsən, elə deyilmi?",
            }
        },
        {
            "title": {
                "tr": "10, 20 veya 30 dakika yeterli mi?",
                "en": "10, 20 veya 30 dakika yeterli mi?",
                "az": "10, 20 və ya 30 dəqiqə kifayətdirmi?"
            },
            "text": {
                "tr": "Günlük 20 dakika en çok tercih edilen programlarımızdan biri. Bu örnek üzerinden gidersek, ayda 440 dakika yapıyor (22 iş günü üzerinden hesaplarsak), bu da 7 saatten fazla ders süresine denk geliyor. Size verilen ödevleri ve diğer materyalleri düşündüğünüzde, oldukça yeterli bir süre. Fazlasına ihtiyaç duyarsanız her zaman dakikalarınızı arttırabilirsiniz.",
                "en": "Günlük 20 dakika en çok tercih edilen programlarımızdan biri. Bu örnek üzerinden gidersek, ayda 440 dakika yapıyor (22 iş günü üzerinden hesaplarsak), bu da 7 saatten fazla ders süresine denk geliyor. Size verilen ödevleri ve diğer materyalleri düşündüğünüzde, oldukça yeterli bir süre. Fazlasına ihtiyaç duyarsanız her zaman dakikalarınızı arttırabilirsiniz.",
                "az": "Gündə 20 dəqiqə ən çox seçdiyimiz proqramlarımızdan biridir. Bu nümunə ilə getsək, o, ayda 440 dəqiqə (22 iş günü ərzində hesablanır) edir ki, bu da 7 saatdan çox mühazirə vaxtı deməkdir. Sizə tapşırılan tapşırıqları və digər materialları nəzərdən keçirdiyiniz zaman bu, kifayət qədər yetərli vaxtdır. Daha çox ehtiyacınız varsa, hər zaman dəqiqələrinizi artıra bilərsiniz."
            }
        },
        {
            "title": {
                "tr": "Eğitmenleriniz gerçekten Amerikalı mı?",
                "en": "Eğitmenleriniz gerçekten Amerikalı mı?",
                "az": "Müəllimləriniz həqiqətən Amerikalıdır?"
            },
            "text": {
                "tr": "Eğitmenlerimiz Memphis/Tennessee'de bulunan ofisimizde çalışmaktadır. Eğitmenlerimiz ofisimizde çalışmaya başlamadan önce ciddi bir elemeden geçerler. Vatandaşlık durumu, İngilizce eğitim kapasitesi gibi durumlar ciddi bir şekilde değerlendirilir. Ana dili İngilizce olmayan bir eğitmenin işe alımı mümkün değildir. Bu yüzden gönlünüz rahat olabilir.",
                "en": "Eğitmenlerimiz Memphis/Tennessee'de bulunan ofisimizde çalışmaktadır. Eğitmenlerimiz ofisimizde çalışmaya başlamadan önce ciddi bir elemeden geçerler. Vatandaşlık durumu, İngilizce eğitim kapasitesi gibi durumlar ciddi bir şekilde değerlendirilir. Ana dili İngilizce olmayan bir eğitmenin işe alımı mümkün değildir. Bu yüzden gönlünüz rahat olabilir.",
                "az": "Təlimçilərimiz Memfis/Tennessi ştatındakı ofisimizdə işləyirlər. Onlar ofisimizdə işə başlamazdan əvvəl ciddi yoxlamadan keçirlər. Vətəndaşlıq statusu, ingilis dili təhsil qabiliyyəti kimi vəziyyətlər ciddi şəkildə qiymətləndirilir. Ana dili ingilis dili olmayan bir təlimçini işə götürməmiz mümkün deyil. Beləliklə, siz rahat ola bilərsiniz."
            }
        },
        {
            "title": {
                "tr": "Eğitmenlerle istediğim konuları konuşabiliyor muyum?",
                "en": "Eğitmenlerle istediğim konuları konuşabiliyor muyum?",
                "az": "Müəllimlərlə istədiyim mövzuları danışa bilərəmmi?"
            },
            "text": {
                "tr": "İngilizce durumunuza göre, konularınızı belirlerken mesleğinize göre eklemeler yapabiliyoruz. Tıp, mühendislik, iş dünyası, sağlık, güzellik, eğitim, inşaat, finans gibi konular belirleyip, programınıza ekliyoruz. Konuları istediğinizde değiştirebilirsiniz. Online İngilizce kursu olarak her şey size özel.",
                "en": "İngilizce durumunuza göre, konularınızı belirlerken mesleğinize göre eklemeler yapabiliyoruz. Tıp, mühendislik, iş dünyası, sağlık, güzellik, eğitim, inşaat, finans gibi konular belirleyip, programınıza ekliyoruz. Konuları istediğinizde değiştirebilirsiniz. Online İngilizce kursu olarak her şey size özel.",
                "az": "İngilis dili səviyyənizdən asılı olaraq mövzularınızı müəyyənləşdirərkən peşənizə uyğun əlavələr edə bilərik. Tibb, mühəndislik, iş dünyası, sağlamlıq, təhsil, inşaat, maliyyə kimi mövzuları müəyyənləşdirib proqramınıza əlavə edirik. İstədiyiniz zaman mövzuları dəyişə bilərsiniz. Onlayn İngilis dili kursu olaraq hər şey sizin üçün hazırlanmışdır."
            }
        },
        {
            "title": {
                "tr": "Yoğunluktan derse katılamazsam ne oluyor?",
                "en": "Yoğunluktan derse katılamazsam ne oluyor?",
                "az": "Dərsdə intensivliyə görə iştirak edə bilməsəm nə olar?"
            },
            "text": {
                "tr": "Hesabınıza belirli sayıda ders erteleme hakkı tanınıyor. Ders başlamadan iki saat öncesine kadar bu haklarınızı kullanarak dersinizi erteleyebilirsiniz. Haklarınız bittiğinde ise makul bir ücret karşılığında erteleme hakkı satın alabilirsiniz. Fakat yine de tercihimiz saatlerinizi doğru ayarlayıp, derslerinize devam etmeniz.",
                "en": "Hesabınıza belirli sayıda ders erteleme hakkı tanınıyor. Ders başlamadan iki saat öncesine kadar bu haklarınızı kullanarak dersinizi erteleyebilirsiniz. Haklarınız bittiğinde ise makul bir ücret karşılığında erteleme hakkı satın alabilirsiniz. Fakat yine de tercihimiz saatlerinizi doğru ayarlayıp, derslerinize devam etmeniz.",
                "az": "Hesabınızın müəyyən sayda dərsləri təxirə salmaq hüququ var. Bu hüquqlardan istifadə edərək dərsinizi dərsin başlamasına iki saat qalmışa qədər təxirə sala bilərsiniz. Hüquqlarınızın müddəti başa çatdıqda, məqbul bir ödəniş qarşılığında təxirə salma hüququnu əldə edə bilərsiniz. Lakin bizim üstünlük verdiyimiz saatlarınızı düzgün təyin etmək və dərslərinizə davam etməkdir."
            }
        },
        {
            "title": {
                "tr": "İlerleyişimi nasıl takip edebilirim?",
                "en": "İlerleyişimi nasıl takip edebilirim?",
                "az": "İnkişafımı necə izləyə bilərəm?"
            },
            "text": {
                "tr": "Derslere başladığınız zaman, belirlediğiniz kullanıcı adı ve şifre ile öğrenci panelinize giriş yapabileceksiniz. Bu panelde, eğitim içeriklerinizle birlikte ilerleme raporunuz da olacak. Burada eğitmeninizin notlarını, ilerlemenizi ve diğer içeriklerinizi görebileceksiniz.",
                "en": "Derslere başladığınız zaman, belirlediğiniz kullanıcı adı ve şifre ile öğrenci panelinize giriş yapabileceksiniz. Bu panelde, eğitim içeriklerinizle birlikte ilerleme raporunuz da olacak. Burada eğitmeninizin notlarını, ilerlemenizi ve diğer içeriklerinizi görebileceksiniz.",
                "az": "Dərslərə başladığınız zaman təyin etdiyiniz istifadəçi adı və şifrə ilə tələbə panelinizə daxil ola biləcəksiniz. Bu paneldə təlim məzmununuzla birlikdə inkişaf hesabatınız olacaq. Burada siz müəlliminizin qiymətlərini, gedişatınızı və digər məzmununu görə biləcəksiniz.",
            }
        },
        {
            "title": {
                "tr": "%100 İade garantisi nedir?",
                "en": "%100 İade garantisi nedir?",
                "az": "100% Geri Ödəniş Zəmanəti nədir?"
            },
            "text": {
                "tr": "%100 iade garantisi 6 ve 9+3 aylık paket alan öğrencilerimize sunmuş olduğumuz bir haktır. Derslerine en az %90 oranında devamlılık gösteren öğrencilerimiz eğitimlerinden memnun kalmadıklarında iade isteyebilir ve ödemelerinin tamamını geri iade olarak alabilirler.",
                "en": "%100 iade garantisi 6 ve 9+3 aylık paket alan öğrencilerimize sunmuş olduğumuz bir haktır. Derslerine en az %90 oranında devamlılık gösteren öğrencilerimiz eğitimlerinden memnun kalmadıklarında iade isteyebilir ve ödemelerinin tamamını geri iade olarak alabilirler.",
                "az": "100% geri ödəniş zəmanəti 6 və 12 aylıq paket alan tələbələrimizə təqdim etdiyimiz hüquqdur. Kurslarında ən azı 90% davamiyyət göstərən tələbələr, təhsillərindən razı qalmadıqları təqdirdə pulun geri qaytarılmasını tələb edə və ödənişlərini geri ala bilərlər."
            }
        }
    ],
    "studentComments": [
        {
            "name": "Dr. Hidayət Memmedzadə",
            "job": {
                "tr": "Doktor",
                "en": "Doctor",
                "az": "Həkim"
            },
            "youtubeId": "JlLUHrp5d0E"
        }
    ],
    "howToLearnEnglish": {
        "youtubeId": "ZJQMc9y6WBc"
    }
}