import React from 'react';
import {
  Calendar as BigCalendar,
  momentLocalizer
} from "react-big-calendar";
import moment from "moment";
import 'moment/locale/az';
import { useSelector } from 'react-redux';

const Calendar = (props) => {
  const language = useSelector(state => state.localizationStore.language);
  //dil ingilizce seçildiğinde takvimin dili ingilizce olması, azerbaycan dili seçildiğinde de azerbaycan dili olmasını sağlayan fonksiyon
  language === 'en' ? moment.locale('en') : moment.locale('az')
  const localizer = momentLocalizer(moment);

  return <BigCalendar {...props} localizer={localizer} />;
}

export default Calendar;