import React, {useRef, useState} from 'react'
import { Danger_Sing } from '../../../../assets/svg'
import { useLocalization } from '../../../../hooks/useLocalization'
import useAuth from "../../../../hooks/useAuth";
import { getTimeZone, addTimeZone } from '../../../../utils';
import {BookModal} from "../../../../components/Modals";

const MissedCallInformation = ({ data, showButton, action }) => {

    const strings = useLocalization();
    const { member } = useAuth();
    const timezone = getTimeZone(member.Timezone);

    const bookModalRef = useRef();

    const [timeoutState, setTimeoutState] = useState(true);

    const toggleModal = () => {
        bookModalRef.current.classList.toggle("hidden");

        if(!bookModalRef.current.classList.contains("hidden")){
            setTimeout(() => setTimeoutState(false), 4000);
        } else {
            setTimeoutState(timeoutState => true);
        }
    }

    const getMissedCallWarning = (text) => {
        let warningOne = "Telafi dersinizin sonucu geldikten sonra günün ders kitabını yeniden kontrol ediniz.";
        let warningTwo = "Günün ders sonucu geldikten sonra telafi ders kitabını yeniden kontrol ediniz.";

        if(text === warningOne)
            return strings.speaking_lesson.no_answer.missed_call_warning_one;
        else if(text === warningTwo)
            return strings.speaking_lesson.no_answer.missed_call_warning_two;
        else
            return "";
    }

    return (
        <>
            <div className="transparent-section mt-5 text-sm">
                <img
                    className="mx-auto"
                    src={Danger_Sing}
                    width="64"
                    height="64"
                    alt=""
                />
                <p className="mt-3 max-w-lg mx-auto">{strings.speaking_lesson.no_answer.missed_call_content
                    .replace('#date#', data?.missedDate)
                    .replace('#new_date#', data?.lessonDate)
                    .replace('#time#', addTimeZone(data?.lessonTime, timezone))
                    .replace('#teacher#', data?.teacherName)}
                </p>

                {data?.bookNote.length > 0 && (
                    <p className="mt-3 max-w-lg mx-auto">
                        {getMissedCallWarning(data.bookNote)}
                    </p>
                )}

                {(showButton && data?.book)&& (
                    <button
                        className="button w-[95%] xs:w-[330px] button button-mt primary-button-outline mb-2 mt-3 mx-auto"
                        onClick={toggleModal}
                    >
                        {strings.speaking_lesson.daily_lesson.view_missed_lesson_book}
                    </button>
                )}
            </div>

            {(data?.book) && (
                <BookModal
                    ref={bookModalRef}
                    src={data.book.contents[0].content}
                    toggleModal={toggleModal}
                    timeoutState={timeoutState}
                />
            )}
        </>
    )
}

export default MissedCallInformation