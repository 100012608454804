import {decodeToken, encodeToken, getOS} from "../../utils";
import {useEffect, useState} from "react";
import {OperatingSystems, TermStatusTypes} from "../../components/Constants";
import {useNavigate, useParams} from "react-router-dom";
import {url} from "../../routes/utility";
import {memberLogin} from "../../store/MemberSlice";
import {AutoLoginService, VerifyMagicLink} from "../../services/Auth/AuthService";
import {useDispatch} from "react-redux";
import { setTermId } from "../../store/TermSlice";

const Launch = () => {

    const { base64 } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [magicLinkJwt, setMagicLinkJwt] = useState("");

    const navigateByOs = () => {
        const os = getOS();

        switch (os) {
            case OperatingSystems.IOS:
                window.open("https://apps.apple.com/us/app/az%C9%99r-i-ngilisc%C9%99/id1215718563", "_blank");
                break;
            case OperatingSystems.ANDROID:
                window.open("https://play.google.com/store/apps/details?id=com.konusarakogren.m.konusarakogrenmobil_az", "_blank");
                break;
            default:
                break;
        }

        navigate(url("dashboard"));
    }


    useEffect(() => {
        VerifyMagicLink(base64)
            .then(result => {
                let resultContent = JSON.parse(result);

                if(resultContent.status === 200){
                    setMagicLinkJwt(magicLinkJwt =>  resultContent.content);
                } else {
                    navigate(url("auth.login"));
                }
            });
    }, []);

    useEffect(() => {
        let abortController = new AbortController();

        if(magicLinkJwt.length > 0){

            AutoLoginService(magicLinkJwt).then(result => {
                if (result.statusCode !== 200) {
                    navigate(url("auth.login"));
                } else {
                    const member = decodeToken(magicLinkJwt);

                    /*
                        Kullanıcı giriş yaparken ilk aktif dönemi set ediliyor.
                        Bu sayede çıkış yaparken pasif/onay bekleyen/gelecek bir dönem seçtiyse kafa karışıklığı önlenmiş oluyor.
                    */
                    const activeTerm = member?.Terms && JSON.parse(member?.Terms).find(term => term.Status === TermStatusTypes.Active);
                    activeTerm && dispatch(setTermId(activeTerm.TermId));
                    activeTerm && localStorage.setItem("selectedTerm", JSON.stringify(activeTerm.TermId));

                    let terms = '';

                    if (member.hasOwnProperty("Terms")) {
                        terms = encodeToken(member.Terms);
                        delete member["Terms"];
                    }

                    const token = encodeToken(member);

                    //set member login
                    dispatch(memberLogin({ token, terms }));

                    navigateByOs();
                }
            });
        }

        return () => {
            abortController.abort();
        };

    }, [magicLinkJwt]);
}

export default Launch;