import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { GetCaptionByRecordName } from "../services/SpeakingLesson/SpeakingLessonService";
import { convertTimeFormatToSeconds } from "../utils";
import useAnalytics from "../hooks/useAnalytics";

const AudioSubtitle = ({src, event, error = ''}) =>{

    const analytics = useAnalytics();

    const selectedTermId = useSelector(state => state.termStore.termId);

    const lessonRecordUrl = process.env.REACT_APP_SOUND_BASE_URL;
    const isLessonAudio = src && src.includes(lessonRecordUrl);
    const audioRef = useRef(null);

    const [captions, setCaptions] = useState([]);
    const [currentCaption, setCurrentCaption] = useState('');
    const [showSubtitle, setShowSubtitle] = useState(false);
    const [recordName, setRecordName] = useState("");
    const [isPlayed, setIsPlayed] = useState(false);

    //Servisten 
    const getCaptionByRecordName = async (termId, recordName) => {
        const result = await GetCaptionByRecordName(termId, recordName);

        if(result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setCaptions(captions => resultContent);
        }
    }

    useEffect(() => {
        // "rcd_" ile başlayan kısmı almak için regex pattern'i oluşturuluyor.
        const regex = /rcd_([^\s/]+\.mp3)/;

        // regex desenine uyan kısım alınıyor.
        const match = src?.match(regex);

        // match dizisinden istenilen değer alınıyor.
        setRecordName(recordName => match[0]);
    }, []);

    useEffect(() => {
        if(recordName.length > 0 && selectedTermId && isPlayed){
            getCaptionByRecordName(selectedTermId, recordName);
        }
    }, [recordName, selectedTermId, isPlayed]);

    useEffect(() => {
        if(captions.length > 0){
            setShowSubtitle(showSubtitle => true);
        }
    }, [captions]);

    useEffect(() => {
        const audio = audioRef.current;

        const updateCaption = () => {
            const currentTime = audio.currentTime;

            const caption = captions.find(
                (c) => currentTime >= convertTimeFormatToSeconds(c.start) && currentTime <= convertTimeFormatToSeconds(c.end)
            );

            setCurrentCaption(caption ? caption.text : '');
        };

        audio.addEventListener('timeupdate', updateCaption);

        return () => {
            audio.removeEventListener('timeupdate', updateCaption);
        };
    }, [captions]);

    return (
        <>
            <div className="flex items-center">
                <audio
                    ref={audioRef}
                    className="w-full mt-1"
                    controls
                    controlsList={`${!isLessonAudio ? 'nodownload' : ''} noplaybackrate`}
                    onPlay={() => {
                        setIsPlayed(isPlayed => true);
                        analytics.useAnalytics(event);
                    }}
                >
                    <source src={src} type="audio/mp3" />
                    {error}
                </audio>

                {captions.length > 0 && (
                    <span
                        className="material-symbols-outlined text-black mt-1 cursor-pointer rounded-full hover:bg-box p-[5px] -translate-x-2"
                        onClick={() => setShowSubtitle(showSubtitle => !showSubtitle)}
                    >
                      {showSubtitle ? "subtitles" : "subtitles_off"}
                    </span>
                )}
            </div>
            {showSubtitle && (
                <div className="min-h-[24px] -translate-y-2">
                    <p>{currentCaption ?? ""}</p>
                </div>
            )}
        </>
    );
}

export default AudioSubtitle