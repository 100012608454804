import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Completed } from "../../../../assets/svg";
import { Loading } from "../../../../components";
import { decodeToken, getFormattedTime } from "../../../../utils";
import { useLocalization } from "../../../../hooks/useLocalization";
import { SendQuizReport } from "../../../../services/Quiz/QuizService";
import QuizReportQuestions from "./QuizReportQuestions";
import classNames from "classnames";
import { AddMemberBookHintQuiz } from "../../../../services/SpeakingLesson/SpeakingLessonService";
import useAnalytics from "../../../../hooks/useAnalytics";
import {ActionTypes, EventLogs} from "../../../../components/Constants";
import useAction from "../../../../hooks/useAction";
import { CreateMemberResourcesResult } from "../../../../services/Resources/ResourcesService";
import {useLocation, useNavigate} from "react-router-dom";
import {url} from "../../../../routes/utility";

const QuizReport = (props) => {

    const { startTime, quizReport, solveAgain, quizQuestions, setIsStartQuiz, source = "quiz" } = props;

    const strings = useLocalization();
    const analytics = useAnalytics();
    const action = useAction();
    const navigate = useNavigate();
    const location = useLocation();

    const dateFromState = location?.state?.date || null;
    const selectedTermId = useSelector(state => state.termStore.termId);

    const token = useSelector(state => state.memberStore.token);
    const member = token && decodeToken(token);
    const memberId = member?.MemberId;
    const [loading, setLoading] = useState(true);

    const [rightAnswersCount, setRightAnswersCount] = useState(-1);
    const [wrongAnswersCount, setWrongAnswersCount] = useState(-1);
    const [emptyAnswersCount, setEmptyAnswersCount] = useState(-1);
    const [endTime, setEndTime] = useState(null);
    const [showResult, setShowResult] = useState(false);

    let mistakeQuestions = []

    const sendQuizReport = async (model) => {
        await SendQuizReport(model);
        setLoading(false);
    }

    const addMemberBookHintQuiz = async (model) => {
        await AddMemberBookHintQuiz(model);
        setLoading(false);
    }

    const createMemberResourcesResult = async (model, grammarContent) => {
        await CreateMemberResourcesResult(model);
        setLoading(false);
        analytics.useAnalytics(EventLogs.RESOURCES_COMPLETE_TEST, { 'kaynak': grammarContent });
    }

    const handleShowResultButton = () => {
        setShowResult(showResult => true);

        //Sonuçları Görüntüle butonuna basıldığında log atılıyor.
        if(source === "quiz")
            analytics.useAnalytics(
                EventLogs.LESSON_RESULT_SCREEN_SHOW_RESULTS,
                { quiz_name: quizReport.bookName }
            );

        if(source === "dailysentence")
            analytics.useAnalytics(
                EventLogs.DAILY_PATTERN_SHOW_RESULTS
            );
    }

    const handleReturnVideoButton = () => {
        setIsStartQuiz(isStartQuiz => false);
    };

    const handleRestartButton = () => {
        solveAgain();

        //"Testi Tekrar Başlat" butonuna basıldığında log atılıyor.
        if(source === "quiz")
            analytics.useAnalytics(
                EventLogs.LESSON_RESULT_SCREEN_RESTART,
                { quiz_name: quizReport.bookName }
            );

        if(source === "dailysentence")
            analytics.useAnalytics(
                EventLogs.DAILY_PATTERN_QUIZ_RESTART,
            );
    }

    const handleQuitButton = () => {
        if(!dateFromState) {
            window.location.href = "/speakinglesson/quiz"
        } else {
            localStorage.setItem("selectedDate", dateFromState);
            navigate(url("speakinglesson.dailylesson"));
        }
    }

    // QuizReport değiştiğinde doğru, yanlış ve boş cevap sayılarını hesaplar
    useEffect(() => {
        if (Object.keys(quizReport).length > 0) {
            let rightAnswers = 0;
            let wrongAnswers = 0;
            let emptyAnswers = 0;
            quizReport.questions.map((item) => {
                if (item.userAnswer === -1) {
                    emptyAnswers++;
                } else if (item.userAnswer === item.rightAnswer) {
                    rightAnswers++;
                } else {
                    mistakeQuestions.push(item.id)
                    wrongAnswers++;
                }
            });
            setRightAnswersCount(rightAnswers);
            setWrongAnswersCount(wrongAnswers);
            setEmptyAnswersCount(emptyAnswers);
            let model = {
                memberId: memberId,
                ...(source === "quiz" && { bookQuizId: quizReport.quizId }),
                ...(source === "dailysentence" && { bookHintQuizId: quizReport.quizId }),
                ...(source === "resources" && { resourceQuizId: quizReport.quizId }),
                totalQuestion: quizReport.questions.length,
                true: rightAnswers,
                false: wrongAnswers,
                mistakeQuestions: mistakeQuestions
            }
            source === "quiz" && sendQuizReport(model);
            source === "dailysentence" && addMemberBookHintQuiz(model);
            source === "resources" && createMemberResourcesResult(model, quizReport.bookName);
        }
    }, [quizReport]);

    useEffect(() => {
        setEndTime(endTime => getFormattedTime(new Date()));

        if(source === "quiz") {
            action.useAction(selectedTermId, quizReport.bookUnitId, ActionTypes.KNOWLEDGEABLE)
                .then(r => {})
                .catch(e => {})
        }
    }, []);

    useEffect(() => {
        if(wrongAnswersCount !== -1 && rightAnswersCount !== -1){
            //Rapor görüntülendiğinde log atılıyor.
            if(source === "quiz")
                analytics.useAnalytics(
                    EventLogs.LESSON_RESULT_SCREEN,
                    { quiz_name: quizReport.bookName }
                );

            if(source === "dailysentence")
                analytics.useAnalytics(
                    EventLogs.DAILY_PATTERN_QUIZ_RESULT,
                    { dogru_cevap: rightAnswersCount, yanlis_cevap: wrongAnswersCount}
                );
        }

    }, [rightAnswersCount, wrongAnswersCount]);

    return (
        <div className={classNames("mt-5", {
            "main-content": source === "quiz"
        })}>
            <div className={classNames("", {
                "left-page": source === "quiz"
            })}>
                {
                    loading && <Loading />
                }
                {
                    !loading && (
                        <>
                            <div className="mb-5 gray-section bg-title-bg px-[10px] xs:px-[10px] text-start">
                                <div className="flex justify-center items-center gap-2">
                                    <img src={Completed} height={30} width={30} />
                                    <span className="font-bold text-base">
                                        {strings.speaking_lesson.quiz.quiz_completed}
                                    </span>
                                </div>
                            </div>
                            <div className="transparent-section mt-5 text-sm text-start pb-5 relative">
                                {source === "quiz" && (
                                    <div className="absolute top-2 right-2">
                                        <span
                                            onClick={handleQuitButton}
                                            className="material-symbols-outlined cursor-pointer"
                                        >
                                            close
                                        </span>
                                    </div>
                                )}
                                <h5 className="text-secondary text-[17px]">{quizReport.bookName}</h5>
                                <p className="text-center mt-2">{strings.speaking_lesson.quiz.report_description}</p>

                                <div className="flex flex-col gap-3 mt-4">
                                    <div className="flex w-full">
                                        <span className="w-1/2 text-end font-bold">{strings.speaking_lesson.quiz.start_time}: </span>
                                        <span className="w-1/2 pl-2"> {startTime}</span>
                                    </div>
                                    <div className="flex w-full">
                                        <span className="w-1/2 text-end font-bold">{strings.speaking_lesson.quiz.end_time}: </span>
                                        <span className="w-1/2 pl-2"> {endTime}</span>
                                    </div>
                                    <div className="flex w-full">
                                        <span className="w-1/2 text-end font-bold">{strings.speaking_lesson.quiz.total_question}: </span>
                                        <span className="w-1/2 pl-2">{quizReport.questions.length}</span>
                                    </div>
                                    <div className="flex w-full">
                                        <span className="w-1/2 text-end font-bold text-green">{strings.speaking_lesson.quiz.right_answer}: </span>
                                        <span className="w-1/2 pl-2">{rightAnswersCount}</span>
                                    </div>
                                    <div className="flex w-full">
                                        <span className="w-1/2 text-end font-bold text-red">{strings.speaking_lesson.quiz.wrong_answer}: </span>
                                        <span className="w-1/2 pl-2">{wrongAnswersCount}</span>
                                    </div>
                                    <div className="flex w-full">
                                        <span className="w-1/2 text-end font-bold text-[#999]">{strings.speaking_lesson.quiz.empty_answer}: </span>
                                        <span className="w-1/2 pl-2">{emptyAnswersCount}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-center gap-4">
                                <button
                                    type="button"
                                    className="button bg-primary text-white hover:bg-secondary mt-10 max-w-[330px]"
                                    onClick={handleRestartButton}
                                >
                                    {strings.speaking_lesson.quiz.solve_again}
                                </button>
                                {!showResult && (
                                    <button
                                        type="button"
                                        className={classNames("button button-mt primary-button-outline w-full mt-0 max-w-[330px]", {
                                            "mb-5": source !== "resources",
                                            "mb-0": source === "resources"
                                        })}
                                        onClick={handleShowResultButton}
                                    >
                                        {strings.speaking_lesson.quiz.show_result}
                                    </button>
                                )}
                                {/* Eğer tüm kaynaklardan geliyorsa o sayfadaki ders videosuna geri dönmek için bu buton aktif edilir */}
                                {source === "resources" && (
                                    <button
                                        type="button"
                                        className="button button-mt primary-button-outline w-full mt-0 mb-5 max-w-[330px]"
                                        onClick={handleReturnVideoButton}
                                    >
                                        {strings.speaking_lesson.quiz.return_video}
                                    </button>
                                )}
                            </div>

                            {showResult && (
                                <QuizReportQuestions
                                    quizQuestions={quizQuestions}
                                    quizReport={quizReport}
                                />
                            )}
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default QuizReport;