import React, {useEffect, useRef, useState} from "react";
import {GetVideoDescription} from "../../../services/VideoPractice/VideoPracticeService";
import {GetAiUseCaseById} from "../../../services/AIUseCase/AIUseCaseService";
import {useNavigate} from "react-router-dom";
import useAiRouting from "../../../hooks/useAiRouting";
import {AIKeys, EventLogs} from "../../../components/Constants";
import {Button, Loading} from "../../../components";
import {AIChat} from "../../SpeakingLesson/components";
import {url} from "../../../routes/utility";
import {useLocalization} from "../../../hooks/useLocalization";
import useAnalytics from "../../../hooks/useAnalytics";
import {sendEventByDefaultParameters} from "../../../utils";

const GrammarAI = (props) => {

    const { videoId, setStartTest, grammarTitle } = props;
    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const useCaseId = useAiRouting(AIKeys.VIDEO_GRAMMAR);
    const videoInfo = JSON.parse(localStorage.getItem("videoInfo")) || false;

    const audioPlayerRef = useRef();

    const [loading,setLoading] = useState(true);
    const [selectedPractice, setSelectedPractice] = useState({});
    const [videoDescription, setVideoDescription] = useState("");

    // base event fonksiyonu
    const sendEvent = (event, parameters) => {
        sendEventByDefaultParameters(
            event,
            analytics,
            videoInfo?.videoTags,
            {
                video_name: videoInfo?.videoName,
                gramer: grammarTitle,
                ...parameters
            },
            { seviye: '', ilgi_alanlari: '' }
        );
    }

    const handleBackButton = () => {
        setStartTest(false);
        sendEvent(EventLogs.VIDEO_EXERCISE_GRAMMAR_TEST_BACK_CLICK); //LOGGED
    }

    const handleContinueButton = () => {
        navigate(url("videopractice.videoflow.ai", {videoId: videoId}));
        sendEvent(EventLogs.VIDEO_EXERCISE_GRAMMAR_TEST_CONTINUE_CLICK); //LOGGED
    }

    useEffect(() => {
        // Video açıklaması getiriliyor
        GetVideoDescription(videoId)
            .then(result => {
                if(result.status === 200) {
                    setVideoDescription(result.content);
                }
            })
            .catch();
    }, [])

    useEffect(() => {
        if(videoDescription.length > 0) {
            // AI Use Case bilgileri getiriliyor
            GetAiUseCaseById(useCaseId)
                .then(result => {
                    if(result.status === 200) {
                        let resultContent = JSON.parse(result.content);
                        resultContent.aiUseCasePrompt =
                            resultContent.aiUseCasePrompt
                                .replace("#videoDescription#", videoDescription);

                        setSelectedPractice(resultContent);
                    }

                    setLoading(false);
                })
                .catch()
        }
    }, [videoDescription])

    return (
        <>
            {loading &&
                <div className="transparent-section mt-10">
                    <Loading classnames="mb-4"/>
                </div>
            }

            {!loading &&
                <>
                    <div className="mt-10">
                        <AIChat
                            ref={audioPlayerRef}
                            selectedPractice={selectedPractice}
                            isFirstMessageBlue={true}
                            backButtonAction={() => setStartTest(false)}
                            logParameters={{
                                ...videoInfo.videoTags,
                                video_name: videoInfo?.videoName,
                                gramer: grammarTitle
                            }}
                        />
                    </div>

                    <div className="flex gap-8 my-7 justify-center">
                        <Button
                            type="button"
                            classnames="button primary-button-outline max-w-[150px]"
                            action={handleBackButton}
                            text={strings.pagination.previous}
                        />
                        <Button
                            type="button"
                            classnames="button primary-button max-w-[150px]"
                            action={handleContinueButton}
                            text={strings.speaking_lesson.quiz.continue}
                        />
                    </div>
                </>
            }
        </>
    )
}

export default GrammarAI;