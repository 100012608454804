import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { BookModal } from "../../../../components/Modals";
import { useLocalization } from "../../../../hooks/useLocalization";
import useAnalytics from "../../../../hooks/useAnalytics";
import {EventLogs} from "../../../../components/Constants";

const QuizList = (props) => {

    const { books, setSelectedQuiz, sendToEndPoint } = props;

    const strings = useLocalization();
    const analytics = useAnalytics();

    const modalRef = useRef();

    const [startIdx, setStartIdx] = useState(0);
    const [selectedBookUrl, setSelectedBookUrl] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [initialStartIdx, setInitialStartIdx] = useState(0)

    const ITEMS_PER_PAGE = 9;

    const toggleModal = () => {
        modalRef.current.classList.toggle("hidden");
        if (!modalRef.current.classList.contains("hidden")) {
            setTimeout(() => setShowModal(false), 4000);
        } else {
            setShowModal(true);
        }
    };

    // Yukarı ok tıklanırsa çalışacak işlev
    const handleUpClick = () => {
        if (startIdx > 0) {
            setStartIdx(startIdx - ITEMS_PER_PAGE);
        }
    };

    // Aşağı ok tıklanırsa çalışacak işlev
    const handleDownClick = () => {
        const maxStartIdx = Math.max(0, books.length - ITEMS_PER_PAGE);
        if (startIdx < maxStartIdx) {
            setStartIdx(startIdx + ITEMS_PER_PAGE);
        }
    };

    // Görüntülenen öğeleri hesaplar ve eksik öğeleri doldurur
    const calculateShowItems = () => {
        let displayedItems = books.slice(startIdx, startIdx + ITEMS_PER_PAGE);

        if (displayedItems.length < ITEMS_PER_PAGE) {
            const missingItemsCount = ITEMS_PER_PAGE - displayedItems.length;
            let missingStartIdx = startIdx - missingItemsCount;

            if (missingStartIdx < 0) {
                missingStartIdx = 0;
            }

            if (missingStartIdx + ITEMS_PER_PAGE > books.length) {
                missingStartIdx = Math.max(0, books.length - ITEMS_PER_PAGE);
            }

            displayedItems = books.slice(missingStartIdx, missingStartIdx + ITEMS_PER_PAGE);
        }

        return displayedItems;
    };
    
    // İlk yükleme veya ITEMS_PER_PAGE değiştiğinde çalışır
    useEffect(() => {
        // Başlangıçta gösterilecek öğenin indeksi

        // Seçilen öğenin indeksini bulur
        let selectedItemIndex = books.findIndex((book) => book.id === initialStartIdx);

        if (selectedItemIndex !== -1) {
            // Yeni başlangıç indeksi hesaplanır
            const newStartIdx = Math.max(0, selectedItemIndex - Math.floor(ITEMS_PER_PAGE / 2));
            setStartIdx(newStartIdx);
        } else {
            setStartIdx(0);
        }
    }, [books, ITEMS_PER_PAGE, initialStartIdx]);

    useEffect(() => {
        const tmpInitialStartIdx = books.find(book => book.isLastLesson)?.id || 0
        setInitialStartIdx(initialStartIdx => tmpInitialStartIdx)
    }, [books])

    useEffect(() => {
        //Quiz listesinin görüntülendiği log atılıyor.
        analytics.useAnalytics(EventLogs.LESSON_QUIZ_LIST_VIEWED);
    }, [])

    // Görüntülenecek öğeleri hesaplar
    const showItems = calculateShowItems();
    // Yukarı Çık butonunun görünüp görünmeyeceğine karar verir. Eğer ilk öğe görüntüleniyorsa yukarı çık butonu görünmez.
    let showUpButton = showItems.includes(books[0]) || false;
    // Aşağıya In butonunun görünüp görünmeyeceğine karar verir. Eğer son öğe görüntüleniyorsa yukarı çık butonu görünmez.
    let showDownButton = showItems.includes(books[books.length - 1]) || false;

    return (
        <>
            <div className="mt-4">
                <div className="flex justify-center">
                     <span
                        onClick={() => { !showUpButton && handleUpClick() }}
                        className={classNames("material-symbols-outlined cursor-pointer", {
                            "opacity-0 cursor-default": showUpButton
                        })}
                     >
                        expand_less
                    </span>
                </div>
                <div className="flex flex-col items-center">
                    {showItems.map((item, index) => (
                        <div
                            key={index}
                            className="w-full"
                        >
                            {index !== 0 && <hr className="my-2 w-full border-transparency-border"/>}
                            <div className="p-2 w-full">
                                <div className="px-4 flex justify-between">
                                    <div className="flex items-center gap-2">
                                        <p
                                            className={classNames("", {
                                                "font-bold" : item.id === initialStartIdx
                                            })}
                                        >
                                            {item.bookName}
                                        </p>
                                        {item.id === initialStartIdx && (
                                            <span className="rounded-[10px] py-0.5 px-2 bg-secondary text-white text-[12px] block max-470:hidden md-810:hidden">{strings.speaking_lesson.quiz.last_lesson}</span>
                                        )}
                                    </div>
                                    <div className="flex gap-2">
                                        {item.isCompleted && (
                                            <div className="flex items-center">
                                                <span className="material-symbols-outlined text-primary">check</span>
                                            </div>
                                        )}
                                        <div className="flex items-center justify-center group relative">
                                            <span
                                                className="material-symbols-outlined text-base-text cursor-pointer"
                                                onClick={() => {
                                                    //Kitap görüntülendiğinde log atılıyor.
                                                    analytics.useAnalytics(EventLogs.LESSON_QUIZ_VIEW_BOOK);
                                                    setSelectedBookUrl(process.env.REACT_APP_BOOK_BASE_URL + item.bookLink);
                                                    toggleModal();
                                                }}
                                            >
                                              article
                                            </span>
                                            <div className="tooltip-top !-top-[35px]">{strings.speaking_lesson.quiz.view_book}</div>
                                        </div>
                                        <div className="flex items-center">
                                            <button
                                                type="button"
                                                className="flex h-6 items-center font-bold text-center rounded-[10px] leading-[28px] px-2 transition-colors duration-300 cursor-pointer w-full primary-button"
                                                onClick={() => {
                                                    setSelectedQuiz(item);
                                                    sendToEndPoint(item);
                                                }}
                                            >
                                                <span className="material-symbols-outlined text-[22px]">play_arrow</span>
                                                <span className="mr-[6px] hidden sm:block">{strings.speaking_lesson.quiz.start}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-center">
                    <span
                        onClick={() => { !showDownButton && handleDownClick() }}
                        className={classNames("material-symbols-outlined cursor-pointer", {
                            "opacity-0 cursor-default": showDownButton
                        })}
                    >
                        expand_more
                    </span>
                </div>
            </div>

            <BookModal ref={modalRef} src={selectedBookUrl} toggleModal={toggleModal} timeoutState={showModal}/>
        </>
    );
};

export default QuizList;