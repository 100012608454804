import password_eye from "../../assets/svg/password-eye.svg"
import password_eye_slash from "../../assets/svg/password-eye-slash.svg"

import check from "../../assets/svg/check.svg"
import logo from "../../assets/svg/logo.svg"

import button_right_arrow from "../../assets/svg/buttons/back_right.svg"

import onboarding_1_1 from "../../assets/svg/onboarding/first/1.svg"
import onboarding_1_2 from "../../assets/svg/onboarding/first/2.svg"
import onboarding_1_3 from "../../assets/svg/onboarding/first/3.svg"
import onboarding_1_4 from "../../assets/svg/onboarding/first/4.svg"

import onboarding_2_1 from "../../assets/svg/onboarding/second/1.svg"
import onboarding_2_3 from "../../assets/svg/onboarding/second/3.svg"
import onboarding_2_8 from "../../assets/svg/onboarding/second/8.svg"

import onboarding_3_1 from "../../assets/svg/onboarding/third/1.svg"
import onboarding_3_2 from "../../assets/svg/onboarding/third/2.svg"
import onboarding_3_3 from "../../assets/svg/onboarding/third/3.svg"
import onboarding_3_4 from "../../assets/svg/onboarding/third/4.svg"
import onboarding_3_5 from "../../assets/svg/onboarding/third/5.svg"
import onboarding_3_6 from "../../assets/svg/onboarding/third/6.svg"
import onboarding_3_7 from "../../assets/svg/onboarding/third/7.svg"
import onboarding_3_8 from "../../assets/svg/onboarding/third/8.svg"
import onboarding_3_9 from "../../assets/svg/onboarding/third/9.svg"
import onboarding_3_10 from "../../assets/svg/onboarding/third/10.svg"
import onboarding_3_11 from "../../assets/svg/onboarding/third/11.svg"
import onboarding_3_12 from "../../assets/svg/onboarding/third/12.svg"

import onboarding_rocket from "../../assets/svg/onboarding/success/rocket.svg"
import onboarding_other from "../../assets/svg/onboarding/question.svg"

import market from "../../assets/svg/menu/market.svg"
import market_active from "../../assets/svg/menu/market_active.svg"
import menu_family from "../../assets/svg/menu/menu_family.svg"
import settings from "../../assets/svg/menu/settings.svg"
import support from "../../assets/svg/menu/support.svg"
import support_active from "../../assets/svg/menu/support_active.svg"
import teachers from "../../assets/svg/menu/teachers.svg"
import teachers_active from "../../assets/svg/menu/teachers_active.svg"
import home from "../../assets/svg/menu/home.svg"
import home_active from "../../assets/svg/menu/home_active.svg"
import microphone from "../../assets/svg/menu/microphone.svg"
import microphone_active from "../../assets/svg/menu/microphone_active.svg"
import chevron_right from "../../assets/svg/menu/Chevron_Right.svg"
import logout from "../../assets/svg/menu/logout.svg"

import month_1 from "../../assets/svg/market/month1.svg"
import month_3 from "../../assets/svg/market/month3.svg"
import month_6 from "../../assets/svg/market/month6.svg"
import month_10 from "../../assets/svg/market/month10.svg"
import month_9_12 from "../../assets/svg/market/month9_12.svg"

import gifts from "../../assets/svg/market/gifts/gifts.svg";
import educational_packages from "./market/educational_package.svg";
import additional_right from "./market/additionalRight/additional_right.svg";

import failed from "../../assets/svg/market/failed.svg"
import successful from "../../assets/svg/market/successful.svg"

import card_name from "../../assets/svg/market/checkout/card_name.svg"
import card_number from "../../assets/svg/market/checkout/card_number.svg"
import cvv from "../../assets/svg/market/checkout/cvv.svg"

import book from "../../assets/svg/speaking_lesson/user/book.svg"
import timeline from "../../assets/svg/speaking_lesson/user/timeline.svg"
import report from "../../assets/svg/speaking_lesson/user/report.svg"
import progress_report from "../../assets/svg/speaking_lesson/user/progress-report.svg"
import daily_sentence from "../../assets/svg/speaking_lesson/user/daily-sentence.svg"
import resource from "../../assets/svg/speaking_lesson/user/resource.svg"
import video_practice from "../../assets/svg/speaking_lesson/user/video-practice.svg"

import schedule from "../../assets/svg/speaking_lesson/daily_lesson/schedule.svg"
import zoom_in from "../../assets/svg/speaking_lesson/daily_lesson/zoom-in.svg"
import zoom_in_white from "../../assets/svg/speaking_lesson/daily_lesson/zoom-in-white.svg"
import close from "../../assets/svg/speaking_lesson/daily_lesson/close.svg"
import speaker from "../../assets/svg/speaking_lesson/daily_lesson/speaker.svg"
import danger_sing from "../../assets/svg/speaking_lesson/daily_lesson/danger-sing.svg"
import postponed_class from "../../assets/svg/speaking_lesson/daily_lesson/postponed_class.svg"
import break_ from "../../assets/svg/speaking_lesson/daily_lesson/break.svg"
import holiday_2 from "../../assets/svg/speaking_lesson/daily_lesson/holiday2.svg"
import chatbot from "../../assets/svg/speaking_lesson/daily_lesson/chatbot.svg";
import down_arrow from "../../assets/svg/speaking_lesson/daily_lesson/down_arrow.svg";
import bookmark from "../../assets/svg/speaking_lesson/daily_lesson/bookmark.svg"
import selected_bookmark from "../../assets/svg/speaking_lesson/daily_lesson/selected_bookmark.svg"

import timer from "../../assets/svg/speaking_lesson/quiz/timer.svg";
import completed from "../../assets/svg/speaking_lesson/quiz/completed.svg";
import quiz_not_found from "../../assets/svg/speaking_lesson/quiz/quiz-not-found.svg";

import keyboard from "../../assets/svg/speaking_lesson/chatbot/keyboard.svg";
import send_icon from "../../assets/svg/speaking_lesson/chatbot/send-icon.svg";

import default_teacher from "../../assets/svg/teacher/default_teacher.svg"

import calendar from "../../assets/svg/support/calendar.svg"
import exchange from "../../assets/svg/support/exchange.svg"
import hourglass from "../../assets/svg/support/hourglass.svg"
import missed_call from "../../assets/svg/support/missed-call.svg"
import paper from "../../assets/svg/support/paper.svg"
import reload from "../../assets/svg/support/reload.svg"
import support_speech_bubble from "../../assets/svg/support/support-speech-bubble.svg"
import icon_confused from "../../assets/svg/support/icon-confused.svg"
import icon_missed_call from "../../assets/svg/support/icon-missed-call.svg"
import success from "../../assets/svg/support/success.svg"
import delete_account from "../../assets/svg/support/delete-account.svg"
import holiday from "../../assets/svg/support/holiday.svg";

import error_404 from "../../assets/svg/error_404.svg";
import announcement from "./announcement.svg";

import under_construction from "../../assets/svg/under_construction.svg"

import checked_green from "../../assets/svg/checked.svg";

import empty from "../../assets/svg/empty.svg";
import recording from "../../assets/svg/recording.svg";
import stop_recording from "../../assets/svg/stop-button.svg";

import permission from "../../assets/svg/collapse/permission.svg";
import profile from "../../assets/svg/collapse/profile.svg";
import reference from "../../assets/svg/collapse/reference.svg";
import demo from "../../assets/svg/collapse/demo.svg";

import mentor from "../../assets/svg/collapse/mentor.svg";

import arrow_down from "../../assets/svg/arrow_down.svg";

import target from "../../assets/svg/mentor/target.svg"
import mentor_card from "../../assets/svg/mentor/mentor-card.svg"

import exercise_lock_future from "./exercise/padlock_future.svg";
import exercise_lock_active from "./exercise/padlock_active.svg";
import exercise_lock_past from "../../assets/svg/exercise/padlock_past.svg";
import smile_face from "../../assets/svg/exercise/smile.svg";
import sad_face from "../../assets/svg/exercise/sad.svg";
import info from "../../assets/svg/exercise/info.svg";
import speaker_white from "../../assets/svg/exercise/speaker_white.svg";
import speaker_outline from "../../assets/svg/exercise/speaker_outline.svg";
import add from "../../assets/svg/exercise/add.svg";
import reading from "../../assets/svg/exercise/book.svg";
import microphone_circle from "../../assets/svg/exercise/microphone.svg";
import edit from "../../assets/svg/exercise/edit.svg";
import microphone_pronunciation from "./exercise/microphone_pronunciation.svg";
import add_black from "../../assets/svg/exercise/add_black.svg";
import microphone_black from "../../assets/svg/exercise/microphone_black.svg";
import edit_black from "../../assets/svg/exercise/edit_black.svg";
import book_black from "../../assets/svg/exercise/book_black.svg";

import copy from "../../assets/svg/family/copy.svg";
import reference_card from "../../assets/svg/family/reference_card.svg"
import family from "../../assets/svg/family/family.svg"
import invite from "../../assets/svg/family/invite.svg"
import add_member from "../../assets/svg/family/add_member.svg"
import add_person from "../../assets/svg/family/add_person.svg"

import education_packages from "../../assets/svg/speaking_lesson/education_packages/education-packages.svg";
import accept from "../../assets/svg/speaking_lesson/education_packages/accept.svg";
import awsome from "../../assets/svg/speaking_lesson/education_packages/awsome.svg";
import knowledgeable from "../../assets/svg/speaking_lesson/education_packages/knowledgeable.svg";
import not_started from "../../assets/svg/speaking_lesson/education_packages/not-started.svg";
import preparation from "../../assets/svg/speaking_lesson/education_packages/preparation.svg";
import thunder from "../../assets/svg/speaking_lesson/education_packages/thunder.svg";
import show_book from "../../assets/svg/speaking_lesson/education_packages/show-book.svg";
import star from "../../assets/svg/speaking_lesson/education_packages/star.svg";
import idea from "../../assets/svg/speaking_lesson/education_packages/idea.svg";

import daily_lesson_horizontal from "./speaking_lesson/daily_lesson_horizontal/daily_lesson.svg";
import attendance_report from "./speaking_lesson/daily_lesson_horizontal/attendance_report.svg";
import education_program from "./speaking_lesson/daily_lesson_horizontal/education_program.svg";
import improve_by_speaking from "./speaking_lesson/daily_lesson_horizontal/improve_by_speaking.svg";
import learn_with_videos from "./speaking_lesson/daily_lesson_horizontal/learn_with_videos.svg";
import quiz_test from "./speaking_lesson/daily_lesson_horizontal/quiz_test.svg";
import daily_sentence_horizontal from "./speaking_lesson/daily_lesson_horizontal/daily_sentence.svg";
import progress_chart from "./speaking_lesson/daily_lesson_horizontal/progress_chart.svg";

import edit_text from "./profile/edit_text.svg";
import profile_settings from "./profile/settings.svg";
import history from "./profile/history.svg";
import streak from "./profile/streak.svg";
import trash from "./profile/trash.svg";
import no_content_found from "./profile/no_content_found.svg";
import unfollow_friend from "./profile/unfollow_friend.svg";
import report_member from "./profile/report_member.svg";
import padlock from "./profile/padlock.svg";
import unlocked from "./profile/unlocked.svg";
import share from "./profile/share.svg";

import speaker_you from "./speaking_lesson/speaking_lab/speaker-you.svg";
import speaker_blue from "./speaking_lesson/speaking_lab/speaker-blue.svg";
import speaking_lab_pronunciation from "./speaking_lesson/speaking_lab/pronunciation.svg";

import demo_video_lesson from "./dashboard/demo_accordion/demo_video_lesson.svg";
import demo_lesson_attend from "./dashboard/demo_accordion/demo_lesson_attend.svg";
import demo_lesson_plan from "./dashboard/demo_accordion/demo_lesson_plan.svg";
import demo_lesson_prepare from "./dashboard/demo_accordion/demo_lesson_prepare.svg";
import demo_lesson_report from "./dashboard/demo_accordion/demo_lesson_report.svg";

export const PasswordEye = password_eye
export const PasswordEyeSlash = password_eye_slash

export const Check = check
export const Logo = logo

export const Button_Right_Arrow = button_right_arrow

export const Onboarding_1_1 = onboarding_1_1
export const Onboarding_1_2 = onboarding_1_2
export const Onboarding_1_3 = onboarding_1_3
export const Onboarding_1_4 = onboarding_1_4

export const Onboarding_2_1 = onboarding_2_1
export const Onboarding_2_3 = onboarding_2_3
export const Onboarding_2_8 = onboarding_2_8

export const Onboarding_3_1 = onboarding_3_1
export const Onboarding_3_2 = onboarding_3_2
export const Onboarding_3_3 = onboarding_3_3
export const Onboarding_3_4 = onboarding_3_4
export const Onboarding_3_5 = onboarding_3_5
export const Onboarding_3_6 = onboarding_3_6
export const Onboarding_3_7 = onboarding_3_7
export const Onboarding_3_8 = onboarding_3_8
export const Onboarding_3_9 = onboarding_3_9
export const Onboarding_3_10 = onboarding_3_10
export const Onboarding_3_11 = onboarding_3_11
export const Onboarding_3_12 = onboarding_3_12

export const Onboarding_Rocket = onboarding_rocket
export const Onboarding_Other = onboarding_other

export const Market = market
export const Market_Active = market_active
export const MenuFamily = menu_family
export const Settings = settings
export const Support = support
export const Support_Active = support_active
export const Teachers = teachers
export const Teachers_Active = teachers_active
export const Home = home
export const Home_Active = home_active
export const Microphone = microphone
export const Microphone_Active = microphone_active
export const Chevron_Right = chevron_right
export const Logout = logout

export const Month1 = month_1
export const Month3 = month_3
export const Month6 = month_6
export const Month10 = month_10
export const Month9_12 = month_9_12
export const Gifts = gifts
export const EducationalPackages = educational_packages
export const AdditionalRight = additional_right;

export const Card_Name = card_name
export const Card_Number = card_number
export const CVV = cvv

export const Book = book
export const Timeline = timeline
export const Report = report
export const ProgressReport = progress_report
export const DailySentence = daily_sentence
export const Resource = resource
export const VideoPractice = video_practice;

export const Schedule = schedule
export const Zoom_In = zoom_in
export const Zoom_In_White = zoom_in_white;
export const Close = close
export const Speaker = speaker
export const Danger_Sing = danger_sing
export const Postponed_Class = postponed_class
export const Break = break_
export const Holiday_2 = holiday_2
export const Chatbot = chatbot;
export const DownArrow = down_arrow;
export const Bookmark = bookmark;
export const SelectedBookmark = selected_bookmark;

export const Timer = timer;
export const Completed = completed;
export const QuizNotFound = quiz_not_found;

export const Keyboard = keyboard;
export const SendIcon = send_icon;

export const Default_Teacher = default_teacher

export const Calendar = calendar
export const Exchange = exchange
export const Hourglass = hourglass
export const Missed_Call = missed_call
export const Paper = paper
export const Reload = reload
export const Support_Speech_Bubble = support_speech_bubble
export const Icon_Confused = icon_confused
export const Icon_Missed_Call = icon_missed_call
export const Success = success
export const Successful = successful
export const Failed = failed
export const DeleteAccount = delete_account
export const Holiday = holiday

export const Error_404 = error_404;
export const Announcement = announcement;

export const Under_Construction = under_construction;

export const Checked_Green = checked_green;

export const Empty = empty;
export const Recording = recording;
export const StopRecording = stop_recording;

export const Permission = permission;
export const Profile = profile;
export const Reference = reference;
export const Demo = demo;

export const Mentor = mentor;

export const Arrow_Down = arrow_down;

export const Target = target;
export const MentorCard = mentor_card;

export const ExerciseLockFuture = exercise_lock_future;
export const ExerciseLockActive = exercise_lock_active;
export const ExerciseLockPast = exercise_lock_past;
export const SmileFace = smile_face;
export const SadFace = sad_face;
export const Info = info;
export const SpeakerWhite = speaker_white;
export const SpeakerOutline = speaker_outline;
export const Add = add;
export const MicrophoneCircle = microphone_circle;
export const Edit = edit;
export const Reading = reading;
export const MicrophonePronunciation = microphone_pronunciation;
export const AddBlack = add_black;
export const MicrophoneBlack = microphone_black;
export const BookBlack = book_black;
export const EditBlack = edit_black;

export const Copy = copy;
export const ReferenceCard = reference_card;
export const Family = family;
export const Invite = invite;
export const AddMember = add_member
export const AddPerson = add_person

export const EducationPackages = education_packages;
export const Accept = accept;
export const Awsome = awsome;
export const Knowledgeable = knowledgeable;
export const NotStarted = not_started;
export const Preparation = preparation;
export const Thunder = thunder;
export const ShowBook = show_book;
export const Star = star;
export const Idea = idea;

export const DailyLessonHorizontal = daily_lesson_horizontal;
export const AttendanceReport = attendance_report;
export const EducationProgram = education_program;
export const ImproveBySpeaking = improve_by_speaking;
export const LearnWithVideos = learn_with_videos;
export const QuizTest = quiz_test;
export const DailySentenceHorizontal = daily_sentence_horizontal;
export const ProgressChart = progress_chart;

export const EditText = edit_text;
export const ProfileSettings = profile_settings;
export const History = history;
export const Streak = streak;
export const Trash = trash;
export const NoContentFound = no_content_found;
export const UnfollowFriend = unfollow_friend;
export const ReportMember = report_member;
export const Padlock = padlock;
export const Unlocked = unlocked;
export const Share = share;

export const SpeakerYou = speaker_you;
export const SpeakerBlue = speaker_blue;
export const SpeakingLabPronunciation = speaking_lab_pronunciation;

export const DemoVideoLesson = demo_video_lesson;
export const DemoLessonAttend = demo_lesson_attend;
export const DemoLessonPlan = demo_lesson_plan;
export const DemoLessonPrepare = demo_lesson_prepare;
export const DemoLessonReport = demo_lesson_report;