import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { languages } from "../localizations"
import { changeLanguage } from "../store/LocalizationSlice";
import { Az_Flag, Usa_Flag } from '../assets/img';
import { getLanguageNameForCurrentLanguage } from '../utils';

const ChangeLanguage = (props) => {

    const { theme, toTop } = props;

    const language = useSelector(state => state.localizationStore.language);
    const flag = language === 'az' ? Az_Flag : language === 'en' ? Usa_Flag : '';
    const dispatch = useDispatch();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const dropDownRef = useRef();
    const dropDownGeneralRef = useRef();

    return (
        <div ref={dropDownGeneralRef} className={`${toTop ? "relative" : "flex flex-col items-end mr-2"}`}>
            <button id="dropdown" className="mt-0 max-w-full" onClick={() => setIsDropdownOpen(isDropdownOpen => !isDropdownOpen)} type="button">
                <div className='flex gap-1 items-center'>
                    <img src={flag} className={`w-[34px] h-[20px] rounded transition-colour duration-300 ${theme === "light" ? "border border-placeholder" : ""}`} />
                    <span className={`material-symbols-outlined transition-transform duration-300 ${theme === "light" ? "text-white" : "text-nav-button-border"} ${isDropdownOpen ? 'rotate-180' : ''}`}>
                        expand_more
                    </span>
                </div>
            </button>

            {/*<!-- Dropdown menu -->*/}
            <div id="dropdown_menu" ref={dropDownRef} className={`w-full mt-[2px] z-10 rounded transition-all min-w-max duration-300 ${toTop ? "absolute bottom-8 right-0 origin-bottom-right" : ""} ${theme === "light" ? "bg-white" : "bg-primary"} ${isDropdownOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-50'} ${toTop ? 'origin-bottom-right' : 'origin-top-right'}`}>
                <ul className="py-1">
                    {
                        Object.entries(languages).map(([key, value]) => {
                            return (
                                <li key={key}>
                                    <a 
                                        onClick={() => {
                                            dispatch(changeLanguage(key));
                                            setIsDropdownOpen(isDropdownOpen => !isDropdownOpen);
                                        }} 
                                        className={`block cursor-pointer py-1 my-1 px-2 mx-[1px] min-w-max text-start ${theme === "light" ? "hover:bg-primary hover:text-white" : "hover:bg-white hover:text-primary text-white"}`}
                                    >
                                        {getLanguageNameForCurrentLanguage(key, language)}
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>

        </div>
    )
}

export default ChangeLanguage