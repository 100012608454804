import React, { useEffect, useState } from 'react'
import useAnalytics from '../../../hooks/useAnalytics';
import { useLocalization } from '../../../hooks/useLocalization'
import useAuth from '../../../hooks/useAuth';
import { TermDropDown } from '../../../components';
import { GetFreezeInformationService } from '../../../services/Support/SupportService';
import FreezeNoRight from './FreezeNoRight';
import FreezeSuccess from './FreezeSuccess';
import Freeze from './Freeze';
import { addTimeZone, getTimeZone } from '../../../utils';

const FreezeHome = () => {
    const strings = useLocalization();
    const analytics = useAnalytics();
    const { member, token } = useAuth();
    const timeZone = getTimeZone(member.Timezone);

    const terms = typeof(member.Terms) !== "undefined" && JSON.parse(member.Terms).filter(term => term.Status === 1);

    const [termId, setTermId] = useState(false);
    const [lesson, setLesson] = useState('');
    const [loading, setLoading] = useState(true);

    const [freezeData, setFreezeData] = useState({});

    const getFreezeData = async termId => {
        try {
            const result = await GetFreezeInformationService(termId, token);
            if (result.statusCode === 200) {
                setFreezeData(JSON.parse(result.message));
                setLoading(loading => false);
            } else {

                if (result.message) {
                    analytics.useAnalytics('Error_Occured', {
                        error: `${result.message}`,
                    });
                }
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }
            }

        } catch (error) {
            analytics.useAnalytics('Error_Occured', {
                error: `An unexpected error occurred.`,
            });
        }
    };

    const freezeOK = freezeData['freezeRightCount'] > 0;

    //from child dropdown
    const changeTerm = term => {
        const type = `${term.ProductName} / ${addTimeZone(term.LessonTime, timeZone)} / ${term.LessonDuration} ${strings.general_information_messages.minute}`;

        setTermId(term.TermId);
        setLesson(lesson => type);
        setLoading(loading => true);
    };

    useEffect(() => {
        if (termId) {
            getFreezeData(termId);
        }
    }, [termId])


    return (
        <>
            <div className="main-content">
                <div className="mt-5 gray-section bg-title-bg px-[10px] xs:px-[10px] text-start">
                    <TermDropDown terms={terms} changeTerm={changeTerm} />
                </div>

                {loading ? (
                    <div className="flex justify-center mt-5">{strings.general_information_messages.loading}</div>
                ) :
                    (freezeData.activeFreezeEndDate === null ?
                        (
                            freezeOK ? (
                                <Freeze termId={termId} lesson={lesson} getFreezeData={getFreezeData} />
                            ) : (
                                <FreezeNoRight />
                            )
                        ) :
                        (
                            <FreezeSuccess endDate={freezeData.activeFreezeEndDate} />
                        ))}


                <div className="left-margin-bottom"></div>
            </div>

        </>
    )
}

export default FreezeHome