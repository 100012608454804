export const fetchHeader = payload => {

    return {
        method: "POST",
        mode: 'cors',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Source': 'Az-Web',
            'Project-Id': '2'
        }
    }
};