import { useState } from "react";
import useAnalytics from "../../../../hooks/useAnalytics";
import useAuth from "../../../../hooks/useAuth";
import { useLocalization } from "../../../../hooks/useLocalization"
import { SetTicketReadService } from "../../../../services/Support/SupportService";
import OutsideClickHandler from "react-outside-click-handler";

const TicketCard = ({ ticket, index }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const { token } = useAuth();

    const answer = ticket.answer ? ticket.answer : strings.support.previous_request.not_yet_answered

    const [showFullMessage, setShowFullMessage] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const setRead = async ticketContentId => {
        try {
            const result = await SetTicketReadService(ticketContentId, token);
            if (result.statusCode !== 200) {
                if (result.message)
                    analytics.useAnalytics('Error_Occured', { error: `${result.message}` });
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }
                return;
            }
        } catch (error) {
            analytics.useAnalytics('Error_Occured', { error: `${error}` });
        }
    };

    // add utc time difference
    const timeConverter = messageDate => {
        let time = messageDate.slice(-5, ticket.messageDate.length);
        //let timezoneOffset = -new Date().getTimezoneOffset();
        let timezoneOffset = -new Date().getTimezoneOffset(); 
        let [hh, mm] = time.split(':').map(e => parseInt(e));
        let [offsetHours, offsetMinutes] = [
            Math.floor(timezoneOffset / 60),
            timezoneOffset % 60,
        ];
        offsetHours = offsetHours + Math.floor((mm + offsetMinutes) / 60);
        let newHour =
            (hh + offsetHours) % 24 === 0
                ? '00'
                : (hh + offsetHours) % 24 < 10
                    ? '0' + ((hh + offsetHours) % 24)
                    : (hh + offsetHours) % 24;
        let newMinute =
            (mm + offsetMinutes) % 60 === 0 
                ? '00' 
                : (mm + offsetMinutes) % 60 < 10
                    ? '0' + ((mm + offsetMinutes) % 60)
                    : (mm + offsetMinutes) % 60;
        return ticket.messageDate.slice(0, 10) + ' ' + newHour + ':' + newMinute;
    };

    return (
        <>
            <OutsideClickHandler onOutsideClick={() => {setIsOpen(false); setShowFullMessage(false);}}>
                <div className="group outline-none text-sm mt-5 relative" tabIndex={index} onClick={() => setIsOpen(!isOpen)}>
                    {ticket.status === 2 && (
                        <div className="absolute h-2 w-2 rounded-full bg-[#2da44e] right-5 z-10 top-6"></div>
                    )}
                    {ticket.status === 1 && (
                        <div className="absolute h-2 w-2 rounded-full bg-[#f25961] right-5 z-10 top-6"></div>
                    )}
                    <button 
                        id={`accordion_${index}`}
                        className={`${isOpen ? 'rounded-b-none border-b-0' : ''} ticket-accordion-div-main w-full`}
                        onClick={() => {
                            ticket.status === 2 && setRead(ticket.ticketContentId);
                            setShowFullMessage(!showFullMessage);
                        }}
                    >
                        <div className={`ticket-accordion-header absolute top-3 left-0 ${isOpen ? 'rotate-90' : ''}`}>
                            <span className="material-symbols-outlined text-base-text">
                                {" "}
                                arrow_forward_ios{" "}
                            </span>
                        </div>
                        <div id="test" className="ticket-accordion-text-main">
                            <p id="question_text" className={`text-start pr-6 ${isOpen ? 'font-bold max-h-screen' : 'max-h-[20px]'} ${ticket.status === 2 || ticket.status === 1 ? 'font-bold' : ''}`}>
                                {showFullMessage ? ticket.message : ticket.message.slice(0, 25)}{!showFullMessage && ticket.message.length > 25 ? '...' : ''}
                            </p>
                            <p className={`${ticket.status === 2 || ticket.status === 1 ? 'font-bold' : ''} text-start group-focus:font-bold`}>
                                {/* {ticket.messageDate} */}
                                {timeConverter(ticket.messageDate)}
                            </p>
                        </div>
                    </button>
                    <div className={`${isOpen ? 'max-h-screen border-x border-b pt-2' : 'max-h-0'} ticket-accordion-content-main`}>
                        <hr className="-mt-2 mb-2" />
                        <p className="mb-2" dangerouslySetInnerHTML={{ __html: answer.replace(/\n/g, '<br>') }} />
                        <p className="mb-3 font-bold">{ticket.answer && timeConverter(ticket.answeredDate)}</p>
                    </div>
                </div>
            </OutsideClickHandler>
        </>
    )
}

export default TicketCard