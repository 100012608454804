import {useLocation} from "react-router-dom";
import {useRef, useState} from "react";
import useAiRouting from "../../hooks/useAiRouting";
import {getUseCaseById} from "../../utils";
import {AIChat} from "./components";
import {GetDetailedUseCases} from "../../services/AIUseCase/AIUseCaseService";
import {useLocalization} from "../../hooks/useLocalization";
import {Button} from "../../components";
import {Logo, Onboarding_Rocket} from "../../assets/svg";

const EmbedAIPractice = () => {

    const strings = useLocalization();
    const location = useLocation();

    const urlSearchParams = new URLSearchParams(location?.search);
    const practice = urlSearchParams.get("practice");
    const useCaseId = urlSearchParams.get("useCaseId");
    const aiUseCaseId = useAiRouting(practice);

    const audioPlayerRef = useRef();

    const [selectedPractice, setSelectedPractice] = useState({});

    const handleBackButton = () => {
        window.location.reload();
    }

    const handleStartButton = () => {
        getDetailedUseCases();
        audioPlayerRef.current.play()
    }

    const getDetailedUseCases = () => {
        GetDetailedUseCases()
            .then(result => {
                if(result.statusCode === 200) {
                    const resultContent = JSON.parse(result.message);
                    let tempSelectedPractice = resultContent.find(item => item.id === aiUseCaseId) ?? {};
                    let useCase = ""

                    if(useCaseId) {
                        useCase = getUseCaseById(+useCaseId);
                    }

                    if(Object.keys(tempSelectedPractice).length > 0) {
                        tempSelectedPractice.aiUseCaseEndpointTestUrl = "";
                        tempSelectedPractice.aiUseCaseEndpointUrl = "";
                        tempSelectedPractice.aiUseCaseEndpointRequestBody = "";

                        if(useCase.length > 0) {
                            let replacedPrompt = tempSelectedPractice.aiUseCasePrompt.replace("#endpoint#", `Student Use-Case: ${useCase}`);
                            tempSelectedPractice.aiUseCasePrompt = replacedPrompt;
                        }
                    }

                    setSelectedPractice(tempSelectedPractice);
                }
            })
            .catch()
    }

    return (
        <>
            {Object.keys(selectedPractice).length === 0 &&
                <section className="h-full flex overflow-hidden flex-col relative">
                    {/*<!-- top -->*/}
                    <div className="w-full h-1/2 flex justify-center items-center">
                        <div className=" mb-12 flex flex-col items-center z-20">
                            <img className="w-[90px] md:w-[120px]" src={Logo} alt="logo"/>
                            <p className="font-bold text-2xl md:text-4xl mt-2 outline-title">{strings.brand_name}</p>
                        </div>
                    </div>

                    <div className="absolute -mt-[16px] top-[calc(50%-80px)] left-[calc(50%-80px)]">
                        <img src={Onboarding_Rocket} width="160" height="160" alt=""/>
                    </div>

                    {/*<!-- bottom -->*/}
                    <div className="w-full h-1/2 bg-box border-t-[5.4px] border-base-text pt-20">
                        <div className="btn-outer-div mb-3 mt-12">
                            <Button
                                type="button"
                                classnames="button primary-button"
                                text={strings.ai_chatbot.determine_your_level}
                                action={handleStartButton}
                            />
                        </div>
                    </div>
                </section>
            }

            <div className="mx-auto px-3 max-w-7xl mt-5">
                <AIChat
                    ref={audioPlayerRef}
                    selectedPractice={selectedPractice}
                    setSelectedPractice={setSelectedPractice}
                    backButtonAction={handleBackButton}
                    isEmbed={true}
                />
            </div>
        </>
    );
}

export default EmbedAIPractice;