const Audio = ({src, onclick, onplay = () => {}, onpause = () => {},  error = ''}) =>{

    const lessonRecordUrl = process.env.REACT_APP_SOUND_BASE_URL;
    const isLessonAudio = src && src.includes(lessonRecordUrl);
  
    return (
      <>
        <audio
          className="w-full mt-1"
          controls
          controlsList={`${!isLessonAudio ? 'nodownload' : ''} noplaybackrate`}
          onClick={onclick}
          onPlay={onplay}
          onPause={onpause}
        >
          <source src={src} type="audio/mp3" />
          {error}
        </audio>
      </>
    );
}   

export default Audio