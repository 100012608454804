import React, {useContext} from 'react'
import {useLocalization} from "../../hooks/useLocalization"
import {Menu, SideBar} from "../../components";
import {Outlet} from "react-router-dom"
import {FirebaseContext} from "../../store/FirebaseProvider";
import CampaignBar from "./components/CampaignBar";

const DashboardLayout = () => {

  const strings = useLocalization();
  const campaignStickyBar = useContext(FirebaseContext)?.campaignStickyBar;

  return (
    <>
      <Menu title={strings.dashboard.title} />

      <section className='container'>

        <Outlet />

        <SideBar />

      </section>

      {campaignStickyBar && <CampaignBar campaignStickyBar={campaignStickyBar} />}
    </>
  )
}

export default DashboardLayout