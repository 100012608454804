import classNames from "classnames";
import { getAnswerTitleByIndex } from "../../../../utils";

const ExerciseAnswerButton = (props) => {

    const { index, item, selectedAnswer, setSelectedAnswer, isClickedControlButton} = props;

    const correctAnswerId = item.isCorrectAnswer ? item.answer.id : null
    const lang = "az";

    let normalButton = selectedAnswer !== item.id;
    const selectedAnswerStatus = selectedAnswer?.answer?.id === item.answer.id;
    const focusedButton = !isClickedControlButton && selectedAnswerStatus;
    const rightAnswerButton = isClickedControlButton && item?.isCorrectAnswer;
    const wrongAnswerButton = isClickedControlButton && item.answer.id === selectedAnswer.answer.id && selectedAnswer.answer.id !== correctAnswerId;

    return (
        <button
            type="button"
            className={classNames("w-full text-[14px] max-w-[330px] h-[50px] leading-[22px] relative overflow-hidden rounded-[10px] border border-onboarding-border mb-4 lg:mb-5 pl-[70px] pr-7 flex items-center", {
                "bg-white": normalButton,
                "!bg-[#EBF6FF] !text-base": focusedButton,
                "bg-green hover:bg-green": rightAnswerButton,
                "!bg-red hover:bg-red": wrongAnswerButton,
                "hover:bg-onboarding-bg-select cursor-pointer": !isClickedControlButton,
                "cursor-default": isClickedControlButton,
            })}
            disabled={isClickedControlButton}
            onClick={() => setSelectedAnswer(item)}
        >
            <div
                className={classNames("onboarding-button-left", {
                    "bg-green border-white text-white": rightAnswerButton,
                    "bg-red border-white text-white": wrongAnswerButton,
                })}
            >
                <span className="font-bold">{getAnswerTitleByIndex(index)}</span>
            </div>
            <p
                className={classNames("text-start", {
                    "text-white": (rightAnswerButton || wrongAnswerButton),
                    "text-[14px] leading-[17px]": item.answer.content.length > 60,
                })}
            >
                {item.answer.translation[lang]}
            </p>
        </button>
    );
};

export default ExerciseAnswerButton;
